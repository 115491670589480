

import { createWebHistory, createRouter } from "vue-router";

//import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import CalenderPage from "../pages/CalenderPage";
import CalenderPageTranspose from "../pages/CalenderPageTranspose";
import CalenderpageFullLine from "../pages/CalenderPageFullLine.vue"
import { getAuth } from "firebase/auth";
import ForgotPasswordPage from "../pages/ForgotPassword";

const routes = [
 
  {
    path: "/",
    name: "calenderPage",
    component: CalenderPage,
    meta: { onlyGuestUser: false }
  },

  /*{
    path: "/",
    name: "calenderPageFullLine",
    component: CalenderpageFullLine,
    meta: { onlyGuestUser: false }
  },*/

  {
    path: "/calenderTranspose",
    name: "calenderTranspose",
    component: CalenderPageTranspose,
    meta: { onlyGuestUser: false }
  },

  {
    path: "/calenderpageFullLine",
    name: "calenderpageFullLine",
    component: CalenderpageFullLine,
    meta: { onlyGuestUser: false }
  },

  {
    path: "/about",
    name: "About",
    component: AboutPage
  },

  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { onlyGuestUser: true }
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    meta: { onlyGuestUser: true }
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPasswordPage,
    meta: { onlyGuestUser: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, _, next) => {
  const isAuth = !!getAuth().currentUser;

  if (to.meta.onlyAuthUser) {
    if (isAuth) {
      next();
    } else {
      next({name: "Login"});
    }
  } else if (to.meta.onlyGuestUser) {
    next();
  }else{
    next();
  }
})

export default router;