
var mycalendars = []

export default {

    state(){
        return{
            items: [],
        }
    },

    actions:{
        getMyCalendars({commit}){
          commit("setMyCalendars",mycalendars);   
      }
    },

    mutations:{
      setMyCalendars(state, mycalendars){
            state.items = mycalendars;
        }
    }
}

