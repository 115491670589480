<!-- RecurringEventForm.vue -->
<!-- https://www.kanzaki.com/docs/ical/rrule.html -->
<template>
    <v-dialog v-model="dialog" width="600px">
        <template v-slot:activator="{ props }">
            <v-icon v-bind="props"
                  
                  class="close"
                  icon="mdi-note-edit"
                  color="#656666"
                  size="35px"
                ></v-icon><!--https://pictogrammers.com/library/mdi/-->
        </template>
    
    <v-card>
      <v-row>
        <v-col cols="12">
          <v-card>
           
        <div style="
            padding-top: 25px;
            padding-left: 25px;
            padding-bottom: 25px;
        ">
            <v-row align="center">
                <div style="
                    font-size: large;
                ">{{ $t('repetitions') }}</div>
         
            </v-row>
        </div>    
     
            <v-card-text>
                
              <v-form @submit.prevent="submitForm">
                <v-row>
                  <v-col cols="5" md="6">
                    <v-text-field
                    variant="outlined"
                      density="compact"
                      v-model="formData.interval"
                      :label="$t('repeatevery')"
                      type="number"
                      dense
                      required
                      min="1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5" md="6">
                    <v-select
                      variant="outlined"
                      density="compact"
                      v-model="formData.frequency"
                      :items="frequencyOptions"
                      :label="$t('timeframe')"
                      dense
                      required
                      @change="updateDaysOfWeek"
                    ></v-select>
                  </v-col>
                </v-row>
  
                <v-row v-if="formData.frequency === 'Wochen' | formData.frequency === 'Weekly'">
                  <v-col cols="12">
                    <v-select
                    variant="outlined"
                      density="compact"
                      v-model="formData.daysOfWeek"
                      :items="daysOfWeekOptions"
                      :label="$t('repeat')"
                      outlined
                      dense
                      multiple
                      chips
                      small-chips
                      @change="updateDaysOfWeek"
                    ></v-select>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                    variant="outlined"
                      density="compact"
                      v-model="formData.endType"
                      :items="endTypeOptions"
                      :label="$t('end')"
                      outlined
                      dense
                      required
                    ></v-select>
                  </v-col>
  
                  <v-col cols="12" md="6">
                    <template v-if="formData.endType === 'nach Wiederholungen'  | formData.endType === 'Repetitions'" >
                      <v-text-field
                      variant="outlined"
                      density="compact"
                        v-model="formData.occurrences"
                        label="Anzahl Termine"
                        type="number"
                        outlined
                        dense
                        required
                        min="1"
                      ></v-text-field>
                    </template>
                    <template v-else-if="formData.endType === 'Enddatum'  | formData.endType === 'Final Date'">
                      <v-date-picker
                        v-model="formData.endDate"
                        label="End Date"
                        outlined
                        dense
                      ></v-date-picker>
                    </template>
                  </v-col>
                </v-row>
  
                <v-row class="text-right">
                  <v-col cols="12">
                    <v-btn @click="dialog = false" class="text-none" color="#656565"  variant="text" style="font-size: 14px; font-weight: 500; padding-right: 20px;">Abbrechen</v-btn>
                    
                    <v-btn class="text-none" color="#316bff" type="submit" variant="flat" style="font-size: 14px; font-weight: 500;">Speichern</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
           
          </v-card>
        </v-col>
      </v-row>
    </v-card>
</v-dialog>
  </template>
  
  
  <script setup>
    
    import { ref, onBeforeMount, defineProps } from 'vue'
    import {useStore} from "vuex";
    import { useI18n } from "vue-i18n";
    
    const props = defineProps({     
                                rRule: String
                              }); 

    const ValuerRule = ref('ONCE');                              
    const store = useStore();
    const { state } = store;
    const formData = ref({
        frequency: 'Tage',
        interval: 1,
        daysOfWeek: [],
        endType: 'nie',
        occurrences: 1,
        endDate: null,
    }) 

    const i18nLocale = useI18n()

    const frequencyOptions = ref([
    i18nLocale.locale.value == "de"? 'Tage' : 'Daily', 
    i18nLocale.locale.value == "de"?  'Wochen' : 'Weekly', 
    i18nLocale.locale.value == "de"?  'Monate' : 'Monthly']
      )
    const daysOfWeekOptions = ref( [
    i18nLocale.locale.value == "de"? 'Montag' : 'Monday',
    i18nLocale.locale.value == "de"? 'Dienstag' : 'Tuesday',
    i18nLocale.locale.value == "de"? 'Mittwoch' : 'Wednesday',
    i18nLocale.locale.value == "de"? 'Donnerstag' : 'Thursday',
    i18nLocale.locale.value == "de"? 'Freitag' : 'Friday',
    i18nLocale.locale.value == "de"? 'Samstag' : 'Saturday',
    i18nLocale.locale.value == "de"? 'Sonntag' : 'Sunday',
    ])
    const endTypeOptions = ref([
    i18nLocale.locale.value == "de"? 'nie': 'never', 
    i18nLocale.locale.value == "de"?   'nach Wiederholungen' : 'Repetitions', 
    i18nLocale.locale.value == "de"?  'Enddatum': 'final Date']) 
    const dialog = ref(false)

    onBeforeMount(() => initialize());

    const initialize = () => {
        //formData.value.interval = 3
        ValuerRule.value = props.rRule
        parseRRule();
    }

    function parseRRule(){
        const rrule = props.rRule;
        const freqMatch = rrule.match(/FREQ=([^;]+)/);
        const untilMatch = rrule.match(/UNTIL=([^;]+)/);
        const countMatch = rrule.match(/COUNT=([^;]+)/);
        const bydayMatch = rrule.match(/BYDAY=([^;]+)/);
        const intervalMatch = rrule.match(/INTERVAL=([^;]+)/);

        if (freqMatch) {
            const freqValue = freqMatch[1];
            if (freqValue === "DAILY") {
                formData.value.frequency = "Tage";
            } else if (freqValue === "WEEKLY") {
                formData.value.frequency = "Wochen";
            }else if (freqValue === "MONTHLY") {
                formData.value.frequency = "Monate";
            }
        }

        if(intervalMatch){
            const intervalValue = intervalMatch[1]
            formData.value.interval = intervalValue
        }
  
        if (untilMatch) {
            const untilValue = untilMatch[1];
            formData.value.endType = "Enddatum"
            formData.value.endDate = Date(untilValue) 
            }else if (countMatch) {
            const countValue = countMatch[1];
            formData.value.endType = "nach Wiederholungen"
            formData.value.occurrences = countValue
            }else{
                formData.value.occurrences = "nie"
        }

        if (bydayMatch) {
            var bydayValue = bydayMatch[1];
            if(bydayValue.includes('MO')){
                formData.value.daysOfWeek.push("Montag")
            }
            if(bydayValue.includes('TU')){
                formData.value.daysOfWeek.push("Dienstag")
            }
            if(bydayValue.includes('WE')){
                formData.value.daysOfWeek.push("Mittwoch")
            }
            if(bydayValue.includes('TH')){
                formData.value.daysOfWeek.push("Donnerstag")
            }
            if(bydayValue.includes('FR')){
                formData.value.daysOfWeek.push("Freitag")
            }
            if(bydayValue.includes('SA')){
                formData.value.daysOfWeek.push("Samstag")
            }
            if(bydayValue.includes('SU')){
                formData.value.daysOfWeek.push("Sonntag")
            }
          
        }
    }

    function submitForm() {
        // Format the form data as RRULE string
        let rrRuleString = `FREQ=${translateToRRule(formData.value.frequency)};INTERVAL=${formData.value.interval}`;
  
        if (formData.value.frequency === 'Wochen' && formData.value.daysOfWeek.length > 0) {
          //rrRuleString += `;BYDAY=${formData.value.daysOfWeek.map(day => day.slice(0, 2)).join(',')}`;
          rrRuleString += `;BYDAY=${formData.value.daysOfWeek.map(day => translateToRRule(day)).join(',')}`;
        }
  
        if (formData.value.endType === 'nach Wiederholungen') {
          rrRuleString += `;COUNT=${formData.value.occurrences}`;
        } else if (formData.value.endType === 'Enddatum' && formData.value.endDate) {
          // Format the end date in UTC
          const endDate = new Date(formData.value.endDate);
          const endDateString = endDate.toISOString().slice(0, 19).replace(/[-:]/g, '') + 'Z';
          
          rrRuleString += `;UNTIL=${endDateString}`;
        }
        //alert(`Generated RRULE String:\n${rrRuleString}`); // Show an alert with the generated RRULE string
        state.dayevent.rRules = rrRuleString
        dialog.value = false;
        
    }
    
    function updateDaysOfWeek() {
        // Handle logic when days of week are updated
        console.log('Wochentage:', formData.value.daysOfWeek);
    }

    function translateToRRule(textval){
        switch(textval) {
        case 'Montag':
        case 'Monday':
            return 'MO'
        case 'Dienstag':
        case 'Tuesday':
            return 'TU'
        case 'Mittwoch':
        case 'Wednesday':
                return 'WE'
        case 'Donnerstag':
        case 'Thursday':
                return 'TH'
        case 'Freitag':
        case 'Friday':
                return 'FR'
        case 'Samstag':
        case 'Saturday':
                return 'SA'
        case 'Sonntag':
        case 'Sunday':
                return 'SU'
        case 'Tage':
        case 'DAILY':
                return 'DAILY'
        case 'Wochen':
        case 'Weekly':
                return 'WEEKLY'
        case 'Monate':
        case 'Monthly':
                return 'MONTHLY'
            default:
                return textval
}

    }
  
  </script>
  
  <style scoped>
  /* Add your component-specific styles here */
  </style>
  
