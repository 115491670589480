
<template>
    <div class="tooltip" >
      <div 
          aria-describedby="tooltip" 
          @mouseenter="handleShow($event)" 
          @mouseleave="handleHide($event)" 
          @click="handleHide($event)" 
          @focus="handleShow($event)" 
          @blur="handleHide($event)">
          <div  v-bind:style="{ 
            backgroundColor:  item.color,
            color: item.color
          }" >
              xxx
          </div>
      </div>
      
      <div class="tooltipText" role="tooltip">
        <!------------------------ DAY START------------------------>
       <div style="
       padding-top: 5px;
       font-weight: 400;
  
       font-size:larger;
       ">
       {{item.dateFormated_De}}
       </div>
       <!------------------------ DAY START------------------------>
  
    
     
       <!------------------------ EVENTS START ------------------------>
  <div style="
  background-color: #f6f8fc;
  padding-top: 15px;
  padding-bottom: 0px;
  
  ">


    <div class="grid-container" v-for ="event in item.events" :key="event.eventidDB">
  
        <component 
            class="grid-item"
            style="
            margin-left: 5px;
            "
            :size="30" 
            :color=event.eventColor 
            weight="light" 
            :is=event.icon
          >
        </component>
  
        <div class="grid-item">
        <div 
          style="
          font-size: 16px;
          font-weight: 400;
          padding-right: 10px;
          "
          v-bind:style="{color: event.eventColor}">{{event.eventName}}
        </div >
         
     
        </div>
           
       
      
          <div style="
            font-size: 14px;
            font-weight: 400;
            color: rgb(97, 97, 97);
          ">
            <div class="grid-item" v-if="event.isFullDay == false">{{ event.fromHour }}: {{ event.fromMinute }} bis: {{ event.toHour }}: {{ event.toMinute }}</div>
            <div class="grid-item" v-if="event.isFullDay == true">ganztägig</div>
          </div>
          <div 
          style="
          white-space: pre;
          text-overflow:clip;
          font-size: 13px;
          font-weight: 400;
          padding-right: 10px;
          padding-left: 55px;
          padding-bottom: 5px;
          color:rgb(97, 97, 97)
          "
        >{{event.additionalInfos}}{{event.location}}
        </div >
    </div>
   
  </div>
  
  
       <!------------------------ EVENTS END ------------------------>
  
        <div class="tooltipArrow" data-popper-arrow></div>
      </div>
    </div>
  </template> 
  
  <script>
  import { defineComponent, ref, computed } from 'vue'
  import { createPopper } from '@popperjs/core'
  
  export default defineComponent({
    name: 'Tooltip',
    props: {
      placement: String,
      item:Object,
    },
    methods:{
      showTooltip2(value1, value2){
        if(value1 == value2){
          return "";
        }
        else{
          return value2;
        }
      }
    },
  
    setup(props) {
      const button = ref(null)
      const tooltip = ref(null)
  
      const popperInstance = computed(() => {
        return createPopper(button.value, tooltip.value, {
          placement: props.placement,//'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
          strategy: 'absolute'
        })
      })
  
      const insertElement = (btn, tip) => {
        button.value = btn
        tooltip.value = tip
      }
  
      const handleShow = (e) => {
        if(button.value === null && tooltip.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.tooltipText'))
        }
        tooltip.value.setAttribute('data-show', '')
        popperInstance.value.update()
      }
  
      const handleHide = (e) => {
        if(button.value === null && tooltip.value === null) {
          insertElement(e.target, e.target.parentElement.querySelector('.tooltipText'))
        }
        tooltip.value.removeAttribute('data-show')
      }
  
      return {
        handleShow,
        handleHide
      }
    }
  })
  </script>
  
  <style scoped>
  
  .div-v-center {
      display: flex;
      height: 100%;
      align-items: center;
  }
  .grid-container {
  display: grid;
  grid-template-columns: 55px auto  160px ;
  padding-left: 10px;
  background-color: #f6f8fc;
  
  }
  
  .grid-item {
    display: flex;
      height: 100%;
      align-items: center;
    background-color: #f6f8fc;
    padding-top: 5px;
    text-align: left;
    vertical-align: middle;
  }
  
  .center {
    
    align-content: center;
  }
  
  .color-selector{
    width:15px;
    height:15px;
    border-radius: 25px;
    margin-top:3px;
    margin-left: 0px;
    margin-right: 8px;
    margin-bottom: 10px;
    padding-right: 5px;
  }
  
  
  .item-small {
      user-select: none;
      display: flex;
      align-items:center;
      grid-column: 1 / span 1;
      padding-left: 0px;
      padding-right: 0px;
      font-size:x-small;
      font-weight: 500;
      height:100%;
      border-top-width: 1px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
      border-style: solid;
      border-top-color: rgb(197, 194, 194);
  }
  
  .item-large {
      display: flex;
      align-items:center;
      justify-content:center;
      padding-left: 0px;
      
      border-radius: 0px;
      grid-column: 2 / span 2;
      text-align:center;
      font-size: x-small;
      color:#0b3558;
      text-align: center;
      font-weight: 400;
      height:100%;
      width:90%;
      right:2%;
      left:2%;
      border-top-width: 1px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
      border-style: solid;
      border-top-color: rgb(197, 194, 194);
  }
  .weekday-number{
    font-weight: 800;
      font-size: 9px;
      vertical-align: middle;
      padding-left: 1px;
      padding-right: 1px;
      
  } 
  
  .weekday-name{
      
    font-weight: 300;
      font-size: 9px;
      
  }
  .day-grid-container{
    background-color: #f6f8fc;
    grid-auto-columns: 1fr;
    display: grid;
    width: 100%;
    height: 100%;
    user-select: none;
  
  }
  .button {
    appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: seagreen;
    border-radius: 25px;
    padding: 0.75rem;
    font-size: 12pt;
    color: white;
  }
  
  .tooltip{
    padding-right: 25px;
    
  }
  
  .tooltipText {
    width: 380px;
    background-color: #f6f8fc;
    color: #0b3558;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 13px;
    display: none;
    border: 1px solid rgba(66, 66, 66, 0.5);
  }
  
  .tooltipText[data-show] {
    display: block;
  }
  
  
  </style>
  