import { defineStore } from 'pinia'

export const usecurrentEventsStore = defineStore('currentEvents', {
    state: () => {
        return {
          fromDay: new Date(),
          toDay: new Date(),
          fromHour: '08',
          fromMinute: '00',
          toHour: '09',
          toMinute: '00',
          isFullDay: true
        }
      },
  })