<template>
<div :class="this.$store.state.settings.currentTheme + '-theme'">

    <v-btn 
        color="#656565"  
        class="text-none text-style" 
        density="default"
        variant="text"  
        style="margin-left: -12px;"
        >  
        {{ props.linktext}}
        <template v-slot:prepend>
            <component 
                class="icon-style"
                :size="18" 
                :is=currentIcon
            /> 
      </template>
    </v-btn> 

</div>
</template>

<style scoped>

.text-style{
    font-size: 13px;
    font-weight: 400;
    color:var(--standard-text-color);
    letter-spacing: -1px;
}

.icon-style{
    color:var(--standard-text-color);
}

.btn {
    text-transform: unset !important;
    color:var(--standard-text-color);
}

</style>

<script setup>

import { defineProps, onBeforeMount, ref } from 'vue';


const currentIcon = ref('')

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  linktext: {
    type: String,
    required: true,
  },
});

onBeforeMount(() => initialize());

const initialize = () => {
    currentIcon.value = props.icon
}


</script>