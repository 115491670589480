
import {db} from "../../db";
import { getDocs, collection } from "firebase/firestore";
import {ref} from "vue"

const userID = ref("")

export default {

    actions:{

        async getavailableCalendarsFromDB({commit},userId){

            userID.value = userId

            const snapshot = await getDocs(collection(db, "calendars"));
            
            const storedavailablecalendars = snapshot.docs.map(doc => {
                const data =  doc.data();
                const id = doc.id;
                return {id, ...data};
            })
           
            commit("setStoredAvailableCalendars",storedavailablecalendars);
            
        },

    },


    mutations:{

        async setStoredAvailableCalendars(state, storedavailablecalendars){

            try{
                state.items = []
            
                //------------ GET MY SUBSCRIBED CALENDARS FROM DATABASE START -----------------
                
                const snapshot = await getDocs(collection(db, "users", userID.value, "myCalendars"));
                
                const storedmycalendars = snapshot.docs.map(doc => {
                    const data =  doc.data();
                    const id = doc.id;
                    return {id, ...data};
                })
    
                
                //------------ GET MY SUBSCRIBED CALENDARS FROM DATABASE END -----------------
    
                
    
                for (let index = 0; index < storedavailablecalendars.length; ++index){
    
                    if(storedavailablecalendars[index].private == false && storedavailablecalendars[index].creatorUID != userID.value){
                        var isSubscribed = false;
    
                        for(let index2 = 0; index2 < storedmycalendars.length; ++index2){
                            if(storedmycalendars[index2].calendarId == storedavailablecalendars[index].calendarId){
                                isSubscribed = true;
                            }
                        }
        
                        let newCalendar = {
                            docId: storedavailablecalendars[index].id,
                            calendarId: storedavailablecalendars[index].calendarId,
                            title: storedavailablecalendars[index].name,
                            description: storedavailablecalendars[index].description,
                            icon: storedavailablecalendars[index].icon,
                            color: storedavailablecalendars[index].color,
                            visible: storedavailablecalendars[index].visible,
                            prependIcon: storedavailablecalendars[index].icon,
                            name: storedavailablecalendars[index].name,
                            creatorName: storedavailablecalendars[index].creatorName,
                            createdOn: storedavailablecalendars[index].createdOn,
                            editedOn: storedavailablecalendars[index].editedOn,
                            subscribers: storedavailablecalendars[index].subscribers,
                            creatorUID: storedavailablecalendars[index].creatorUID,
                            subscribed: isSubscribed
                          }
                          state.items.push(newCalendar)

                    }
                }

            }
            catch(e){
                console.log(e)
            }
        }
        
    }
}
  
  