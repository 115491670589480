<template>
    <v-card
        location="center"
        style="
            border-radius: 8px;
            margin-top: 15px;
            box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
            padding: 12px;
            margin-left: 2px;
        "
    >

    <div style="
          font-size: 15px;
          text-align: left;
          font-weight: 500;
          letter-spacing: -1px;
      ">
    {{props.calendarInfo.specialday}}
    </div>

    <div class="container">

      <div style="
          font-size: 15px;
          text-align: left;
          font-weight: 300;
          letter-spacing: -1px;
      ">
        {{props.calendarInfo.country + " - " + props.calendarInfo.subdivision}}
      </div>
          
      <div class="settings-div" >
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
          <template v-slot:activator="{ props }">
            <div class="plus-button" v-bind="props">
                <PhGearSix class="setting-icon" />
              </div>
          </template>

          <div style="
                margin-left:-18px;
                margin-bottom: -12px;
            ">
             <annualHolidays
                    @close-menu="closeMenu"
                    prop_mode="edit"
                    :prop_calendarId = "props.calendarId"
                    :prop_docId = "props.docId"
                    :allowDelete = true
                 />
            </div>

        </v-menu>
      </div>
    </div>
  </v-card>

</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import annualHolidays from './AnnucalHolidays.vue'
import {useStore} from "vuex";

const store = useStore();



const props = defineProps({
    docId: {
    type: String,
    required: true
  },
  calendarId:{
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  },
  calendarInfo:{
    type: Object,
    required: true
  }

});

onMounted(() => {
  store.state.settings.Log_Status = ""
  store.state.settings.AnnucalSaveText = "Speichern"
  store.state.settings.AnnucalVcardHeadline = "Kalender bearbeiten"
  store.state.settings.normlaErrorMessage = ""
});


const menu = ref(false);

function closeMenu() {
  menu.value = false;
}


</script>

<style scoped>

.plus-button:hover {
    cursor: pointer;
}
  
.settings-div{
    margin-top: 2px;
    width:24px;
    height: 24px;
    background-color: #E7E5DF;
    border-radius: 8px;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
  }

.settings-div:hover{
  background-color: #34332F;
}

.setting-icon{
  color: #34332F;
  width: 18px;
  height: 18px;
  margin-top: 5px;
}

.setting-icon:hover{
  color: white;
}

.container {
  display: grid;
  grid-template-columns: 1fr 25px; /* 2 gleiche Spalten */
  gap: 10px;
}

</style>