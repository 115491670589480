export default {
  "January": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janeiro"])};fn.source="Janeiro";return fn;})(),
  "February": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fevereiro"])};fn.source="Fevereiro";return fn;})(),
  "March": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchar"])};fn.source="Marchar";return fn;})(),
  "April": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])};fn.source="Abril";return fn;})(),
  "May": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maio"])};fn.source="Maio";return fn;})(),
  "June": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junho"])};fn.source="Junho";return fn;})(),
  "July": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julho"])};fn.source="Julho";return fn;})(),
  "August": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])};fn.source="Agosto";return fn;})(),
  "September": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setembro"])};fn.source="Setembro";return fn;})(),
  "October": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outubro"])};fn.source="Outubro";return fn;})(),
  "November": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembro"])};fn.source="Novembro";return fn;})(),
  "December": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezembro"])};fn.source="Dezembro";return fn;})(),
  "Monday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda-feira"])};fn.source="Segunda-feira";return fn;})(),
  "Tuesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terça-feira"])};fn.source="Terça-feira";return fn;})(),
  "Wednesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta-feira"])};fn.source="Quarta-feira";return fn;})(),
  "Thursday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta-feira"])};fn.source="Quinta-feira";return fn;})(),
  "Friday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexta-feira"])};fn.source="Sexta-feira";return fn;})(),
  "Saturday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])};fn.source="Sábado";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])};fn.source="Domingo";return fn;})(),
  "Public holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feriados públicos"])};fn.source="Feriados públicos";return fn;})(),
  "School holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Férias escolares"])};fn.source="Férias escolares";return fn;})(),
  "Calendar weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanas do calendário"])};fn.source="Semanas do calendário";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte-se"])};fn.source="Conecte-se";return fn;})(),
  "No account yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma conta ainda"])};fn.source="Nenhuma conta ainda";return fn;})(),
  "Repeat password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita a senha"])};fn.source="Repita a senha";return fn;})(),
  "Forgot password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu sua senha"])};fn.source="Esqueceu sua senha";return fn;})(),
  "Register now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar agora"])};fn.source="Registrar agora";return fn;})(),
  "Reset now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar agora"])};fn.source="Reiniciar agora";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])};fn.source="Registro";return fn;})(),
  "Already registered?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já registrado?"])};fn.source="Já registrado?";return fn;})(),
  "Log in now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecte-se agora"])};fn.source="Conecte-se agora";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])};fn.source="Fechar";return fn;})()
}