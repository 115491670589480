<template>
    <v-card
      location="center"
      offset-y
   
      style="
        border-radius: 8px;
        margin-top:-11px;
        margin-left: 25px; /* Gleichmäßiger Abstand um die Komponente */
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
      "
    >
      <div class="grid-container">
        <div class="grid-item-links">Kalender hinzufügen</div>
        
        <div class="grid-item-rechts">

          <v-menu v-model="menu" :close-on-content-click="false" location="end">

            <template v-slot:activator="{ props }">
              <div class="plus-button" v-bind="props">
                  <PhPlus class="plus-icon" />
                </div>
            </template>
          
            <div style="
                width: 450px;
                background-color: rgb(253, 253, 253);
              ">
              <!--
                prop_type="1"
                1 = Internal Kalender
                2 = Holiday, Shool holidays and other international events
                3 = Subscribed ICal from Google, Apple, and so on ...
              -->
                <AddNewCalendar 
                :first_column_width='14'
                  @closeCalendarMenu="closeCalendarMenu"  
                  header="Kalender erstellen"
                  prop_mode="new"
                  prop_kalendername=""
                  prop_kalenderbeschreibung=""
                  prop_externalCalendarLink=""
                  prop_type=1
                  :prop_radios= true
                  :calendarColor=getCalendarColor()
                  prop_icon='ph-CalendarCheck'
                  prop_calendarId=""
                  :prop_color= props.calendarColor
                  :prop_creatorUID=this.$store.state.user.userID
                  />
              </div>

        </v-menu>



        </div>
        <div class="grid-item">&nbsp;</div>
        <div class="grid-item">&nbsp;</div>
        <div class="grid-item-links">Kalender abonnieren</div>
        <div class="grid-item-rechts">
          <div class="plus-button">
            <PhPlus class="plus-icon" />
          </div>
        </div>
        <div class="grid-item">&nbsp;</div>
        <div class="grid-item">&nbsp;</div>
        <div class="grid-item-links">Öffentliche Kalender</div>
        <div class="grid-item-rechts">
          <div class="plus-button">
            <PhMagnifyingGlass class="plus-icon" />
          </div>
        </div>
      </div>
    </v-card>
  </template>

<script setup>
import { ref, defineProps, computed, onMounted } from 'vue';
import AddNewCalendar from "./AddNewCalendar.vue";
import {useStore} from "vuex";

const store = useStore();
    const { state } = store;

// Define props
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  calendarColor: {
    type: String,
    default: '#DAFDD8' // Default value if not provided
  }
});

const menu = ref(false);


onMounted(() => {
  store.state.settings.Log_Status = ""
  store.state.settings.AnnucalSaveText = "Kalender Speichern"
  store.state.settings.AnnucalVcardHeadline = "Kalender erstellen"
  store.state.settings.normlaErrorMessage = ""
});

const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

function closeCalendarMenu() {
  menu.value = false;
}

function getCalendarColor(){
  switch (mycalendars.value.length) {
    case 1:
        return '#4BA0C4'
    case 2:
        return '#5C70D7'
    case 3:
        return '#9D41D5'
    case 4:
        return '#D272DA'
    default:
        return '#6AD069'
    }
}



</script>

  
  <style scoped>

  .grid-container {
    display: grid;
    grid-template-columns: 130px auto;
    background-color: #ffffff;
    padding: 16px; /* Gleichmäßiges Padding */
  }
  
  .grid-item-links {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -1px;
  }
  
  .grid-item-rechts {}
  
  .plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px; /* Gleiche Größe wie Checkbox */
    height: 18px; /* Gleiche Größe wie Checkbox */
    background-color: #e7e5df;
    border: 1px solid #e7e5df;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
  }
  
  .plus-icon {
    color: #191816;
    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .plus-button:hover {
    background-color: #191816;
  }
  
  .plus-button:hover .plus-icon {
    color: white;
  }
  </style>
  