
import timezones from "../../assets/timezones.json"

export default {

    state(){
        return{
            items: [],
        }
    },

    actions:{
        getTimezones({commit}){
            commit("setTimezones");
        },

    },
    
    mutations:{

        setTimezones(state){
            state.items= timezones   
        },
        
    }
}



      