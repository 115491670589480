<template>

  <!-------------------------------------------------------------------------------------------->
  <!------------------------------------ Annucal VCard ----------------------------------------->
  <!-------------------------------------------------------------------------------------------->
  <AnnucalVCard
    :padding_botton='4'
    :first_column_width='14'
    :calendarColor = props.calendarColor
    :showCalendarName = true
    :showvCardActions = false
    :VcardWitdh = '380'
    :calendarName="calendarName"
    @close-menu="closeMenu"
    @saveNew="saveNew"
    style="
      margin-left:8px;
      margin-right:8px;
    "
  >

  <!-------------------------------------------------------------------------------------------->
  <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
  <!-------------------------------------------------------------------------------------------->
  <template v-slot:middle>
      <div class="grid-container">
    
      <!----------------------------------  Input Textbox ------------------------------------------>
      <AnnucalTextfieldVue
        labeltext="textinput"
        v-model="textInput" 
        label = "Name des Events"
        labelType="normal"
      />
      
      <!------------------------------------  Icon trash ------------------------------------------->
      <div style="padding-left: 18px;" v-if="showSavingCircleDeleteEvent">
        <v-progress-circular class="circular-progress-trash" 
          indeterminate :size="20" 
          :width="2"
          color="#85827C"
          ></v-progress-circular>
      </div>

      <div v-if="!showSavingCircleDeleteEvent">
        <div  v-if="props.prop_mode == 'edit'" >
        <deleteButton
          :active = true
          @childClicked="deleteEvent($event)" 
         />
      </div>
      <div v-if="props.prop_mode == 'new'">
        <deleteButton
          :active = false
         />
      </div>
      </div>
    
      <!----------------------------------  Icon Black Next ---------------------------------------->
      <div v-if="showSavingCircleNewEvent">
        <v-progress-circular class="circular-progress-save" 
          indeterminate :size="20" 
          :width="2"
          color="#85827C"
          ></v-progress-circular>
      </div>
      <div v-if="!showSavingCircleNewEvent" >
        <div class="plus-button" v-on:click="saveNew" v-if="prop_mode == 'new'">
          <PhArrowRight class="plus-icon" />
        </div>
        <div class="plus-button" v-on:click="saveEdit" v-if="prop_mode == 'edit'">
          <PhArrowRight class="plus-icon" />
        </div>
      </div>
    </div>
    </template>
  </AnnucalVCard>

</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from 'vue';
import {useStore} from "vuex";
import { useToast } from "vue-toastification"
//import format from 'date-fns/format' 
import calenderCore from "../composition/calenderCore";
import deleteButton from "../components/DeleteButton.vue"
const store = useStore();
const { state } = store;
const toast = useToast(); 
const calenderCore1 = calenderCore(); 
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalTextfieldVue from "./AnnucalTextfield.vue"

const showSavingCircleNewEvent = ref(false)
const showSavingCircleDeleteEvent = ref(false)

const deletingEvent = ref(false)
const textInput = ref('')

const events = computed({
        get() {
            return state.events.items;
        },
})

// Define props
const props = defineProps({
eventidDB:{
  type: String,
  required: true
},
calendarId:{
  type: String,
  required: true
},
calendarName: {
  type: String,
  required: true
},
calendarColor:{
  type: String,
  required: true
},
prop_mode:{
  type: String,
  required: true
},
date:{
  type: String,
  required: true
},
date_json:{
  type: String,
  required: true
},
eventName:{
  type: String,
  required: true
}
});

onMounted(() => {
  textInput.value = props.eventName
  calenderCore1.setEditMode(props.date, props.calendarId)
  store.state.settings.AnnucalVcardHeadline = props.calendarName
  store.state.settings.normlaErrorMessage = ""
});



async function saveNew(){

  if(deletingEvent.value == true){
    return
  }

  if(textInput.value == ""){
    //toast.error("Bitte einen Titel für das Ereignis eintragen")
    store.state.settings.normlaErrorMessage = "Event Name erforderlich"
    return
  }

  showSavingCircleNewEvent.value = true
  //var isValid = await this.v$.$validate();

  //if (isValid){
  console.log("Props.date: " + props.date)
  console.log("test: " + store.state.dayevent.dateTo)
  console.log("Props.date_json: " + props.date_json)
       
          if(store.state.settings.Authenticated == true){
          let newEvent = {
            calendarId: props.calendarId,
            color: props.calendarColor,//store.state.settings.currentCalendarColor, 
            title: textInput.value,
            datefrom: props.date , //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
            dateto: props.date, //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
            datefromJson: props.date_json,//store.state.dayevent.dateFrom, 
            datetoJson: props.date_json,//store.state.dayevent.dateTo,
            icon: 'ph-CalendarCheck', 
            fromHour: store.state.dayevent.valueFromHour,
            fromMinute: store.state.dayevent.valueFromMinute,
            toHour: store.state.dayevent.valueToHour,
            toMinute: store.state.dayevent.valueToMinute,
            isFullDay: store.state.dayevent.isFullDay,
            creatorUID: store.state.user.userID,
            calendarName: props.calendarName,
            additionalInfos: '',// this.additionalInfos,
            rRules: (store.state.dayevent.rRules == undefined)? "ONCE" :  store.state.dayevent.rRules ,
            location: "",
            removedDays: []
          }
          await store.dispatch("createEvent", newEvent); 
          
        
        
      }

      // Refresh Events Indexlist
      for(let index = 0; index < store.state.events.items.length; ++index){
          store.state.events.items[index].index = index;
      }

      // Write each event index in the events of each mycalendars
      for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
        store.state.mycalendars.items[calenderIndex].events = []
          for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
              if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
                store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
              }
          } 
      } 


      //if(this.selectedDay != null){
      //    this.selectedDay.cursor = "s-resize";
      //} 

      //store.state.settings.eventName = ""
      calenderCore1.updateCalender();
      closeMenu()
      //closeCalendarMenu()

    //}
    /*else{
      if(this.v$.eventName.required.$invalid){
        toast.error("Bitte einen Titel für das Ereignis eintragen")
      }
      else if(this.v$.eventName.maxLength.$invalid){
        toast.error("Der Eventname darf aus max. 25 Zeichen bestehen")
      }
    }*/
}

async function saveEdit(){
    
  if(deletingEvent.value == true){
    return
  }
  
  store.state.dayevent.removedDays = []
   /* switch (selectedOption.value) {
    case '1':
      console.log('Diesen Termin');
      setRemovedDays(1);
      break;
    case '2':
      console.log('Diesen Termin und alle folgenden');
      setRemovedDays(2);
      break;
    case '3':
      console.log('Alle Termine');
      store.state.dayevent.removedDays = [];
      // Do nothing
      break;
    default:
      console.log("Error - AddNewItemBox - saveEdit - switch(selectedOption.value)");
}*/

    if(textInput.value == ""){
      toast.error("Bitte einen Titel für das Ereignis eintragen")
      return
    }

    showSavingCircleNewEvent.value = true
   
    //var isValid = await this.v$.$validate();

    //if (isValid){
          
      if(store.state.settings.Authenticated == true){
            let newEvent = {
              calendarId: props.calendarId,
              eventidDB: props.eventidDB,
              title: textInput.value,
              datefrom: props.date , //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
              dateto: props.date, //format(store.state.dayevent.dateTo, 'yyyy-MM-dd')
              datefromJson: props.date_json,//store.state.dayevent.dateFrom, 
              datetoJson: props.date_json,//store.state.dayevent.dateTo,
              fromHour: store.state.dayevent.valueFromHour,
              fromMinute: store.state.dayevent.valueFromMinute,
              toHour: store.state.dayevent.valueToHour,
              toMinute: store.state.dayevent.valueToMinute,
              isFullDay: store.state.dayevent.isFullDay,
              additionalInfos: "", //this.additionalInfos,
              rRules: store.state.dayevent.rRules,
              location: "",
              removedDays: store.state.dayevent.removedDays
            }
            await store.dispatch("updateStoredEvent", newEvent);
        
        }

      //  if(this.selectedDay != null){
      //      this.selectedDay.cursor = "s-resize";
      //  } 

        //store.state.settings.eventName = ""
        calenderCore1.updateCalender();
        

      //}
 

  }


async function deleteEvent(){

  showSavingCircleDeleteEvent.value = true

  deletingEvent.value = true

  calenderCore1.unSetEditMode();

  let Event = {
      calendarId: props.calendarId,
      eventidDB: props.eventidDB,
  }
  await store.dispatch("deleteEventFromCalendar", Event);

  // delete event from eventlist
  for(let index = 0; index < events.value.length; ++index){
        if(events.value[index].calendarId == props.calendarId && events.value[index].eventidDB == props.eventidDB){
            events.value.splice(index,1);
    }
  }

  // Refresh Events Indexlist
  for(let index = 0; index < store.state.events.items.length; ++index){
      store.state.events.items[index].index = index;
  }

  // Write each event index in the events of each mycalendars
  for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
    store.state.mycalendars.items[calenderIndex].events = []
      for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
          if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
            store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
          }
      } 
  } 


  
      
  calenderCore1.updateCalender();
  



//emits('closeNewEventMenue')

} 


const emit = defineEmits(['close-menu'])

function closeMenu(){
    emit('close-menu')
}



</script>

<style scoped>

.grid-container {

padding-top: 16px;
display: grid;
grid-template-columns: auto 55px 61px;
}

.small-textbox {
  width: 208px; /* Adjust the width as needed */
  height: 22px;
  margin-top: 8px; /* Optional: for better appearance */
  margin-left: 8px;
  font-size: 14px; /* Optional: adjust font size */
  border-radius: 4px; /* Rounded corners */
  background-color: #F6F2EF; /* Grey background */
  border: 0px  /* Optional: border styling */
}

.small-textbox::placeholder {
  padding-left: 4px; /* Padding for the placeholder text */
}

.next-icon {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.circular-progress-trash{
  margin-top: 11px;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.circular-progress-save{
  margin-top:11px;
  margin-left:24px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.arrow-back{
  margin-top: 1px;
  margin-right: 10px;
  width: 44px;
  height: 44px;
  background-color: black;
  border-radius: 4px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.icon-wrapper {
display: inline-block;
}

icon-trash-activ:hover{
transform: scale(1.5);
}

.icon-trash-activ {

  margin-top: 15px;
  margin-left: 15px;
  transition: transform 0.2s ease-in-out;
  color: rgb(255, 0, 0); 
}

.icon-trash-inactiv {
  padding-top: 5px;
  font-size: 18px;
  opacity: 1; /* Icon initial unsichtbar */
  transition: opacity 0.3s ease; /* Weicher Übergang für die Sichtbarkeit */
  color: rgb(198, 198, 198); /* Icon weiß */
}

.headline{
  padding-top: 2px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: -1px;
}

.headline-grid-container {
  padding-left:8px;
  display: grid;
  grid-template-columns: 24px 255px 70px ;
  height: 40px;
}

.headline-grid-item {
  display: flex;
  align-items: center;
  justify-content: start; /* links ausgerichtet */   
}

.headline-grid-item-close{
padding-top: 8px;

}

.headline-grid-item-close:hover{
font-weight: 500;
cursor: pointer; 

}

.dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--dot--color);
  margin-top: 4px;
  margin-left: 0px;
}

.plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px; 
    height: 45px; 
    background-color: #E7E5DF;
    border: 1px solid #E7E5DF;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 0px;
    margin-left:15px;
  }
  
  .plus-icon {
    color: #191816;
    font-size: 24px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .plus-button:hover {
    background-color: #191816;
    cursor: pointer; 
  }
  
  .plus-button:hover .plus-icon {
    color: #F6F2EF;
  }

  .button-text-cancel{
    font-size: 15px;
    font-weight: 400;
    color: #191816;
    letter-spacing: -1px;
    
    padding-left: 30px;
}

.button-text-save{
    font-size: 15px;
    font-weight: 400;
    color: white;
    letter-spacing: -1px;
    padding-right: 5px;
    padding-left: 5px;
}


</style>


