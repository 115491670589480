
<template>

<div class="grid-padding">
    <new-calendar-modal/>
 
    <div class="grid-container">

        <div class="grid-item" v-for="(month, index) in months" 
            :key="index" 
            :style="{ gridColumn: `span ${daysInMonths[index]}` }"
            style="
            background-color: #d5d5d5;
            padding-top:5px;
            border-top: 1px rgb(197, 194, 194);
            border-left: 1px rgb(17, 14, 14);
         
            font-size: larger; 
            font-weight: 800; 
            color:rgb(97, 97, 97)
            "
            >
        {{ month }}
      </div>
      <template  v-for="row in 1" :key="row" >
            <div  v-for="column in 366" :key="column" 
            class="grid-item"
            style="
            font-size: 15px ;
            font-weight: 600;
            padding-top:7px;
            border-top: 1px rgb(197, 194, 194);
            border-left: 1px rgb(197, 194, 194);
            background-color:rgb(227, 227, 227);
            color: black;
            "
            >{{getWochentag(column) }}
            </div>
        </template>

        <template  v-for="row in 1" :key="row" >
            <div  v-for="column in 366" :key="column" 
            class="grid-item"
            style="
             font-weight: 500;
             padding-top:8px;
            background-color:rgb(227, 227, 227);
            border-top: 1px rgb(197, 194, 194);
            border-left: 1px rgb(197, 194, 194);
            color: black;
            font-size: smaller;
            "
            >{{getDayInfo(column) }}</div>
        </template>

       

        <template v-for="calendar in myCalendars" :key="calendar" >
            <div  v-for="column in 366" :key="column" class="grid-item" 
          
            style="
               border-top: 1px rgb(197, 194, 194);
            border-left: 1px rgb(197, 194, 194);"
            >
                <PopoverCalenderdayFullLineVue placement="right-start" 
                :item=getObject(column)
                :calendarId=calendar.calendarId
                />
            </div>
        </template>
        
    </div>
  
</div>




</template>
    
<script>

//import PopoverTranspose from "../components/PopoverCalenderdayTranspose";
//import AllDaysofYear from "../components/AllDaysOfYear.vue";
import calenderCore from "../composition/calenderCore";
import { useStore } from "vuex";
import { computed } from "vue";
import PopoverCalenderdayFullLineVue from '../components/PopoverCalenderdayFullLine.vue';
//import IntroModal from '../components/IntroModal.vue';
//import NewCalendarModal from '../components/NewCalendarModal.vue';

export default{

    components:{
     PopoverCalenderdayFullLineVue
        //AllDaysofYear,
        //PopoverTranspose,
    //IntroModal,
    //NewCalendarModal,
    
    },

    data(){
    return {
        insertEventisOpen: false,
        deleteEventisOpen: false,
        selectedDate: "",
        selectedEvent: "",
        deleteBackgroundColor: "#00ff00",
        calenderCore1 : calenderCore(),
        
        months: ["Januar 2024", "Februar 2024", "März 2024", "April 2024", "Mai 2024", "Juni 2024", "Juli 2024", "August 2024", "September 2024", "Oktober 2024", "November 2024", "Dezember 2024"],
      daysInMonths: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      days: [],
      weekdayAbbreviations: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]
        
    }
    },


    
    
    computed:{

        myCalendars(){
            return this.$store.state.mycalendars.items;
        },

        items(){
            return this.$store.state.calender.items;
        },
        events(){
            return this.$store.state.events.items;
        }
        },

        async created(){
        this.$store.dispatch("getCalender");
        this.$store.dispatch("getTimezones");
        this.$store.dispatch("getPublicHolidays"); 
        this.$store.dispatch("getShoolholidays"); 
        this.$store.dispatch("getContent"); 
            
        const calenderCore1 = calenderCore();
        calenderCore1.updateCalender();

        const store = useStore();
        const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);

        if(isAuthenticated.value == true){
            this.$store.state.settings.Authenticated = true
            if(this.$store.state.settings.fetchedDataFromDb == false){
            //this.$store.dispatch("getMyCalendars");
            await this.$store.dispatch("getmyCalendarsFromDB");
            //this.$store.dispatch("getMyEvents"); 
            await this.$store.dispatch("getmyEventsFromDB",this.$store.state.user.userID);
            //this.$store.dispatch("getAvailableCalendars");
            await this.$store.dispatch("refreshIndexList");
            await this.$store.dispatch("getavailableCalendarsFromDB", this.$store.state.user.userID);
            this.$store.state.mycalendars.items = this.$store.state.storedmycalendars.items
            this.$store.state.availablecalendars.items = this.$store.state.storedavailablecalendars.items
            this.$store.state.settings.fetchedDataFromDb = true;
            this.$store.state.events.items = this.$store.state.storedevents.items
            await this.$store.dispatch("getmydaysWithEventsFromDB", {
                    events: this.$store.state.events.items, 
                    myCalendars: this.$store.state.mycalendars.items, 
                    calender: this.$store.state.calender.items});
            calenderCore1.updateCalender();
            this.$store.state.settings.startPageOpen = false; 
            }
            else{
            this.$store.state.events.items = this.$store.state.storedevents.items
            calenderCore1.updateCalender();
            }
        }
        else{
            this.$store.dispatch("getMyEvents"); 
            calenderCore1.updateCalender();
            this.$store.state.settings.introPageToShow = 1;
            this.$store.state.settings.startPageOpen = true;
        }

        },

        async mounted() {
        window.addEventListener('resize', this.getDimensions);
        document.addEventListener( "keydown", this.onKeydown );
        document.addEventListener( "keyup", this.onKeyup );

           // Erstelle ein neues Date-Objekt für den 1. Januar 2024
    let currentDate = new Date(2024, 0, 1);
    // Iteriere über alle Tage des Jahres
    for (let i = 0; i < 365; i++) {
      this.days.push(currentDate.getDate());
      // Erhöhe das Datum um einen Tag
      currentDate.setDate(currentDate.getDate() + 1);
    }
      
        },

        unmounted() {
        window.removeEventListener('resize', this.getDimensions);
        },

        methods: {

            getDayInfo(dayNumber) {
  const date = new Date(new Date().getFullYear(), 0); // Erstellt ein Datum am 1. Januar des aktuellen Jahres
  date.setDate(dayNumber); // Setzt das Datum auf den angegebenen Tag des Jahres

  //const month = date.getMonth() + 1; // Monate sind nullbasiert, daher fügen wir 1 hinzu
  const dayOfMonth = date.getDate();

  //return `${dayOfMonth}.${month}`;
  return dayOfMonth;
},

 getWochentag(tagNummer) {
    // Erstelle ein Datum-Objekt für den angegebenen Tag im Jahr 2024
    var datum = new Date('2024-01-01'); // Startdatum ist der 1. Januar 2024
    datum.setDate(datum.getDate() + tagNummer - 1); // Setze das Datum entsprechend der Tagnummer
    
    // Erstelle ein Array mit den Wochentagen in deutsch
    var wochentage = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    
    // Gib die Abkürzung des Wochentags des Datums zurück
    return wochentage[datum.getDay()];
}, 

getMonth(x){
    switch (x) {
    case 1:
        return 31
    case 2:
        return 29
    case 3:
        return 31
    case 4:
        return 30
    case 5:
        return 31
    case 6:
        return 30
    case 7:
        return 31
    case 8:
        return 31
    case 9:
        return 30
    case 10:
        return 31
    case 11:
        return 30
    case 12:
        return 31
    }
},

    getObject(x){
        
        try{

        var tag = 0;

        switch (true) {
            case x < 32: // Jan 31
                tag = x;
                break;
            case x < 61: // Feb
                tag = x -31;
                break;
                case x < 92: // Mar
                tag = x-60;
                break;
                case x < 122: // Apr
                tag = x-91;
                break;
                case x < 153: // Mai
                tag = x-121;
                break;
                case x < 183: // Jun
                tag = x-152;
                break;
                case x < 214: // Jul
                tag = x-182;
                break;
                case x < 245: // Aug
                tag = x-213;
                break;
                case x < 275: // Sep
                tag = x-244;
                break;
                case x < 306: // Oct
                tag = x-274;
                break;
                case x < 336: // Nov
                tag = x-305;
                break;
                case x < 367: // Dez
                tag = x-335;
                break;
           
        }
        console.log("Tag: " + tag)
        if(tag < 1 | tag > 364){
            tag = 1
        }

            var firstElement = this.$store.state.calender.items[Math.floor(x/31)][tag-1];
            var accountName = firstElement;
            return accountName
        }catch{
            console.log("Fehler- Tag: " + tag)
            return "x"
        }
    
    },

        getMonthNumber(mydate){
            const today = new Date(mydate);
            return today.getMonth() + 1;
                //dateFormated_Json
        },

        getDimensions() {
            this.$store.dispatch("getCalender"); 
        },

        onKeyup (e) {
            if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = false;
            this.$store.state.settings.selectedCursor = 'default'
            
        }
        },

        onKeydown  (e) {
        if(e.key == 'd'){
            this.$store.state.settings.ctrlPressed = true;
            this.$store.state.settings.selectedCursor = 'grab'
            
        }
    }      
    }
}

</script>

<style scoped>

.grid-container34 {
  width: 100%;
  overflow-x: auto; /* Horizontales Scrollen aktivieren */
}

.grid34 {
  display: flex;
  flex-direction: column;
  width:155px;
}

.row {
  display: flex;
  height: 40px;
}

.cell {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  width: 30px;

}

.vl {
    border-left: 1px solid rgb(96, 96, 96);
    height: 36px;
}

.mycontainer {
    display: Grid;
    flex-direction:column;
    flex-wrap: wrap;
    max-height: 120vh;
    overflow-x: auto; /* Horizontales Scrollen aktivieren */
}

.grid-container22 {

  display: grid;
 
    grid-auto-flow: column;
    padding-left: 0px;
    grid-template-columns: repeat(365, 0.3%); 
    overflow-x: scroll;
    overscroll-behavior-inline:none;

}

.item {
    margin: 0.5rem;
    height: 7px;
}




.main-grid{

display: grid;
grid-template-columns: 40px auto;

}

.month-grid{
height: 55px;
}


@media (min-width: 100px){
.grid-container112 {
    display: grid;
    grid-auto-flow: column;
    padding-left: 0px;
    grid-template-columns: repeat(31, 5%); 
    overflow-x: scroll;
    overscroll-behavior-inline:none;
    overscroll-behavior-block: contain
}
}



@media (min-width: 800px){
.grid-container112 {
    display: grid;
    grid-auto-flow: column;
    padding-left: 0px;
    grid-template-columns: repeat(31, 12%); 
    overflow-x: scroll;
    overscroll-behavior-inline: contain;
    align-content: stretch;
    
}
}

.grid-container32 {
  width:1300px;
  display: grid;
  grid-template-columns: repeat(365, 25px); /* Erstellt 365 Spalten mit einer Breite von 100px */
  gap: 10px; /* Optional: Legt den Abstand zwischen den Gitterelementen fest */
  overflow-x: auto; /* Horizontales Scrollen aktivieren */
  white-space: nowrap; /* Verhindert, dass Gitterelemente umgebrochen werden */
}

@media (min-width: 1200px){
    .grid-container112 {
  width:1300px;
  display: grid;
  grid-template-columns: repeat(365, 1.55%); /* Erstellt 365 Spalten mit einer Breite von 100px */
  gap: 10px; /* Optional: Legt den Abstand zwischen den Gitterelementen fest */
  overflow-x: auto; /* Horizontales Scrollen aktivieren */
  white-space: nowrap; /* Verhindert, dass Gitterelemente umgebrochen werden */
}
}

    
.day-grid-container{
    display: grid;
    
}

day-grid-container:hover{

}

.day-item {
    border-radius: 0px;
    cursor: pointer;
    border: 0px solid rgba(210, 202, 202, 0.802);
    background-color: rgba(255, 255, 255, 0.758);
    padding-top: 5px;
    color:#434343;
    font-size: medium;
    font-weight:300;
    text-align: center;
    user-select: none;
}

.grid-padding{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 47px;
    user-select: none;
    
    
    
}

.center {
  border: 5px solid;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 10px;
}

.top-grid-item-year {
    padding-top: 14px;
    font-size: 28px;
    font-weight:900;
    text-align: center;
    vertical-align:top;
    user-select: none;
}

.the-sliding-grid{
    height: 100vh;
    display: grid;
    place-items: center;
    background: #222;
    }

    .slider-container{
    height: 500px;
    width: 800px; 
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    }

    .slider-container-slide{
    scroll-snap-align: start;
    }

    .slider-container-img{
    width: 800px;
    height: 100%;
    object-fit: cover;
    }

.grid-container {
    overflow-x:scroll; /* Horizontales Scrollen aktivieren */
    display: grid;
    grid-template-columns: repeat(366, 1fr); /* 365 Spalten */
    
    gap: 0px; /* Abstand zwischen den Grid-Elementen */
  }

    .grid-item {
    background-color: white;
    border: 1px solid rgb(196, 196, 196);
    padding: 1px;
    text-align: center;
    min-width: 39px; 
    min-height: 39px;
    font-size: 24px;
    grid-row: span 3;
  }

</style>
    
    
    
    
    
  
  