<template>
  <div class="page-wrapper vertical-center">
<div >
    <div class="cointainer is-fluid has-text-centered">
      <div class="column is-4 is-offset-4">
        <h3 class="title has-text-grey">ANMELDEN</h3>
          <HelloI18n />
        <div class="box">
          <form>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.email"
                  class="input is-large"
                  type="email"
                  placeholder="Email"
                  autofocus=""
                  autocomplete="email">
              </div>
            </div>
            <div class="field">
              <div class="control">
                <input
                  v-model="form.password"
                  class="input is-large"
                  type="password"
                  placeholder="Passwort"
                  autocomplete="current-password">
              </div>
            </div>
            <button
              @click="login"
              :disabled="isProcessing"
              type="button"
              class="button is-block is-info is-large is-fullwidth"
            >
              Einloggen
            </button>
          </form>
          <p class="white-text">test</p>
        </div>
        <p>
          <a href="#" class="has-text-grey infotext">{{ $t("No account yet") }}?</a>&nbsp;
          <router-link
                to="/register"
                class="infotext"
               >
               {{ $t("Register now") }}
              </router-link>
            </p>
            <p>
              <a href="#" class="has-text-grey infotext">{{ $t("Forgot password") }}?</a>&nbsp;
          <router-link
          class="infotext"
                to="/forgotpassword"
               >
                Jetzt zurücksetzen
              </router-link></p>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import useAuth from '../composition/useAuth';

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    }
  },

  setup() {
    return useAuth();
  },

  watch: {
    isAuthenticated(isAuth) {
      if (isAuth) { this.$router.push("/calender"); }
    }
  },

  methods: {
    login() {
      this.$store.dispatch("user/login", this.form);
    }
  }
}
</script>

<style scoped>
.white-text{
  color:white
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 30%;
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
  width:100%;
}
  .hero.is-success {
    background: #F2F6FA;
  }
  .hero .nav, .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .infotext{
    font-size: medium;
    font-weight: 400;
  }
  .box {
    margin-top: 1rem;
  }
  .avatar {
    margin-top: -70px;
    padding-bottom: 20px;
  }
  .avatar img {
    padding: 5px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
    box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
  }
  input {
    font-weight: 300;
  }
  p {
    font-weight: 700;
  }
  p.subtitle {
    padding-top: 1rem;
  }
</style>