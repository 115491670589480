export default {
  "January": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])};fn.source="Janvier";return fn;})(),
  "February": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])};fn.source="Février";return fn;})(),
  "March": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])};fn.source="Mars";return fn;})(),
  "April": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])};fn.source="Avril";return fn;})(),
  "May": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])};fn.source="Mai";return fn;})(),
  "June": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])};fn.source="Juin";return fn;})(),
  "July": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])};fn.source="Juillet";return fn;})(),
  "August": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])};fn.source="Août";return fn;})(),
  "September": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])};fn.source="Septembre";return fn;})(),
  "October": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])};fn.source="Octobre";return fn;})(),
  "November": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])};fn.source="Novembre";return fn;})(),
  "December": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])};fn.source="Décembre";return fn;})(),
  "Monday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])};fn.source="Lundi";return fn;})(),
  "Tuesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])};fn.source="Mardi";return fn;})(),
  "Wednesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])};fn.source="Mercredi";return fn;})(),
  "Thursday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])};fn.source="Jeudi";return fn;})(),
  "Friday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])};fn.source="Vendredi";return fn;})(),
  "Saturday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])};fn.source="Samedi";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])};fn.source="Dimanche";return fn;})(),
  "Public holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacances publiques"])};fn.source="Vacances publiques";return fn;})(),
  "School holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacances scolaires"])};fn.source="Vacances scolaires";return fn;})(),
  "Calendar weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaines calendaires"])};fn.source="Semaines calendaires";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])};fn.source="Connexion";return fn;})(),
  "No account yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de compte"])};fn.source="Pas encore de compte";return fn;})(),
  "Repeat password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])};fn.source="Répéter le mot de passe";return fn;})(),
  "Forgot password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])};fn.source="Mot de passe oublié";return fn;})(),
  "Register now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'S''inscrire maintenant'"])};fn.source="'S''inscrire maintenant'";return fn;})(),
  "Reset now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser maintenant"])};fn.source="Réinitialiser maintenant";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])};fn.source="Enregistrer";return fn;})(),
  "Already registered?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà enregistré?"])};fn.source="Déjà enregistré?";return fn;})(),
  "Log in now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecte-toi maintenant"])};fn.source="Connecte-toi maintenant";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])};fn.source="Fermer";return fn;})()
}