
<template>

    <v-card
      variant="flat"
      color="white"
      width="40px"
      height="400px">

      <!--<div >
                <PhCalendar style="
               color: #34332F; 
        width: 20px;
        height: 20px;
        position: absolute;
 top:10px;
  left: 50%;
  transform: translate(-50%);
        "  />
            </div>-->

           

            <div class="checkbox-group">
              <CustomCheckbox
                v-for="checkbox in items"
                :key="checkbox.id"
                :id="checkbox.id"
                v-model:value="checkbox.checked"
                @state-change="handleStateChange"
                @hover="handleHover"
              />
            </div>



       <!-------------------------------------------------------------------------------------->
    <!--     Circular Procress Indicator while loading the calenders    -->
    <!-------------------------------------------------------------------------------------->

    <!--<div v-if="items.length == 0 && isAuthenticated  == true" style=" text-align: center;"> 
        <v-progress-circular
          indeterminate
          color="#f0f3f5"
        ></v-progress-circular>
    </div>-->

    <!-------------------------------------------------------------------------------------->
    <!--              Show Checkbox for each subscribed calender                          -->
    <!-------------------------------------------------------------------------------------->
    <!--<table>
      <tr>
      <td v-for="(item, index) in items" :key="index">
        <v-list-item 
          :key="i"
          :value="item"
          :color="item.color"
          :base-color="item.color"
          height="40px"
          variant="green"
          @click="setActiveCalendar(item.name, item.color, item.icon, item.calendarId, item.creatorUID)">
        
          <v-checkbox-btn   class="shrink mr-2"
          @change="showVisibleCalendars(item)"
          :model-value="item.visible">
          <v-tooltip
        activator="parent"
        location="top"
      >{{ item.name }}</v-tooltip></v-checkbox-btn>

          
    
        </v-list-item>
      </td>
    </tr>
    </table>-->

    </v-card>
   

  </template>


  <script>
  
  import calenderCore from "../composition/calenderCore";
  import iCalCore from "../composition/iCalCore";
  import {useStore} from "vuex";
  import {computed } from 'vue'
  import {db} from "../db";
  import { doc, updateDoc} from "firebase/firestore";
  //import CalendarMenu from "../components/CalendarMenu.vue";
  import useAuth from '@/composition/useAuth';
  import CustomCheckbox from './CustomCheckbox.vue';
  
  
  
  export default {

    components:{
      //CalendarMenu
      CustomCheckbox
    },


    data() {
      return {
        menu: false,
        checked: false,
        menuVisible: false,
        checkboxes: [
        { id: 'checkbox1', checked: false },
        { id: 'checkbox2', checked: false },
        { id: 'checkbox3', checked: false }
      ]

      },
      useAuth()
    },



    methods:{
      handleStateChange(id, checked) {
      console.log(`Checkbox with ID ${id} is now ${checked ? 'checked' : 'unchecked'}`);
    },
    handleHover(id) {
      console.log(`Hovering over Checkbox with ID ${id}`);
    },
    

       save(){
          this.menu = false
      },
       
     
    },

    
    
    setup(){
      const calenderCore1 = calenderCore();
      const iCalCore1 = iCalCore();
      const store = useStore();
      const { state } = store;

    
      
      const items = computed({
        get() {
          return state.mycalendars.items; 
        },  
      })

      function closeCalendarMenu(){
        
        for (let i2 = 0; i2 < items.value.length; i2++) {
          items.value[i2].menuopen = false
        }
        
      }
 
      function setActiveCalendar(calendar, color, icon, id, creatorUID){

          if(store.state.user.userID == creatorUID){
            state.settings.currentCalendarId = id;
            state.settings.currentCalendarName = calendar;
            state.settings.currentCalendarColor = color;
            state.settings.currentCalendarIcon = icon;
          }
    
      }



//https://npm.io/package/ical-date-parser
//https://www.kanzaki.com/docs/ical/rrule.html
//https://github.com/cwlsn/ics-to-json/blob/master/icsToJson.js

     

      

      async function  showVisibleCalendars(item){

        state.settings.currentVisibleCalendars = [];

        if(item.visible == true){

          item.visible = false;
          let newCalendar = {
            visible: false,
          };
          await updateDoc(doc(db, "users", store.state.user.userID, "myCalendars", item.docId), newCalendar);
          
        }else{

            if(item.externalCalendarLink != '' && item.externalCalendarLink != undefined){
              await iCalCore1.parseICALFile(item)
            }

            item.visible = true;
            let newCalendar = {
                visible: true,
            };
            await updateDoc(doc(db, "users", store.state.user.userID, "myCalendars", item.docId), newCalendar);
            }
            for (let i2 = 0; i2 < this.items.length; i2++) {

              if(this.items[i2].visible == true){
                state.settings.currentVisibleCalendars.push(this.items[i2].id)
              }
            } 
            calenderCore1.updateCalender();
      }

      return{
        
        setActiveCalendar,
        closeCalendarMenu,
        showVisibleCalendars,
        items,
        brandName: "Annucal - Plan ahead",
        menuItems: [],
        layerGridWidth: "90px auto",
        layerArrowImage: 'pfeilrechts3.png',
        buttonImage: 'newCalendarButton.png',
        isGridExpanded: false,
        checkbox1: true,
        checkbox2: false,
        dialogm1: '',
        dialog: false,
        radios: "private",
        items2: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      locations: ['top', 'bottom', 'start', 'end', 'center'],
      location: 'end',
      firstSelection:  items.value[0],
   
      }
    }
  }
  </script>
  
  <style lang="scss">
    @import '../assets/styles/variables.scss';
    @import '~bulma/bulma.sass';
    @import '../assets/styles/main.scss';
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
  
    $navbar-item-hover-color: red;
  
  </style>
  
  <style scoped>


.horizontal-virtual-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-list {
  display: flex;
  flex-direction: row;
}
.horizontal-list .v-list-item {
  margin-right: 6px; /* Optional: Abstand zwischen den Items */
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
  .grid-container {
      display: grid;
      grid-template-columns: 130px 50px;
      grid-gap: 2;
  } 
  .grid-container2 {
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
} 
  .color-selector2{
  width:25px;
  height:25px;
  border-radius: 25px;
  margin-top:10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}
  .list{
    background: #f6f8fc;
  }



.checkbox-group {
  display: flex;
  flex-direction: column;
}

  </style>
  



