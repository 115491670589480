<template>
    
    <div style="color:rgb(112, 112, 112)"> {{ $t( 'calendarColor')}}</div>
    
    <div class="grid-container" >
          <div v-for="(itemColor,i) in colors" 
            :key="i"
            @click="changeEventColor(itemColor)" 
            class="color-selector" 
            v-bind:style="{ 
              backgroundColor: itemColor,
              transform: currentSelectedColor == itemColor ? 'scale(1.25)' : 'scale(1.0)',
              
              }"
                v-on:mouseover="mouseover"
                v-on:mouseleave="mouseleave"
            >
              <div   
              v-if = "currentSelectedColor == itemColor" style=" z-index: -1;">
                <v-icon style="
                          padding-bottom: 6px;
                          padding-right: 6px;
                        "
                       
                        color=white
                        size="24px"
                        class="ma-2"
                        variant="text"
                        icon="mdi-check" 
                        dark v-bind="props"
                    />
              </div>
          </div>
        </div> 

    <!--<div class="grid-container" >
        <div v-for="(itemColor,i) in colors" 
        :key="i"
        @click="changeEventColor(itemColor)" 
        class="color-selector" 
        v-bind:style="{ backgroundColor: itemColor }"
        />
    </div> -->
    
</template>

<style scoped>

.grid-container {
    padding-left: 0px;
    display: grid;
    grid-template-columns: 50px 50px 50px 50px 50px 50px 50px 50px;
    height: 100px;
}

.color-selector{
    width:34px;
    height:34px;
  border-radius: 25px;
  margin-top:13px;
  margin-left: 0px;
  margin-right: 8px;
  margin-bottom: 0px;
  transition: transform .2s; /* Animation */
 border:1px solid rgb(147, 147, 147)
}

.color-selector:hover{
  cursor: pointer;
  transform: scale(1.3);
  
}



</style>

<script setup>
import {  ref, defineEmits, defineProps } from 'vue'

const emits = defineEmits(['changeCalendarColor'])

const props = defineProps({   
                    prop_calendarColor: String,
                }); 

const colors = [
  '#FF9933',
  '#FF6699',
  '#CC9933',
  '#33CC66',
  '#FF6633',
  '#CC66CC',
  '#999966',
  '#3399fe',
]


const currentSelectedColor = ref("#FF9933")
const calendarColor = ref('#3399fe')

function changeEventColor(selectedColor){
  calendarColor.value = selectedColor;
  currentSelectedColor.value = selectedColor;
  emits('changeCalendarColor', selectedColor)
}

const initialize = () => {
       
        calendarColor.value = props.prop_calendarColor
        currentSelectedColor.value = props.prop_calendarColor
    }   

  //  onMounted(() => initialize());

  initialize()

</script>