export default {
  "Januar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])};fn.source="January";return fn;})(),
  "Februar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])};fn.source="February";return fn;})(),
  "März": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])};fn.source="March";return fn;})(),
  "April": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])};fn.source="April";return fn;})(),
  "Mai": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])};fn.source="May";return fn;})(),
  "Juni": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])};fn.source="June";return fn;})(),
  "Juli": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])};fn.source="July";return fn;})(),
  "August": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])};fn.source="August";return fn;})(),
  "September": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])};fn.source="September";return fn;})(),
  "Oktober": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])};fn.source="October";return fn;})(),
  "November": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])};fn.source="November";return fn;})(),
  "Dezember": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])};fn.source="December";return fn;})(),
  "Monday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])};fn.source="Monday";return fn;})(),
  "Tuesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])};fn.source="Tuesday";return fn;})(),
  "Wednesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])};fn.source="Wednesday";return fn;})(),
  "Thursday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])};fn.source="Thursday";return fn;})(),
  "Friday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])};fn.source="Friday";return fn;})(),
  "Saturday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])};fn.source="Saturday";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])};fn.source="Sunday";return fn;})(),
  "Public holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public holidays"])};fn.source="Public holidays";return fn;})(),
  "School holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School holidays"])};fn.source="School holidays";return fn;})(),
  "Calendar weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar weeks"])};fn.source="Calendar weeks";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])};fn.source="Log in";return fn;})(),
  "No account yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account yet"])};fn.source="No account yet";return fn;})(),
  "Repeat password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])};fn.source="Repeat password";return fn;})(),
  "Forgot password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])};fn.source="Forgot password";return fn;})(),
  "Register now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now"])};fn.source="Register now";return fn;})(),
  "Reset now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset now"])};fn.source="Reset now";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])};fn.source="Register";return fn;})(),
  "Already registered?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered?"])};fn.source="Already registered?";return fn;})(),
  "Log in now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in now"])};fn.source="Log in now";return fn;})(),
  "close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])};fn.source="Close";return fn;})(),
  "newCalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Calendar"])};fn.source="New Calendar";return fn;})(),
  "description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])};fn.source="Description";return fn;})(),
  "calendarColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Color"])};fn.source="Calendar Color";return fn;})(),
  "subscribetoexternalcalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to external calendar"])};fn.source="Subscribe to external calendar";return fn;})(),
  "private": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["private"])};fn.source="private";return fn;})(),
  "public": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["public"])};fn.source="public";return fn;})(),
  "howtosubscribe1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I subscribe to my external calendars?"])};fn.source="How can I subscribe to my external calendars?";return fn;})(),
  "howtosubscribe2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To subscribe to one of your calendars, you can enter the ICal link of your calendar here."])};fn.source="To subscribe to one of your calendars, you can enter the ICal link of your calendar here.";return fn;})(),
  "howtosubscribe3": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is an Ical Link? (Link to explanation?)"])};fn.source="What is an Ical Link? (Link to explanation?)";return fn;})(),
  "howtosubscribe4": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I find the ICal link to my Google Calendar? (Link to YouTube instructions?)"])};fn.source="Where can I find the ICal link to my Google Calendar? (Link to YouTube instructions?)";return fn;})(),
  "howtosubscribe5": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I find the ICal link to my Apple calendar? (Link to YouTube instructions?)"])};fn.source="Where can I find the ICal link to my Apple calendar? (Link to YouTube instructions?)";return fn;})(),
  "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
  "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
  "unsubscribe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])};fn.source="Unsubscribe";return fn;})(),
  "newevent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Event"])};fn.source="New Event";return fn;})(),
  "additionalinformation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])};fn.source="Additional Information";return fn;})(),
  "repetitions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetitions"])};fn.source="Repetitions";return fn;})(),
  "once": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once"])};fn.source="Once";return fn;})(),
  "daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])};fn.source="Daily";return fn;})(),
  "weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])};fn.source="Weekly";return fn;})(),
  "monthly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])};fn.source="Monthly";return fn;})(),
  "ownselection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own Selection"])};fn.source="Own Selection";return fn;})(),
  "allday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Day"])};fn.source="All Day";return fn;})(),
  "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])};fn.source="from";return fn;})(),
  "until": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])};fn.source="until";return fn;})(),
  "availableCalendarsSearch": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search available calendars"])};fn.source="Search available calendars";return fn;})(),
  "language": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])};fn.source="Language";return fn;})(),
  "currentCalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Calendar"])};fn.source="Current Calendar";return fn;})(),
  "timeframe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeframe"])};fn.source="Timeframe";return fn;})(),
  "repeatevery": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat every"])};fn.source="Repeat every";return fn;})(),
  "end": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])};fn.source="End";return fn;})(),
  "repeat": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat at"])};fn.source="Repeat at";return fn;})(),
  "savecalendar": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Calendar"])};fn.source="Save Calendar";return fn;})(),
  "closewithx": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close X"])};fn.source="Close X";return fn;})(),
  "logouttext1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are currently logged in as "])};fn.source="You are currently logged in as ";return fn;})(),
  "logouttext2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to unsubscribe now?"])};fn.source="Do you want to unsubscribe now?";return fn;})(),
  "logout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])};fn.source="Log out";return fn;})()
}