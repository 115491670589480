
<template>

<component v-if="props.edit==false"
                
                :size="50" 
                :color=currentColor 
                weight="light" 
                v-bind="props"
                :is=currentIcon
            >
                </component>

    <v-menu v-if="props.edit==true"
        v-model="menu"
      :close-on-content-click="false"
      location="end"
    >
        <template v-slot:activator="{ props }">
            <component 
                class="grid-item"
                :size="52" 
                :color=currentColor 
                weight="light" 
                v-bind="props"
                :is=currentIcon
            >
                </component>
        </template>

        <div style="
        width:700px;
        height: 200px;
        background-color: #f6f8fc;
        ">
        <v-card
        bg-color="#f6f8fc"
        variant="outlined"
        >
        <div class="top-grid-container">
            <v-text-field
                bg-color="white"
                ref="form"
                style="
                padding-top: 30px;
                padding-left: 30px;
                padding-right: 30px;"
                density="compact"
                height="37px"
                v-model="search"
                clearable
                label="Verfügbare Icons suchen"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                color="#6397ff"
            > 
            </v-text-field>
            <div class="vertical-center">
                <v-icon 
                @click="close()"
                class="close"
                icon="mdi-close-circle"
                color="#656666"
                size="35px"
                ></v-icon>
            </div>

        </div>
        
            <div class="grid-container"
            
            >
                <div  v-for="icon  in searchResults"
                    :key="icon">
                    <component 
                        @click="changeIcon(icon)"
                        class="grid-item"
                        :size="40" 
                        :color=currentColor 
                        weight="light" 
                        :is=icon>
                </component>
                </div>
            </div>
            <div style="
            padding-bottom: 25px;
            background-color: #f6f8fc;
            "
            ></div>
        </v-card>
    </div> 

    </v-menu>

     
</template>

<script setup>

import * as icons from "@phosphor-icons/vue";
import { ref, computed, defineEmits, defineProps, onBeforeMount } from 'vue'

const props = defineProps({   
                            color: String,
                            icon: String,
                            edit:Boolean
                          }); 

const emits = defineEmits(['changeIcon'])

const alleIcons = ref([])
const menu = ref(false)
const currentIcon = ref('')
const currentColor = ref('')
const isEditAble = ref(false)

for (let index = 0; index < Object.keys(icons).length; ++index){
    alleIcons.value.push(Object.keys(icons)[index])

        }

const search = ref('')

onBeforeMount(() => initialize());

const initialize = () => {
    currentIcon.value = props.icon
    currentColor.value = props.color
    isEditAble.value = props.edit
}

const searchResults = computed(()=> {

  try{
      if(search.value === '' || search.value === null){
          return alleIcons.value
      }
      return alleIcons.value.filter(item  => {
          if(item.toLowerCase().includes(search.value.toLowerCase())) {
              return item
          }
      })
  }catch(e){
    console.log(e)
    return 0
  }

})

function close(){
    menu.value = false
}

function changeIcon(icon){
    currentIcon.value = icon
    emits('changeIcon', icon)
    menu.value = false
}


</script>

<style scoped>

.top-grid-container {
      display: grid;
      grid-template-columns: 90% 10%;
      grid-gap: 2;
  } 

  .vertical-center {
    display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 45px;

}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  background-color: #f6f8fc;    
  padding: 50px;  
  overflow: auto;
  height: 35vh;
  gap: 15px;
}

.grid-item{
    transition: transform .2s; /* Animation */
}

.close{
    cursor: pointer;
    padding-top: 10px;
}

.grid-item:hover{
    transform: scale(1.3);
    cursor: pointer;
}

</style>