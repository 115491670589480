var events = []

export default {

    state(){
        return{
            items: [],
        }
    },
    actions:{
      getMyEvents({commit}){
        commit("setEvents",events);   
    }
  },

  mutations:{
    setEvents(state, events){
          state.items = events;
      }
  }
}

