<!--//https://primevue.org/-->

<template>

    <AnnucalVCard
        :prop_mode = props.prop_mode
        saveText = "savecalendar"
        :calendarColor = props.calendarColor
        :showCalendarName = true
        :showvCardActions = true
        :VcardWitdh = '440'
        :calendarName=props.header
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeCalendarMenu"
        style="
            margin-left:12px;
            margin-right:8px;
            margin-top:-19px;
        "
  >
    <template v-slot:middle>
        <div style="
   
        ">
    
        <!-------------------------------------------------------------------------------------------->
        <!------------------------------------ Kalendername ------------------------------------------>
        <!-------------------------------------------------------------------------------------------->
    
           <div >
                <div style="
                        padding-top:24px
                    ">
                     <AnnucalTextfieldVue
                        labeltext="calendarNameInput"
                        v-model="calendarNameInput" 
                        label = "Kalender Name"
                    />
                    </div>
               
                <!--<v-text-field v-if="editable==true"
                    bg-color="#F6F2EF"
                    density="compact"
                    v-model=kalendername
                    clearable
                    variant="flat"                  
                /> -->
                <div 
                    v-if="mode=='edit' &&  editable==false"
                    class="vertical-center"
                    v-bind:style="{ color: calendarColor }"
                    style="font-size: large;
                    font-weight: 300;
                    "                    
                >
                    {{ kalendername }}
                </div> 
           </div>
            
        <!-------------------------------------------------------------------------------------------->
        <!-------------------------------- Kalenderbeschreibung -------------------------------------->
        <!-------------------------------------------------------------------------------------------->
        <div style="
                    color: #191816;
                    font-size: 11px;
                    font-weight: 300;
                    letter-spacing: -1px;
                    margin-top: 8px;
                "
                >Kurzbeschreibung</div>
          <v-textarea  v-if="editable==true"
                density="compact"
                v-model=kalenderbeschreibung
                bg-color="#F6F2EF"
                clearable
                variant="flat"  
            > 
          </v-textarea>


          <div v-if="mode=='edit' &&  editable==false"
            style="
                padding-left: 10px;
                padding-bottom: 25px;
                font-size: medium;
                font-weight: 300;
            "
                
                                        
            >{{ kalenderbeschreibung }}</div> 
    
          <!------------------------------- KALENDER BESCHREIBUNG END ---------------------------------->

        </div>
    </template>

</AnnucalVCard>

  
</template>
    
<script setup>
    
    import { ref, defineEmits, defineProps, onMounted, computed } from 'vue'
    import {db} from "../db";
    import {useStore} from "vuex";
    import calenderCore from "../composition/calenderCore";
    import { doc, updateDoc, deleteDoc } from "firebase/firestore";
    //import { useToast } from "vue-toastification"
    import AnnucalVCard from "./AnnucalVcard.vue"
    import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
    
    //const toast = useToast(); 

    const calendarNameInput = ref('')


    const iconPickerKey = ref(0);

    const forceRerender = () => {
        iconPickerKey.value += 1;
    };
    
    const props = defineProps({   
                    prop_mode: String,
                    header: String,
                    prop_kalendername: String,
                    prop_kalenderbeschreibung: String,
                    prop_radios: Boolean,
                    calendarColor: String,
                    prop_icon: String,
                    prop_calendarId: String,
                    prop_docId: String,
                    prop_creatorUID:String,
                    prop_externalCalendarLink:String,
                    prop_color:String,
                    prop_type:String
                }); 

  
    
    const emits = defineEmits(['closeCalendarMenu'])

    //const availableCalendarItems= computed({
    //    get() {
    //     return state.availablecalendars.items;
    //    },
    //  })

 

    const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

    const events = computed({
        get() {
            return state.events.items;
        },
        })
    
    const store = useStore();
    
const { state } = store;
const calenderCore1 = calenderCore();

    const menueHeader = ref('')
    const mode = ref('')
    const editable = ref(false);
    const deleting = ref(false);
    const loading = ref(false);
    const docId = ref('')
    const calendarId = ref('')
    const kalendername = ref('')
    const externalCalendarLink = ref('')
    const creatorUID = ref('')
    const kalenderbeschreibung = ref('')
    const type = ref('')
    const radios= ref(true)
    const calendarColor = ref(null)
    //const calendarImagePath = ref('https://firebasestorage.googleapis.com/v0/b/annucaldev.appspot.com/o/default%2Fstandard_calendar_icon.png?alt=media&token=c55dce53-7204-4b48-b171-44fb0d687a9d')
    var calendarImage = null
    const icon = ref('')

    const initialize = () => {

        calendarNameInput.value = props.prop_kalendername
        //calenderCore1.setEditMode(props.date, props.calendarId)

        kalendername.value = props.prop_kalendername
        kalenderbeschreibung.value = props.prop_kalenderbeschreibung
        type.value = props.prop_type
        radios.value = props.prop_radios
        calendarColor.value = props.prop_calendarColor
        calendarId.value= props.prop_calendarId
        docId.value = props.prop_docId
        creatorUID.value = props.prop_creatorUID
        icon.value = props.prop_icon
        mode.value = props.prop_mode
        menueHeader.value = props.header
        if (props.prop_externalCalendarLink == undefined){
            externalCalendarLink.value = ''
        }else{
            externalCalendarLink.value = props.prop_externalCalendarLink
        }
        

        if(store.state.user.userID == creatorUID.value ){
            editable.value = true
            forceRerender()
        }
        else{
            menueHeader.value = 'Kalender deabonnieren?'
            editable.value = false
            forceRerender()
        }

        store.state.settings.savingModus = false

    }   

    onMounted(() => initialize());

  /* const labelStyles = computed(() => ({
      
      '--dot--color': getCalendarColor()
    ,
    }));*/
    
    //*****function changeCalendarImage(newImage){
    //*****    calendarImagePath.value = newImage.name
    //*****     calendarImage = newImage
    //***** }
    
    function closeCalendarMenu(){
        emits('closeCalendarMenu')
    }

    function getCalendarColor(){

        if(props.prop_mode == 'new' ){
            switch (mycalendars.value.length) {
            case 1:
                return '#4BA0C4'
            case 2:
                return '#5C70D7'
            case 3:
                return '#9D41D5'
            case 4:
                return '#D272DA'
            default:
                return '#6AD069'
            }
        }
        else{
            return props.prop_calendarColor
        }
    }
    
    async function savenew(){

        if(calendarNameInput.value == ""){
            store.state.settings.normlaErrorMessage = "Kalendername erforderlich"
            store.state.settings.savingModus = false
            return
        }

        store.state.settings.savingModus = true

        let newCalendar = {
            color: getCalendarColor(), //calendarColor.value, 
            //color: '#34ccff', - Farbe für Feiertage
            icon: 'ph-CalendarCheck', //icon.value,
            name: calendarNameInput.value,
            externalCalendarLink: '',//externalCalendarLink.value,
            description: kalenderbeschreibung.value,
            visible: true,
            private: radios.value,
            creatorUID: store.state.user.userID,
            creatorName: "Annucal",
            calendarImage:  calendarImage,
            type: type.value,
            specialday:'',
            country: '',
            subdivision: '',
            events:[]
        }
    
        const newCalendarData = await store.dispatch("createCalendar", newCalendar);
        newCalendar.icon = newCalendarData.imageURL
        newCalendar.calendarId = newCalendarData.calendarId
        newCalendar.docId = newCalendarData.MyCalendarsdocId
        store.state.mycalendars.items.push(newCalendar)
        
        calenderCore1.updateCalender();

        emits('closeCalendarMenu')
    }

    async function saveEdit(){

        
        if(calendarNameInput.value == ""){
            store.state.settings.normlaErrorMessage = "Kalendername erforderlich"
            store.state.settings.savingModus = false
            return
        }

        store.state.settings.savingModus = true

        let newCalendar = {
            color: props.calendarColor, 
            icon: icon.value,
            name: calendarNameInput.value,
            description: kalenderbeschreibung.value,
            private: radios.value,
            docId: docId.value,
            userId: store.state.user.userID,
            calendarId: calendarId.value,
            type:"Annucal",
            externalCalendarLink: externalCalendarLink.value
        }
    
        await store.dispatch("updateCalendar", newCalendar);
        

        //--------------- CHANGE LOCAL MYCALENDERS START ---------------------------
        
        for (let i = 0; i < mycalendars.value.length; i++) {
          if(mycalendars.value[i].calendarId == calendarId.value){
            mycalendars.value[i].color = props.calendarColor
            mycalendars.value[i].icon = icon.value
            mycalendars.value[i].name = calendarNameInput.value
            mycalendars.value[i].description = kalenderbeschreibung.value
            mycalendars.value[i].private = radios.value
            mycalendars.value[i].externalCalendarLink = externalCalendarLink.value
            mycalendars.value[i].type = type.value
          }
        }

        //--------------- CHANGE LOCAL MYCALENDERS END ---------------------------

        //--------------- CHANGE LOCAL EVENTS START ------------------------------


        for (let i = 0; i < events.value.length; i++) {
          if(events.value[i].calendarId == calendarId.value){
            events.value[i].color = calendarColor.value
            events.value[i].icon = icon.value
            events.value[i].name = kalendername.value
            events.value[i].description = kalenderbeschreibung.value
            events.value[i].private = radios.value
            events.value[i].externalCalendarLink = externalCalendarLink.value
            await updateDoc(doc(db, "calendars", events.value[i].calendarId, "events", events.value[i].eventidDB), newCalendar);
          }
        }

        calenderCore1.updateCalender();

        //--------------- CHANGE LOCAL EVENTS END ------------------------------

    

        
        emits('closeCalendarMenu')
    }

    async function deleteElement(){

        let Calendar = {    
            calendarId: calendarId.value,
            docId: docId.value,
            creatorUID: store.state.user.userID,
        }
        await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
        await store.dispatch("deleteCalendarFromCalendars", Calendar);
        unsubcribeToCalendarAfterDeleting(Calendar) 
        SetNewSelectedCalender()
        emits('closeCalendarMenu')

    }

    function SetNewSelectedCalender(){
        for (let i = 0; i < mycalendars.value.length; i++){
            if(mycalendars.value[i].externalCalendarLink == null || mycalendars.value[i].externalCalendarLink == "" || mycalendars.value[i].externalCalendarLink == undefined){
                store.state.settings.currentSelectedCalendar = mycalendars.value[i];
                store.state.settings.currentCalendarId = mycalendars.value[i].calendarId
                store.state.settings.currentCalendarColor = mycalendars.value[i].color 
                store.state.settings.currentCalendarIcon = mycalendars.value[i].icon
            }
        }
    }

    async function unsubscribeMain(){

        let Calendar = {
            calendarId: calendarId.value,
            docId: docId.value,
            creatorUID: store.state.user.userID,
        }
        await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
        unsubcribeToCalendar(Calendar)
        emits('closeCalendarMenu')
    }

    const availableCalendarItems= computed({
        get() {
         return state.availablecalendars.items;
        },
      })

    function removeEvents(calendarId){
        for(let index2 = 0; index2 < events.value.length; ++index2){
            if(events.value[index2].calendarId == calendarId){
            events.value.splice(index2,1);
            }
        }
    }

    async function unsubcribeToCalendar(result){

        loading.value = true;

        for (let index = 0; index < mycalendars.value.length; ++index){
            if(mycalendars.value[index].calendarId == result.calendarId){
            await deleteDoc(doc(db, "users", state.user.userID, "myCalendars", mycalendars.value[index].docId));
            mycalendars.value.splice(index, 1);
            }
        }

        for(let index2 = 0; index2 < events.value.length; ++index2){
            removeEvents(result.calendarId)
        }

        // Refresh Events Indexlist
        for (let index = 0; index < events.value.length; index++){
                events.value[index].index = index;
        }

        for (let index3 = 0; index3 < availableCalendarItems.value.length; ++index3){
             if(availableCalendarItems.value[index3].calendarId == result.calendarId){
                 availableCalendarItems.value[index3].subscribed = false;
             }
         }

        calenderCore1.updateCalender();
        
        result.subscribed = false;

        loading.value = false;

    }

async function unsubcribeToCalendarAfterDeleting(result){

    deleting.value = true;

    for (let index = 0; index < mycalendars.value.length; ++index){
        if(mycalendars.value[index].docId == result.docId){
            mycalendars.value.splice(index, 1);
        }
    }

    for(let index2 = 0; index2 < events.value.length; ++index2){
        if(events.value[index2].calendarId == result.docId){
            events.value.splice(index2,1);
        }
    }

    
    calenderCore1.updateCalender();

    deleting.value = false;

}

   
    
    </script>
    
    <style scoped>

.button-grid{
    display: grid;
  padding-left: 0px;
  grid-template-columns: 248px auto ;
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
       .grid-container {
        
        height: 70px;
        display: grid;
        grid-template-columns: 70px auto;
        gap: 2;
        align-items: center;
      } 
    
      .center{
       vertical-align: middle;
      }
      .image{
        padding-top:30px;
        transition: transform .2s; /* Animation */
      }
    
      .image:hover{
        transform: scale(1.2);
        cursor: pointer;
    }

    .dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  
  background-color: var(--dot--color);
  margin-top: 5px;
  margin-left: 15px;
}

  .headline{
    
    padding-top: 4px;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
}

.headline-grid-container {
    padding-left:8px;
    display: grid;
    grid-template-columns: 50px 223px 110px 20px;
    height: 40px;
        }
.headline-grid-item {
    display: flex;
    align-items: center;
    justify-content: start; /* links ausgerichtet */
     
}

.button-text-save{
    font-size: 15px;
    font-weight: 400;
    color: white;
    letter-spacing: -1px;
    padding-right: 5px;
    padding-left: 5px;
}

.button-text-cancel{
    font-size: 15px;
    font-weight: 400;
    color: #191816;
    letter-spacing: -1px;
    padding-right: 5px;
    padding-left: 5px;
}

  
  

    
    </style>
    