
import countries_de from "../../assets/countries_with_subdivisions_de.json"
import countries_en from "../../assets/countries_with_subdivisions_de.json"

export default {

    state(){
        return{
            items: countries_de,
            selectedLanguage: 'de',
        }
    },


    
    mutations:{

        setYear(state,language){
            state.selectedLanguage = language
        },

        setLanguage(state){
            state.items= []
            if( state.selectedLanguage == 'de'){
                state.items = countries_de
            }
            else if( state.selectedLanguage == 'en'){
                state.items = countries_en
            }
        }
    }   
}




      