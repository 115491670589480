


var availableCalendars = []


export default {

    state(){
        return{
            items: [],
            
        }
    },

    actions:{
        getAvailableCalendars({commit}){
          commit("setAvailableCalendars",availableCalendars);   
      }
    },

    mutations:{
      setAvailableCalendars(state, availableCalendars){
            state.items = availableCalendars;
        }
    }
}

