<template>
    <!-------------------------------------------------------------------------------------------->
    <!--------------------------------------- Card ----------------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <v-card
        class="vcard-style"
        min-width="300px"
        location="top"    
    >
    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------- Top Line --------------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <v-btn 
                  color="#656565"  
                  class="text-none button-text-cancel top-right" 
                  density="default"
                  variant="text"  
                  @click="closeMenu"
                >  
                  {{ $t('closewithx')}}
    </v-btn> 

    <div class="top-grid">
        <div>
            <div class="date-day"
            >{{props.item.name}}. {{ $t( props.item.month)}}  {{ getYear(props.item.id)}}</div>
            <div class="date-weekday"
            >{{props.item.weekdayFullName}}</div>
        </div>
        <div 
                style="
                    text-align: right; 
                    color: #656565;
                    margin-right: -4px;
                    margin-top:-8px;
                ">
             
 

            </div>
        <!--<div 
            style="
                text-align: right; 
                color: #656565;
            ">
            <div class="close-button" @click="closeMenu">
                <div >
                    {{ $t('closewithx')}}
                </div>
            </div>
        </div>-->
      

    </div>

       
  <div 
    style="
        padding-top:4px;
    "
    >

  <!-------------------------------------------------------------------------------------------->
  <!------------------------------------- Events start all ------------------------------------->
  <!-------------------------------------------------------------------------------------------->
  <div v-if="props.mode === 'edit' " >
    <div  v-for ="event in dayitemtype1" :key="event.eventidDB">

    <div class="grid-container" v-if="event.calendarId === props.calendarId">

        <span class="dot"
              v-bind:style="{backgroundColor: event.eventColor}">
        </span>

        <div class="grid-item">
          <div 
            style="
              font-size: 14px;
              font-weight: 300;
              padding-right: 10px;
              ">
            {{event.eventName}}
          </div>
        </div>


        <div>
          <div class="grid-item-rechts">
          <div class="delete-button">
            <PhTrash class="delete-icon" />
          </div>
        </div>
        </div>
        <div>
          <div class="grid-item-rechts">
          <div class="edit-button">
            <PhPencilSimple class="plus-icon" />
          </div>
        </div>
        </div>


        <div>
         
        {{event.additionalInfos}}{{event.location}}
        </div >
    </div>
  </div>
</div>

  <!-------------------------------------------------------------------------------------------->
  <!------------------------------------- Events start all ------------------------------------->
  <!-------------------------------------------------------------------------------------------->
  
  <div v-if="props.calendarId === 'all'" >
  <!-------------------------------------------------------------------------------------------->
  <!------------------------------------- normal Events ------------------------------------->
  <!-------------------------------------------------------------------------------------------->
    <div  v-for ="event in dayitemtype1" :key="event.eventidDB">
      
      <div class="grid-container" >
        <!------------------------------------- Calendar Color ------------------------------------->
        <span class="dot"
              v-bind:style="{backgroundColor: event.eventColor}">
        </span>

        <!--------------------------------------- Event name ------------------------------------->
        <div class="grid-item">
            <div 
              style="
                font-size: 14px;
                font-weight: 300;
                padding-right: 10px;
                ">
              {{event.eventName}}
            </div>
        </div>

        <!--------------------------------------- edit event ----------------------------------->
      
    
        <div >
                <div class="grid-item-rechts">
                  <div class="edit-button">
                    <PhGear class="edit-icon" />
                  </div>
                </div>
              </div>
      

        <!--------------------------------------- delete event ----------------------------------->
        <div>
          <div class="grid-item-rechts" v-on:click="deleteEvent(event.calendarId,event.eventidDB)">
            <div class="delete-button">
              <PhTrash class="delete-icon" v-if="!event.deletemode" />
              <v-progress-circular v-if="event.deletemode" 
                indeterminate :size="12" 
                :width="1"
                color="#ffffff"
              />
            </div>
          </div>
        </div>

        <!---------------------------------- Event additional infos ------------------------------>
        <div class="additional-infos">
              {{event.additionalInfos}}{{event.location}}&nbsp;&nbsp;
        </div >
       
        </div>

      </div>
      
        <!-------------------------------------------------------------------------------------------->
        <!------------------------------------- public holidays - School holidays -------------------->
        <!-------------------------------------------------------------------------------------------->
        <div class="grid-container" v-for ="event in dayitemtypeElse" :key="event.eventidDB">

            <!------------------------------------- Calendar Color ------------------------------------->
              <span class="dot"
                    v-bind:style="{backgroundColor: event.eventColor}">
              </span>

              <!--------------------------------------- Event name ------------------------------------->
              <div class="grid-item">
                  <div 
                    style="
                      font-size: 14px;
                      font-weight: 300;
                      padding-right: 10px;
                      ">
                    {{event.eventName}}
                  </div>
              </div>

              <!--------------------------------------- edit event ----------------------------------->


              <div >
                <div class="grid-item-rechts">
                  <div class="edit-button-inactive">
                    <PhGear class="icon-inacitve" />
                  </div>
                </div>
              </div>


              <!--------------------------------------- delete event ----------------------------------->
              <div>
                <div class="grid-item-rechts" v-on:click="deleteEvent(event.calendarId,event.eventidDB)">
                  <div class="delete-button-inactive">
                    <PhTrash class="delete-icon-inactive" v-if="!event.deletemode" />
                    <v-progress-circular v-if="event.deletemode" 
                      indeterminate :size="12" 
                      :width="1"
                      color="#ffffff"
                    />
                  </div>
                </div>
              </div>

              <!---------------------------------- Event additional infos ------------------------------>
              <div class="additional-infos">
                    {{event.additionalInfos}}{{event.location}}&nbsp;&nbsp;
              </div >
            
              </div>










  </div>
  

</div>


 <!------------------------ EVENTS END ------------------------>


  </v-card>
</template>

<script setup>

import { defineProps, ref, computed, defineEmits, onBeforeMount } from 'vue';
import calenderCore from "../composition/calenderCore";

const calenderCore1 = calenderCore(); 
import {useStore} from "vuex";
const store = useStore();
const { state } = store;

const events = computed({
        get() {
            return state.events.items;
        },
})



const props = defineProps({     
    item: Object,
    mode: {
      type: String,
      required: true
    },
    calendarId: {
      type: String,
      required: true
    },
}); 

const dayitem = ref(null)
const dayitemtype1 = ref(null)
const dayitemtypeElse = ref(null)

onBeforeMount(() => {
  dayitem.value = props.item
  dayitemtype1.value = props.item.events.filter(myCal => myCal.type === '1')
  dayitemtypeElse.value = props.item.events.filter(myCal => myCal.type != '1')
  
});


const deletingEvent = ref(false)



async function deleteEvent(calendarId, eventIdDb){

  for(let index = 0; index < dayitem.value.events.length; ++index){
        if(dayitem.value.events[index].calendarId == calendarId && events.value[index].eventidDB == eventIdDb){
          dayitem.value.events[index].deletemode = true;
    }
  }

  deletingEvent.value = true

  calenderCore1.unSetEditMode();

  let Event = {
      calendarId: calendarId,
      eventidDB: eventIdDb,
  }
  await store.dispatch("deleteEventFromCalendar", Event);

  // delete event from eventlist
  for(let index = 0; index < events.value.length; ++index){
        if(events.value[index].calendarId == calendarId && events.value[index].eventidDB == eventIdDb){
            events.value.splice(index,1);
    }
  }

  // Refresh Events Indexlist
  for(let index = 0; index < store.state.events.items.length; ++index){
      store.state.events.items[index].index = index;
  }

  // Write each event index in the events of each mycalendars
  for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
    store.state.mycalendars.items[calenderIndex].events = []
      for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
          if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
            store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
          }
      } 
  } 

  calenderCore1.updateCalender();
  closeMenu();
} 

const emit = defineEmits(['close-menu'])

function getYear(date){
  return date.slice(0,4)
}

function closeMenu(){
  emit('close-menu')
}

</script>

<style scoped>

.top-right {
    position: absolute; /* Positionierung innerhalb des Containers */
    top: 6px;
    right: 4px;
}

.top-grid{
  display: grid;
  grid-template-columns: 200px auto 1fr;
}

.dot {
    height: 15px;
    width: 15px;
    margin-top: 10px;
    border-radius: 50%;
    margin-bottom: 9px;
}

.grid-container {
    display: grid;
    grid-template-columns: 22px auto 25px 23px  ;
    padding-left: 4px;
    background-color: #ffffff;
}

.grid-item {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #ffffff;
    text-align: left;
    vertical-align: middle;
}

.date-day{
    font-size: large;
    font-weight: 700;
    padding-left: 4px;
    height: 45px;
    letter-spacing: -1px;
}

.date-weekday{
    padding-left: 4px;
    margin-top: -25px;
    font-size: larger;
    font-weight: 400;
    letter-spacing: -1px;
}

.close-icon {
    color: #191816;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }


  .grid-item-links {
    font-size: 14px;
    text-align: left;
    font-weight: 300;
    letter-spacing: -1px;
  }
  
  .grid-item-rechts {
    padding-top: 5px;
  }
  
  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px; /* Gleiche Größe wie Checkbox */
    height: 22px; /* Gleiche Größe wie Checkbox */

    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
  }

  .edit-button-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px; /* Gleiche Größe wie Checkbox */
    height: 22px; /* Gleiche Größe wie Checkbox */

    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
  }
  
  .plus-icon {
    color: #191816;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .icon-inacitve {
    color: #C6C3BB;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .icon-inacitve {
    color: #C6C3BB;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .edit-button:hover {
    background-color: #191816;
    cursor: pointer;
  }
  
  .edit-button:hover .plus-icon {
    color: white;
  }


  .delete-button-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px; /* Gleiche Größe wie Checkbox */
    height: 22px; /* Gleiche Größe wie Checkbox */
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
  }


  .delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px; /* Gleiche Größe wie Checkbox */
    height: 22px; /* Gleiche Größe wie Checkbox */

    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 2px;
  }

  .edit-icon {
    color: #191816;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .edit-button:hover .edit-icon {
    color: white;
  }
  
  .delete-icon {
    color: #191816;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .delete-icon-inactive {
    color: #C6C3BB;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: #e30303d4;
    cursor: pointer;
  }
  
  .delete-button:hover .delete-icon {
    color: white;
  }

.close-button:hover {
  background-color: #f0f0f0;
}

  .close-button {
    font-size: 13px;
    font-weight: 400;
    color: #191816;
    letter-spacing: -1px;
    display: inline-block;
    padding: 4px 6px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: -5px; 
}

.vcard-style{
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  margin-right: 16px;
  margin-top:12px;
}

.button-text-cancel{
    font-size: 15px;
    font-weight: 400;
    color: #615E59;
    letter-spacing: -1px;
    text-align: right;
    margin-right: 2px;
}

.additional-infos{
  grid-column: span 2; 
  font-size: 14px;
  font-weight: 300;
  margin-top:-9px;
  padding-left: 21px;
  color:#85827C
}

</style>