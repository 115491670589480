
<template>

    <div class="grid-padding">
 
            <new-calendar-modal/>
        <div class="main-grid">
            <div style="
              font-weight: 300;
              font-size: 15px; 
              padding-top: 73px;
              color:rgb(60, 60, 60);
              ">
                <div class="month-grid">Jan</div>
                <div class="month-grid">Feb</div>
                <div class="month-grid">Mar</div>
                <div class="month-grid">Apr</div>
                <div class="month-grid">Mai</div>
                <div class="month-grid">Jun</div>
                <div class="month-grid">Jul</div>
                <div class="month-grid">Aug</div>
                <div class="month-grid">Sep</div>
                <div class="month-grid">Okt</div>
                <div class="month-grid">Nov</div>
                <div class="month-grid">Dez</div>
            </div>
            <div class="grid-container">
                <div v-for="index1 in 31" v-bind:key="index1">
                  <div v-if="index1 < 32">
            <!--------------------------------- YEAR HEADLINE ----------------------------------->
            <div 
          style="height: 36px;"
          v-bind:style="{ backgroundColor: index1<32 ? '#c7c5c5' : '#b2b2b2' }">
            <div v-if="index1==2 && this.$store.state.settings.screenWidth < 800" style="font-size: x-large; font-weight: 800; color:rgb(97, 97, 97)">
              2024   
            </div> 
            <div v-if="index1==20 && this.$store.state.settings.screenWidth < 800" style="font-size: x-large; font-weight: 800; color:rgb(97, 97, 97)">
              2024
            </div> 
            <div v-if="index1==15" style="font-size: medium; font-weight: 800; color:rgb(97, 97, 97)">
              2024   
            </div> 
          </div> 
      
        </div>
                 <div style="
                 padding-top: 5px;
                 padding-bottom: 5px;
                 font-size: 15px; 
                 font-weight: 400;
                 text-align: center;
                 color:rgb(100, 100, 100);
                 ">{{index1}}</div> 
                    <div v-for="index2 in 12" v-bind:key="index2">
                        <PopoverTranspose placement="right-start" :item=getObject(items[index2-1][index1-1])  />
                    </div>
                </div> 
            </div> 
        </div>

        <!--<h3 style="padding-top: 15px;">2025</h3>
            <new-calendar-modal/>
        <div class="main-grid">
            <div style="font-size: 14px;">
                <div class="month-grid">Januar</div>
                <div class="month-grid">Februar</div>
                <div class="month-grid">März</div>
                <div class="month-grid">April</div>
                <div class="month-grid">Mai</div>
                <div class="month-grid">Juni</div>
                <div class="month-grid">Juli</div>
                <div class="month-grid">August</div>
                <div class="month-grid">September</div>
                <div class="month-grid">Oktober</div>
                <div class="month-grid">November</div>
                <div class="month-grid">Dezember</div>
            </div>
            <div class="grid-container">
                <div v-for="index1 in 31" v-bind:key="index1">
                    <div v-for="index2 in 12" v-bind:key="index2">
                        <PopoverTranspose placement="right-start" :item=getObject(items[index2+11][index1-1])  />
                    </div>
                </div> 
            </div> 
        </div>-->
      
  
  
  </div>  
    
    </template>
    
    <script>
    
    import PopoverTranspose from "../components/PopoverCalenderdayTranspose";
    import calenderCore from "../composition/calenderCore";
    import { useStore } from "vuex";
    import { computed } from "vue";
    //import IntroModal from '../components/IntroModal.vue';
    import NewCalendarModal from '../components/NewCalendarModal.vue';
    
    export default{
    
      components:{
          PopoverTranspose,
        //IntroModal,
       NewCalendarModal,
        
      },
    
      data(){
        return {
          insertEventisOpen: false,
          deleteEventisOpen: false,
          selectedDate: "",
          selectedEvent: "",
          deleteBackgroundColor: "#00ff00",
          calenderCore1 : calenderCore(),
          
          
        }
      },
    
     
      computed:{
    
          items(){
                return this.$store.state.calender.items;
            },
          events(){
                return this.$store.state.events.items;
            }
          },
    
          async created(){
            this.$store.dispatch("getCalender");
            this.$store.dispatch("getTimezones");
            this.$store.dispatch("getPublicHolidays"); 
            this.$store.dispatch("getShoolholidays"); 
            this.$store.dispatch("getContent"); 
              
            const calenderCore1 = calenderCore();
            calenderCore1.updateCalender();
    
            const store = useStore();
            const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
    
            if(isAuthenticated.value == true){
              this.$store.state.settings.Authenticated = true
              if(this.$store.state.settings.fetchedDataFromDb == false){
                //this.$store.dispatch("getMyCalendars");
                await this.$store.dispatch("getmyCalendarsFromDB");
                //this.$store.dispatch("getMyEvents"); 
                await this.$store.dispatch("getmyEventsFromDB",this.$store.state.user.userID);
                //this.$store.dispatch("getAvailableCalendars");
                await this.$store.dispatch("refreshIndexList");
                await this.$store.dispatch("getavailableCalendarsFromDB", this.$store.state.user.userID);
                this.$store.state.mycalendars.items = this.$store.state.storedmycalendars.items
                this.$store.state.availablecalendars.items = this.$store.state.storedavailablecalendars.items
                this.$store.state.settings.fetchedDataFromDb = true;
                this.$store.state.events.items = this.$store.state.storedevents.items
                await this.$store.dispatch("getmydaysWithEventsFromDB", {
                      events: this.$store.state.events.items, 
                      myCalendars: this.$store.state.mycalendars.items, 
                      calender: this.$store.state.calender.items});
                calenderCore1.updateCalender();
                this.$store.state.settings.startPageOpen = false; 
              }
              else{
                this.$store.state.events.items = this.$store.state.storedevents.items
                calenderCore1.updateCalender();
              }
            }
            else{
              this.$store.dispatch("getMyEvents"); 
              calenderCore1.updateCalender();
              this.$store.state.settings.introPageToShow = 1;
              this.$store.state.settings.startPageOpen = true;
            }
    
          },
    
           async mounted() {
            window.addEventListener('resize', this.getDimensions);
            document.addEventListener( "keydown", this.onKeydown );
            document.addEventListener( "keyup", this.onKeyup );
          },
    
          unmounted() {
            window.removeEventListener('resize', this.getDimensions);
          },
    
          methods: {
    
              
  
              getObject(x){
                  //{"index":"1","id":"2024-01-01","name":"01","backColor":"#ffffff","weekday":"Mo","backColorWeekDay":"#ffffff","weekDayColor":"#ffffff","eventText":"","date":"2024-01-01","weekdayFullName":"Montag","dateFormated_De":"Montag 01. Januar 2024","dateFormated_Json":"January 01, 2024 12:00:00 UTC","showTooltip":false,"dayHasEvent":false,"PublicHolidayName":"","dayIsShoolHoliday":false,"kw":"01.svg","cursor":"default","draggable":false,"month":"Januar","weekdayFontWeight":300,"events":[{"eventName":"","eventColor":"#ff0000"}]}
                  try{
                      var firstElement = x;
                  var accountName = firstElement;
  
                  return accountName
                  }catch{
                      return "x"
                  }
                
              },
  
            getMonthNumber(mydate){
              const today = new Date(mydate);
              return today.getMonth() + 1;
                  //dateFormated_Json
            },
    
            getDimensions() {
              this.$store.dispatch("getCalender"); 
            },
    
            onKeyup (e) {
              if(e.key == 'd'){
              this.$store.state.settings.ctrlPressed = false;
              this.$store.state.settings.selectedCursor = 'default'
              
            }
            },
    
            onKeydown  (e) {
            if(e.key == 'd'){
              this.$store.state.settings.ctrlPressed = true;
              this.$store.state.settings.selectedCursor = 'grab'
              
            }
          }      
        }
      }
    
    </script>
    
    <style scoped>
    
    .vl {
      border-left: 1px solid rgb(96, 96, 96);
      height: 36px;
    }
      .mycontainer {
      display: Grid;
      flex-direction:column;
      flex-wrap: wrap;
      max-height: 120vh;
    
    }
    .item {
      margin: 0.5rem;
      height: 7px;
    }


    
  
.main-grid{
  
  display: grid;
  grid-template-columns: 40px auto;
    
}

.month-grid{
    height: 55px;
}
    
  
    @media (min-width: 100px){
    .grid-container {
      display: grid;
      grid-auto-flow: column;
      padding-left: 0px;
      grid-template-columns: repeat(31, 5%); 
      overflow-x: scroll;
      overscroll-behavior-inline:none;
      overscroll-behavior-block: contain
    }
  }
  
  
  
  @media (min-width: 800px){
    .grid-container {
      display: grid;
      grid-auto-flow: column;
      padding-left: 0px;
      grid-template-columns: repeat(31, 12%); 
      overflow-x: scroll;
      overscroll-behavior-inline: contain;
      align-content: stretch;
      
    }
  }
  
  @media (min-width: 1200px){
    .grid-container {
      display: grid;
      grid-auto-flow: column;
      padding-left: 0px;
      grid-template-columns: repeat(31, 3.25%); 
      overflow-x: scroll;
      overscroll-behavior-inline: contain;
      align-content: stretch;
      
    }
  }
    
     
    .day-grid-container{
      display: grid;
      
    }
    
    day-grid-container:hover{
    
    }
    
    .day-item {
      border-radius: 0px;
      cursor: pointer;
      border: 0px solid rgba(210, 202, 202, 0.802);
      background-color: rgba(255, 255, 255, 0.758);
      padding-top: 5px;
      color:#434343;
      font-size: medium;
      font-weight:300;
      text-align: center;
      user-select: none;
    }
    
    .grid-padding{
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-top: 0px;
      user-select: none;
      
     
      
    }
    
    .top-grid-item-year {
      padding-top: 14px;
      font-size: 28px;
      font-weight:900;
      text-align: center;
      vertical-align:top;
      user-select: none;
    }
    
    .the-sliding-grid{
        height: 100vh;
        display: grid;
        place-items: center;
        background: #222;
      }
    
      .slider-container{
        height: 500px;
        width: 800px; 
        display: flex;
        overflow: auto;
        scroll-snap-type: x mandatory;
      }
    
      .slider-container-slide{
        scroll-snap-align: start;
      }
    
      .slider-container-img{
        width: 800px;
        height: 100%;
        object-fit: cover;
      }
    
    </style>
    
    
    
    
    
  
  