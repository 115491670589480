<template>

<v-dialog v-model="dialog2" width="auto">
      <template v-slot:default="{ isActive }">
        <v-card title="Wiederkehrenden Termin bearbieten">
          <v-divider class="mt-3"></v-divider>

          <v-card-text class="px-4" style="height: 150px">
            <v-radio-group
              v-model="selectedOption"
           
              column
            >
              <v-radio label="Diesen Termin" value="1"></v-radio>

              <v-radio label="Diesen Termin und alle folgenden" value="2"></v-radio>

              <v-radio label="Alle Termine" value="3"></v-radio>

            </v-radio-group>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-flex justify-end">
            <v-btn text="Abbrechen" @click="isActive.value = false" color="#656565" class="text-none" ></v-btn>
            <v-btn
              color="#316bff"
              text="Ok"
              variant="flat"
              @click='saveEdit($event)'
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>


  <v-card v-if="showCard == 'Edit'"
      min-width="300" 
      max-width="400"
      rounded="lg"
      variant="flat"
      color="#f6f8fc" 
    
      style="
        padding-top: 20px;
        border:1px solid rgb(56, 56, 56);
        box-shadow: none;
      "
    >
  <!---------------------------------- TOP EDIT == TRUE START --------------------------------->
  
  <div 
    v-if="editMode == false"
    class="grid-container">

      <div class="grid-item">
        <component 
                  style="margin-left: 5px;"
                  :size="35" 
                  :color=props.color 
                  weight="light" 
                  :is=props.icon
                >
        </component>
    </div>

   <div class="grid-item"
      style="
          font-size: 20px;
          font-weight: 400;
      "
        v-bind:style="{ color: color,}"
        >{{ props.calendarName  }}
    </div>

    <div v-if="showEditIcon == false"></div>

    <v-btn v-if="showEditIcon == true" class="grid-item"
      color="#f6f8fc"  
      variant="flat" 
      max-width="20"
      @click='startEditing($event)'>       
                <v-icon 
                    color= "#525252"
                    icon="mdi-pencil"
                    size="30px"
                />
                </v-btn> 

  </div>

    <div v-if="editMode == false"
          style="
            font-size: 16px;
            font-weight: 500;
            padding-left: 30px;
          "
          v-bind:style="{ color: color }"
          >
          
        {{ props.prop_title }}
    </div>

    <div v-if="editMode == false"
          style="
            font-size: 14px;
            font-weight: 300;
            padding-left: 30px;
            white-space: pre;
          ">
        {{ props.prop_additionalInfos }}{{ props.prop_location }}
    </div>

    <div v-if="editMode == false"
      style="
        padding-bottom: 15px;
        "
    ></div>

  
   
  <!---------------------------------- TOP EDIT == TRUE END --------------------------------->

  <!---------------------------------- TOP EDIT == FALSE START --------------------------------->

    <div v-if="editMode == true"
        class="grid-container">
        <div class="grid-item">
          <component 
                    style="
                    margin-left: 5px;"
                    :size="45" 
                    :color=store.state.settings.currentCalendarColor
                    weight="light" 
                    :is=props.icon
                  >
          </component>
        </div>

        <div class="grid-item">
    
   
          <v-combobox
          
            bg-color="white"
            density="compact"
            :model-value="store.state.settings.currentSelectedCalendar"
            :items="myCalendars"
            item-title="name"
            item-value="calendarId"
            variant="outlined"
            color="#6397ff"  
            item-color="green"
            :label="$t('currentCalendar')"
            @update:model-value="updateSelectedCalendar"     
          >

       
        </v-combobox>

   


    </div>

        <div></div>

     
 
  </div> 

  <!---------------------------------- TOP EDIT == FALSE END --------------------------------->



<!--------------------------   TITEL EINTRAGEN START ----------------------->

          <v-text-field v-if="editMode == true"
          bg-color="white"
                density="compact"
                v-model=title
                clearable
                :label="$t('newevent')"
                variant="outlined"
                color="#6397ff"   
                style="
                    margin-left: 25px;
                    margin-right: 15px;
                    margin-top: 5px;
                    
                "                       
          /> 
              
<!--------------------------   TITEL EINTRAGEN END ----------------------->

<!--------------------------   ADDITIONAL INFOS START ----------------------->

<v-textarea  v-if="editMode == true"
                style="
                   margin-left: 25px;
                    margin-right: 15px;
                    margin-top: 5px;
                "
                density="compact"
                height="37px"
                v-model=additionalInfos
                clearable
                :label="$t('additionalinformation')"
                variant="outlined"
                color="#6397ff"
                bg-color="white"
            > 
          </v-textarea>

<!--------------------------   ADDITIONAL INFOS END ----------------------->

<!--------------------------   START DATE START ------------------------------>
    
    <div v-if="editMode == false"
      style="padding-left: 0px;">
        <time-picker
            :editMode=editMode
            :key="timePickerKey" 
            :fromDay = valueFromDay
            :toDay = valueToDay
            :fromHour = valueFromHour
            :fromMinute = valueFromMinute
            :toHour = valueToHour
            :toMinute = valueToMinute
            :isFullDay = isFullDay
        />
      </div>  

      <div v-if="editMode == true"
      style="padding-left: 0px;">
        <time-picker
            :editMode = editMode
            :key="timePickerKey" 
            :fromDay = valueFromDay
            :toDay = valueToDay
            :fromHour = valueFromHour
            :fromMinute = valueFromMinute
            :toHour = valueToHour
            :toMinute = valueToMinute
            :isFullDay = isFullDay
        />
      </div> 


      <div  v-if="editMode == true"
      style="
          padding-left: 35px;
          padding-top: 45px;
          padding-right: 125px;
      ">
          <v-row>
            <v-combobox
              bg-color="white"
              density="compact"
              
              v-model=comboValue
              :label="$t('repetitions')"
              variant="outlined"
              color="#6397ff"  
              :items="[$t('once'),$t('daily'),$t('weekly'),$t('monthly'),$t('ownselection')]"          

            ></v-combobox>  

            <div v-if="comboValue == 'Eigene Auswahl' | comboValue == 'Own Selection' "
            style="padding-left: 15px;
                    padding-top:5px;
              ">
                 <rrules :rRule="valueRRule" v-if="editMode == true"></rrules>
             
              </div> 

          </v-row>
      </div>
  


<div v-if="editMode == false && valueRRuleToText != ''"
    style="
    color:rgb(67, 67, 67);
    font-size: 13px;
    padding-left: 30px;
    padding-top: 25px;
    padding-right: 15px;
    ">
    {{valueRRuleToText}}
</div>
<div v-if="editMode == true"
    style="
    color:rgb(67, 67, 67);
    font-size: 13px;
    padding-left: 30px;
    padding-right: 15px;
    ">
    {{valueRRuleToText}}
</div>
   
      
    

      <v-card-actions v-if="editMode == false"
      style="
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 5px;
        ">
        <v-spacer></v-spacer>
        <v-btn        style="margin-right: 5px; font-size: 14px; font-weight: 500;" 
          color="#656565"  class="text-none" variant="text" size="small"  @click='closeForm()'>  
          {{ $t('cancel')}}
        </v-btn>  

        </v-card-actions>

<!--------------------------   START DATE END ------------------------------>

      <v-card-actions v-if="editMode == true"
      style="
        padding-top: 40px;
        padding-bottom: 15px;
        padding-right: 15px;
        ">
        <v-spacer></v-spacer>

        <div v-if="props.prop_mode=='edit'"
                style="
              position:fixed;
                left:10px;
                bottom:20px;
                "
            >
              <v-btn @click='deleteEvent($event)'>       
                <v-icon 
                color="#656666"
                    icon="mdi-delete"
                    size="35px"
                />
                </v-btn> 
            </div>

        <v-btn        style="margin-right: 5px; font-size: 14px; font-weight: 500;" 
          color="#656565"  class="text-none" variant="text" size="small"  @click='closeForm()'>  
          {{ $t('cancel')}}
        </v-btn>
        
    

        <v-btn   color="#316bff" class="text-none" v-if="props.prop_mode=='new'"  variant="flat"  @click='saveNew($event)' style="font-size: 14px; font-weight: 500;">   
          {{ $t('save')}}
        </v-btn>


        <v-btn  color="#316bff" class="text-none" v-if="props.prop_mode=='edit'"  variant="flat"  @click='store.state.dayevent.rRules == undefined || store.state.dayevent.rRules.toLowerCase() == "once"?    saveEdit($event) : dialog2 = true' style="font-size: 14px; font-weight: 500;">   
          {{ $t('save')}}
        </v-btn>
       
      
      </v-card-actions>
    </v-card>


    <v-card v-if="showCard == 'Info'"
    min-width="300" 
      max-width="400"
      rounded="lg"
      variant="flat"
      color="#f6f8fc" 
    
      style="
        padding-top: 10px;
        border:1px solid rgb(56, 56, 56);
        box-shadow: none;
      "
     
  >
  <div class="grid-container">
      <div class="grid-item">
        <component 
                  style="margin-left: 5px;"
                  :size="40" 
                  :color=props.color 
                  weight="light" 
                  :is=props.icon
                >
        </component>
    </div>



    <div class="grid-item"
    style="font-size:x-large ;"
      v-bind:style="{ color: color,}"
      >{{ props.calendarName }}</div>
    </div>


    <div style="
            font-size: 16px;
            font-weight: 500;
            padding-left: 30px;
          "
          v-bind:style="{ color: color }">{{ title }}</div>

<div  v-if="editMode == false"
          style="
            font-size: 14px;
            font-weight: 300;
            padding-left: 30px;
            white-space: pre;
          "
    >{{ props.prop_additionalInfos }}</div>
 
<v-card-actions 
      style="
        padding-top: 40px;
        padding-bottom: 10px;
        padding-right: 5px;
        ">
        <v-spacer></v-spacer>
        <v-btn        style="margin-right: 5px; font-size: 14px; font-weight: 500;" 
          color="#656565"  class="text-none" variant="text" size="small"  @click='closeForm()'>  
          Abbrechen
        </v-btn>  

        </v-card-actions>



      




</v-card>

</template>

<style scoped>

.d-flex {
    display: flex;
  }

  .justify-end {
    justify-content: flex-end;
  }

.grid-container {
display: grid;
grid-template-columns: 55px auto  60px ;
padding-left: 10px;
background-color: #f6f8fc;

}
.grid-item {
  background-color: #f6f8fc;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 30px;
  text-align: left;
  vertical-align: middle;
}

.circleicon {
  cursor: alias;

}

.circleicon{
    transition: transform .2s; /* Animation */
}

.circleicon:hover{
    transform: scale(1.2);
    cursor: pointer;
}

</style>

<script setup>

  import { useToast } from "vue-toastification"
  import calenderCore from "../composition/calenderCore";
  import { useI18n } from "vue-i18n";

  import {useStore} from "vuex";
  import { ref, defineProps, defineEmits, onBeforeMount, computed, onMounted, watch } from 'vue';
  import timePicker from "../components/timePicker.vue"
  import rrules from "../components/RRules.vue"
  import format from 'date-fns/format' 
  import { rrulestr } from 'rrule'

  const toast = useToast(); 
  const emits = defineEmits(['closeNewEventMenue'])
const props = defineProps({     
                                fromDay: Date,
                                toDay: Date,
                                fromHour: Number,
                                fromMinute: Number,
                                toHour: Number,
                                toMinute: Number,
                                prop_mode: String,
                                prop_title: String,
                                prop_additionalInfos: String,
                                prop_location: String,
                                header: String,
                                icon: String, 
                                calendarName: String,
                                color: String,
                                startDate: Date,
                                calendarId: String,
                                eventidDB: String ,
                                showCard: String,
                                prop_editMode: Boolean,
                                showEditIcon: Boolean,
                                isFullDay: Boolean,
                                rRule: String
                              }); 


const i18nLocale = useI18n();

const timePickerKey = ref(0);

const selectedOption= ref('1');

const dialog2 = ref(false)

const comboValue = ref(i18nLocale.locale.value == "de"? "Einmalig" : "Once")

const editMode = ref(false);

const forceRerender = () => {
    timePickerKey.value += 1;
};

const valueFromDay = ref(Date.now)
const valueToDay = ref(Date.now)
const valueFromHour =  ref('08')
const valueFromMinute =  ref('00')
const valueToHour =  ref('09')
const valueToMinute =  ref('00')
const valueRRule = ref('ONCE')
const valueRRuleToText = ref('')


  const calenderCore1 = calenderCore(); 
  const store = useStore();
  const { state } = store;

const myCalendars = computed({
        get() {
            var myEditableCalendars = []
            for (let index = 0; index < state.mycalendars.items.length; ++index){
              if(state.mycalendars.items[index].creatorUID == store.state.user.userID){
                if(state.mycalendars.items[index].externalCalendarLink == null || state.mycalendars.items[index].externalCalendarLink == "" || state.mycalendars.items[index].externalCalendarLink == undefined){
                  myEditableCalendars.push(state.mycalendars.items[index])
                }
              }
            }
            return myEditableCalendars;
        },
        })

  const selectedCalendar = ref(store.state.settings.currentSelectedCalendar );

  const updateSelectedCalendar = (value) => {
  selectedCalendar.value = value;
  store.state.settings.currentSelectedCalendar = value;
  store.state.settings.currentCalendarId = value.calendarId
  store.state.settings.currentCalendarColor = value.color 
  store.state.settings.currentCalendarIcon = value.icon
};


  const events = computed({
        get() {
            return state.events.items;
        },
        })

        watch(comboValue, (newValue, oldValue) => {
          console.log(newValue, oldValue);

          switch(newValue) {
          case 'Einmalig':
          case 'Once':
            valueRRuleToText.value = ""
            valueRRule.value = "Once"
            state.dayevent.rRules = "Once"
            break;
          case 'Täglich':
          case 'Daily':
            valueRRuleToText.value = i18nLocale.locale.value == "de"? "Jeden Tag für 10 Wiederholungen" : "Every day for 10 repetitions"
            valueRRule.value = "FREQ=DAILY;INTERVAL=1;COUNT=10"
            state.dayevent.rRules = "FREQ=DAILY;INTERVAL=1;COUNT=10"
            break;
          case 'Wöchentlich':
          case 'Weekly':
            valueRRuleToText.value = i18nLocale.locale.value == "de"? "Jede Woche für 10 Wiederholungen" : "Every week for 10 repetitions"
            valueRRule.value = "FREQ=WEEKLY;INTERVAL=1;COUNT=10"
            state.dayevent.rRules = "FREQ=WEEKLY;INTERVAL=1;COUNT=10"
            break;
          case 'Monatlich':
          case 'Monthly':
            valueRRuleToText.value = i18nLocale.locale.value == "de"? "Jeden Monat für 10 Wiederholungen" : "Every month for 10 repetitions" ; 
            valueRRule.value = "FREQ=MONTHLY;INTERVAL=1;COUNT=10"
            state.dayevent.rRules = "FREQ=MONTHLY;INTERVAL=1;COUNT=10"
            break;
  
        }
        });

  const title = ref('')
  const additionalInfos = ref('')
  const location = ref('')

onBeforeMount(() => initialize());

onMounted(() => {
  store.state.dayevent.rRules = props.rRule
  if(store.state.settings.currentSelectedCalendar == null){
    store.state.settings.currentSelectedCalendar = myCalendars.value.length > 0 ? myCalendars.value[0] : null
  }

  //store.state.settings.currentSelectedCalendar == null? myCalendars.value.length > 0 ? myCalendars.value[0] : null: store.state.settings.currentSelectedCalendar

  selectedCalendar.value = myCalendars.value[0];

  if(state.mycalendars.items.length > 0 && state.settings.currentCalendarId == -1){

      for (let index = 0; index < state.mycalendars.items.length; ++index){
          if(state.mycalendars.items[index].creatorUID == store.state.user.userID){
            setActiveCalendar(
                state.mycalendars.items[index].name, 
                state.mycalendars.items[index].color, 
                state.mycalendars.items[index].icon, 
                state.mycalendars.items[index].calendarId)
                break
          }
      }     
    }
})

const initialize = () => {
  
      try{
        title.value = props.prop_title
        if(props.prop_additionalInfos == null){
          additionalInfos.value = ""
        }else{
          additionalInfos.value = props.prop_additionalInfos
        }

        if(props.location == null){
          location.value = ""
        }else{
          location.value = props.location
        }
        
        valueFromDay.value = props.fromDay
        valueToDay.value = props.toDay

        if(props.fromHour){valueFromHour.value = props.fromHour}
        if(props.fromMinute){valueFromMinute.value = props.fromMinute}
        if(props.toHour){valueToHour.value = props.toHour}
        if(props.toMinute){valueToMinute.value = props.toMinute}
        if(props.rRule){
          valueRRule.value = props.rRule
          var testrruleset = ''
          if(props.rRule == 'ONCE'){
              valueRRuleToText.value = 'ONCE'
              comboValue.value = 'Einmalig'
          }else if(props.rRule == 'FREQ=DAILY;INTERVAL=1;COUNT=10')
          {
            testrruleset = rrulestr(props.rRule)
            valueRRuleToText.value = testrruleset.toText()
            comboValue.value = 'Täglich'
          }
          else if(props.rRule == 'FREQ=WEEKLY;INTERVAL=1;COUNT=10')
          {
            testrruleset = rrulestr(props.rRule)
            valueRRuleToText.value = testrruleset.toText()
            comboValue.value = 'Wöchentlich'
          }
          else if(props.rRule == 'FREQ=MONTHLY;INTERVAL=1;COUNT=10')
          {
            testrruleset = rrulestr(props.rRule)
            valueRRuleToText.value = testrruleset.toText()
            comboValue.value = 'Monatlich'
          }
          else{
            testrruleset = rrulestr(props.rRule)
            valueRRuleToText.value = translateRRule(testrruleset.toText()) 
            comboValue.value = 'Eigene Auswahl'
          }
        }
        
        editMode.value = props.prop_editMode

        forceRerender() 
      }catch(e){
        console.log(e)
      }

        
 
}  

function translateRRule(rruleText){
  //every 2 weeks on Tuesday, Wednesday for 10 times



  rruleText = rruleText.replace("Monday", "Montag")
  rruleText = rruleText.replace("Tuesday", "Dienstag")
  rruleText = rruleText.replace("Wednesday", "Mittwoch")
  rruleText = rruleText.replace("Thursday", "Donnerstag")
  rruleText = rruleText.replace("Friday", "Freitag")
  rruleText = rruleText.replace("Saturday", "Samstag")
  rruleText = rruleText.replace("Sunday", "Sonntag")
  
  if(rruleText.includes("weeks")){rruleText = rruleText.replace("every", "Alle");
  }else if(rruleText.includes("week")) {rruleText = rruleText.replace("every", "Jede");}

  if(rruleText.includes("weeks")){rruleText = rruleText.replace("weeks", "Wochen");
  }else if(rruleText.includes("week")){rruleText = rruleText.replace("week", "Woche");}

  if(rruleText.includes("days")){rruleText = rruleText.replace("every", "Alle");
  }else if(rruleText.includes("day")) {rruleText = rruleText.replace("every", "Jeden");}

  if(rruleText.includes("days")){rruleText = rruleText.replace("days", "Tage");
  }else if(rruleText.includes("day")) {rruleText = rruleText.replace("day", "Tag");}

  if(rruleText.includes("months")){rruleText = rruleText.replace("every", "Alle");
  }else if(rruleText.includes("month")) {rruleText = rruleText.replace("every", "Jeden");}

  if(rruleText.includes("months")){rruleText = rruleText.replace("months", "Monate");
  }else if(rruleText.includes("month")) {rruleText = rruleText.replace("month", "Monat");}

  rruleText = rruleText.replace(" on ", " am ")
  rruleText = rruleText.replace("times", "Wiederholungen")
  rruleText = rruleText.replace("for", "für")
  rruleText = rruleText.replace("until", "bis")

  rruleText = rruleText.replace("January", "Januar")
  rruleText = rruleText.replace("February", "Februar")
  rruleText = rruleText.replace("March", "März")
  //rruleText = rruleText.replace("April", "April")
  rruleText = rruleText.replace("Mai", "Mai")
  rruleText = rruleText.replace("June", "Juni")
  rruleText = rruleText.replace("July", "Juli")
  //rruleText = rruleText.replace("August", "August")
  //rruleText = rruleText.replace("September", "Januar")
  rruleText = rruleText.replace("October", "Oktober")
  //rruleText = rruleText.replace("November", "Januar")
  rruleText = rruleText.replace("December", "Dezember")


  



  
  return rruleText
}


function setActiveCalendar(calendar, color, icon, id){
          state.settings.currentCalendarId = id;
          state.settings.currentCalendarName = calendar;
          state.settings.currentCalendarColor = color;
          state.settings.currentCalendarIcon = icon;
      }



  function closeForm(){
    
    emits('closeNewEventMenue')
  }


  function startEditing(){
    if(editMode.value == false){
      editMode.value = true
    }else{
      editMode.value = false
    }
    forceRerender()
  }

  async function deleteEvent(){

      let Event = {
            calendarId: props.calendarId,
            eventidDB: props.eventidDB,
      }
      await store.dispatch("deleteEventFromCalendar", Event);

      // delete event from eventlist
      for(let index = 0; index < events.value.length; ++index){
              if(events.value[index].calendarId == props.calendarId && events.value[index].eventidDB == props.eventidDB){
                  events.value.splice(index,1);
          }
      }

      // Refresh Events Indexlist
      for(let index = 0; index < store.state.events.items.length; ++index){
            store.state.events.items[index].index = index;
        }

        // Write each event index in the events of each mycalendars
        for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
          store.state.mycalendars.items[calenderIndex].events = []
            for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
                if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
                  store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
                }
            } 
        } 
            
      calenderCore1.updateCalender();

      emits('closeNewEventMenue')

  } 

  function setRemovedDays(type){
    if(type == 1){
      store.state.dayevent.removedDays = []
    }else{
      store.state.dayevent.removedDays = []
    }
  }

  async function saveEdit(){
    
    switch (selectedOption.value) {
    case '1':
      console.log('Diesen Termin');
      setRemovedDays(1);
      break;
    case '2':
      console.log('Diesen Termin und alle folgenden');
      setRemovedDays(2);
      break;
    case '3':
      console.log('Alle Termine');
      store.state.dayevent.removedDays = [];
      // Do nothing
      break;
    default:
      console.log("Error - AddNewItemBox - saveEdit - switch(selectedOption.value)");
}

    if(this.title == ""){
      toast.error("Bitte einen Titel für das Ereignis eintragen")
    }

   
    //var isValid = await this.v$.$validate();

    //if (isValid){
          
      if(store.state.settings.Authenticated == true){
            let newEvent = {
              calendarId: props.calendarId,
              eventidDB: props.eventidDB,
              title: this.title,
              datefrom:  format(store.state.dayevent.dateFrom, 'yyyy-MM-dd') ,
              dateto:format(store.state.dayevent.dateTo, 'yyyy-MM-dd') ,
              datefromJson: store.state.dayevent.dateFrom, 
              datetoJson: store.state.dayevent.dateTo,
              fromHour: store.state.dayevent.valueFromHour,
              fromMinute: store.state.dayevent.valueFromMinute,
              toHour: store.state.dayevent.valueToHour,
              toMinute: store.state.dayevent.valueToMinute,
              isFullDay: store.state.dayevent.isFullDay,
              additionalInfos: this.additionalInfos,
              rRules: store.state.dayevent.rRules,
              location: "",
              removedDays: store.state.dayevent.removedDays
            }
            await store.dispatch("updateStoredEvent", newEvent);
        
        }

        if(this.selectedDay != null){
            this.selectedDay.cursor = "s-resize";
        } 

        //store.state.settings.eventName = ""
        calenderCore1.updateCalender();
        closeForm()

      //}
 

  }

  async function saveNew(){
    if(this.title == ""){
      toast.error("Bitte einen Titel für das Ereignis eintragen")
    }
    //var isValid = await this.v$.$validate();

    //if (isValid){
          if(store.state.settings.currentCalendarId == -1){
            toast.error("Bitte einen Kalender auswählen")
          }else if(this.title == null | this.title == ""){
            toast.error("Bitte einen Titel für das Ereignis eintragen")
          }else
          {
            if(store.state.settings.Authenticated == true){
            let newEvent = {
              calendarId: store.state.settings.currentCalendarId,
              color: store.state.settings.currentCalendarColor, 
              title: this.title,
              datefrom:  format(store.state.dayevent.dateFrom, 'yyyy-MM-dd') ,
              dateto:format(store.state.dayevent.dateTo, 'yyyy-MM-dd') ,
              datefromJson: store.state.dayevent.dateFrom, 
              datetoJson: store.state.dayevent.dateTo,
              icon: props.icon, 
              fromHour: store.state.dayevent.valueFromHour,
              fromMinute: store.state.dayevent.valueFromMinute,
              toHour: store.state.dayevent.valueToHour,
              toMinute: store.state.dayevent.valueToMinute,
              isFullDay: store.state.dayevent.isFullDay,
              creatorUID: store.state.user.userID,
              calendarName: props.calendarName,
              additionalInfos: this.additionalInfos,
              rRules: (store.state.dayevent.rRules == undefined)? "ONCE" :  store.state.dayevent.rRules ,
              location: "",
              removedDays: []
            }
            await store.dispatch("createEvent", newEvent); 
            
          }
          
        }

        // Refresh Events Indexlist
        for(let index = 0; index < store.state.events.items.length; ++index){
            store.state.events.items[index].index = index;
        }

        // Write each event index in the events of each mycalendars
        for (let calenderIndex = 0; calenderIndex < store.state.mycalendars.items.length; calenderIndex++) {
          store.state.mycalendars.items[calenderIndex].events = []
            for (let eventIndex = 0; eventIndex < store.state.events.items.length; eventIndex++){
                if(store.state.mycalendars.items[calenderIndex].calendarId == store.state.events.items[eventIndex].calendarId){
                  store.state.mycalendars.items[calenderIndex].events.push(eventIndex)
                }
            } 
        } 


        if(this.selectedDay != null){
            this.selectedDay.cursor = "s-resize";
        } 

        //store.state.settings.eventName = ""
        calenderCore1.updateCalender();
        closeForm()

      //}
      /*else{
        if(this.v$.eventName.required.$invalid){
          toast.error("Bitte einen Titel für das Ereignis eintragen")
        }
        else if(this.v$.eventName.maxLength.$invalid){
          toast.error("Der Eventname darf aus max. 25 Zeichen bestehen")
        }
      }*/
    }
   
</script>



