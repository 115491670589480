<template>
        
    <!------------------------------------------------------------------------------------------->
    <!--------------------------------- FULL DAY TOGGLE START------------------------------------>
    <!------------------------------------------------------------------------------------------->

    <div class="grid-container-tooglecheckbox" 
        v-if="editMode == true"
        style="
            padding-left: 27px;
            padding-bottom: 13px;"
    >
        <div class="div-v-center" >
            <input 
                ref="myroot"
                type="checkbox" 
                id="toggleFullDay" 
                name="toggleFullDay" 
                @change="checkBoxClicked('toggleFullDay')" 
                >
            <label 
                class="ios-checkbox" 
                for="toggleFullDay">
            </label>
        </div>
        <div style="
            color:rgb(67, 67, 67);
            font-size: 13px;"
            class="div-v-center">
            {{ $t('allday')}}
        </div>
    </div>

    <!---------------------------------------------------------------------------------------------->
    <!--------------------------------- FULL DAY TOGGLE END----------------------------------------->
    <!---------------------------------------------------------------------------------------------->
   



    <!---------------------------------------------------------------------------------------------->
    <!--------------------------------- EDIT MODE IS FALSE START------------------------------------>
    <!---------------------------------------------------------------------------------------------->

     <div v-if="editMode == false" class="grid-container">
        
        <!-- VON: -->
        <div class="grid-item-First"> {{ $t('from')}}:</div>

        <!-- DATE: -->
        <div class="grid-item-Date"> 
            <div  style="
                cursor: default;
                color:rgb(67, 67, 67)">{{ formattedDateFrom }}
            </div>
        </div>

        <div 
            v-if="state.dayevent.isFullDay == true"
            style="
                    color:rgb(67, 67, 67);
                    font-size: 13px;"
            >
            {{ $t('allday')}}
        </div>

        <!-- TIME: -->
        <div v-if="state.dayevent.isFullDay == false">
            <table >
                <td>
                    <div  style="
                            width: 22px;
                            color:rgb(67, 67, 67);
                            font-size: 13px;
                        text-align: center;
                        "  class="date-time-flat">
                        {{ valueFromHour }}
                    </div>
                </td>
                <td style="font-size: 13px;">:</td>
                <td>
                    <div  style="
                            width: 22px;
                            color:rgb(67, 67, 67);
                            font-size: 13px;
                        text-align: center;
                        "  class="date-time-flat">
                        {{ valueFromMinute }}
                    </div>
                </td>
            </table>
        </div>  
    </div>  

        <!-- BIS -->
        <div v-if="editMode == false" class="grid-container">
        <div class="grid-item-First"> {{ $t('until')}}: </div>

        <!-- DATE: -->
        <div 
            class="grid-item-Date"> 
            <div  style="
                cursor: default;
                color:rgb(67, 67, 67)">{{ formattedDateTo }}
            </div>
        </div>

        <!-- TIME: -->
        <div 
        v-if="state.dayevent.isFullDay == false">
            <table>
                <td>
                    <div style="
                            width: 22px;
                            color:rgb(67, 67, 67);
                            font-size: 13px;
                        text-align: center;
                        "   class="date-time-flat">
                        {{ valueToHour }} 
                    </div>
                    </td>
                    <td style="font-size: 13px;">:</td>
                    <td>   
                        <div  style="
                            width: 22px;
                            color:rgb(67, 67, 67);
                            font-size: 13px;
                        text-align: center;
                        "  class="date-time-flat">
                        {{ valueToMinute }}
                    </div>
                    </td>
            </table>
        </div>  
    </div>
    <!-------------------------------------------------------------------------------------------->
    <!--------------------------------- EDIT MODE IS FALSE END------------------------------------>
    <!-------------------------------------------------------------------------------------------->

    


    <!-------------------------------------------------------------------------------------------->
    <!--------------------------------- EDIT MODE IS TRUE START----------------------------------->
    <!-------------------------------------------------------------------------------------------->

    <div class="grid-container" v-if="editMode == true">
    
    <!----------------------------------------- VON START ---------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    

        <!------------- VON ------------->

        <div class="grid-item-First"> {{ $t('from')}}:</div>

        <!------------- DATE ------------->

        <div class="grid-item-Date">  
            <v-menu :close-on-content-click="false" v-model="newEventMenueFrom">
                <template v-slot:activator="{ props }">
                    <div class="change-class" style="
                    cursor: pointer;
                    color:rgb(67, 67, 67);
                    font-size: 13px;
                    " v-bind="props">{{ formattedDateFrom }}</div>
                </template>
                <!--https://vue-datepicker.netlify.app/ -->
                <VDatePicker  :max-date="this.$store.state.dayevent.dateTo" @dayclick="closeNewEventMenueFrom()" v-model="currentEventsStore.fromDay" style="color: black;"/>
            </v-menu>   
        </div>

        <!------------- GANZTÄGIG TEXT ------------->

        <div v-if="state.dayevent.isFullDay == true"
            style="
                color:rgb(67, 67, 67);
                font-size: 13px;">
            {{ $t('allday')}}
        </div>

        <!------------- TIME ------------->

        <div v-if="state.dayevent.isFullDay == false"
            style="display: grid;
            grid-template-columns: 22px 4px 22px;
            ">
            <div>
                <input class="change-class2"
                style="
                width: 22px;
                color:rgb(67, 67, 67);
                font-size: 13px;
                text-align: center;
                " v-model.number="valueFromHour" 
                @keypress="check($event)">
            </div>
            <div>:</div>
            <div>
                <input class="change-class2"
                style="
                width: 22px;
                color:rgb(67, 67, 67);
                font-size: 13px;
                text-align: center;
                " v-model.number="valueFromMinute" @keypress="check($event)">
            </div>
        </div>  
    </div>
 
        <!----------------------------------------  VON END ---------------------------------------->
        <!-------------------------------------------------------------------------------------------->

        <!---------------------------------------- BIS START ----------------------------------------> 
        <!-------------------------------------------------------------------------------------------->

    <div class="grid-container" v-if="editMode == true">
    
        <!------------- BIS ------------->

        <div class="grid-item-First"> {{ $t('until')}}:</div>

        <!------------- DATE ------------->

        <div class="grid-item-Date">  
            <v-menu :close-on-content-click="false" v-model="newEventMenueTo">
                <template v-slot:activator="{ props }">
                    <div class="change-class" style="
                    cursor: pointer;
                    color:rgb(67, 67, 67);
                    font-size: 13px;
                    " v-bind="props">{{ formattedDateTo }}</div>
                </template>
                <!--https://vue-datepicker.netlify.app/ -->
                <VDatePicker  :min-date="this.$store.state.dayevent.dateFrom" @dayclick="closeNewEventMenueTo()" v-model="this.$store.state.dayevent.dateTo" style="color: black;"/>
            </v-menu>   
        </div>

        <!------------- GANZTÄGIG TEXT ------------->

        <div v-if="state.dayevent.isFullDay == true"
            style="
                color:rgb(67, 67, 67);
                font-size: 13px;">
            {{ $t('allday')}}
        </div>

        <!------------- TIME ------------->

        <div  v-if="state.dayevent.isFullDay == false">
            <table >
                <td>
                    <input class="change-class2"
                    :key="timePickerHour" 
                    style="
                        width: 22px;
                        color:rgb(67, 67, 67);
                        font-size: 13px;
                        text-align: center;
                        " v-model.number="valueToHour" 
                    @keypress="check($event)">
                </td>
                <td>:</td>
                <td>   
                    <input class="change-class2"
                    :key="timePickerMinute" 
                    style="
                        width: 22px;
                        color:rgb(67, 67, 67);
                        font-size: 13px;
                        text-align: center;
                        " v-model.number="valueToMinute" @keypress="check($event)">
                </td>
            </table>
        </div>  
    </div>
    <div 
        v-if="editMode == true" 
        style="
        padding-left :30px;
        
    ">

    <!--<v-menu :close-on-content-click="false" v-model="changeTimeZone">
        <template v-slot:activator="{ props }">
            <div class="change-class" style="
            cursor: pointer;
            color:rgb(67, 67, 67);
            font-size: 13px;
            " v-bind="props">{{ currentTimezone }}</div>
        </template>
        <TimeZonePicker @click="closeChangeTimeZone()"></TimeZonePicker>
        
    </v-menu>-->  
  
    </div>

    <!------------------------------------------- BIS END -------------------------------------------->
    <!-------------------------------------------------------------------------------------------->
  
    <!------------------------------------------------------------------------------------------------>
    <!------------------------------------ EDIT MODE IS TRUE END-------------------------------------->
    <!------------------------------------------------------------------------------------------------>

</template>

<script setup>

    import format from 'date-fns/format'
    import {  ref, watch, computed, defineProps, onBeforeMount, onMounted } from 'vue'
    import { de } from 'date-fns/locale';
    import { useStore } from "vuex";
    import { usecurrentEventsStore} from "@/stores/currentEvent.js"
    //import { DateTime } from 'luxon';
    //import TimeZonePicker from './TimeZonePicker.vue';
  

  //const currentTimezone = "(GMT+" + ("00" + DateTime.local().offset/60).slice(-2) + ":00) " + DateTime.local().zoneName  ;
  
    const currentEventsStore = usecurrentEventsStore();
    console.log(currentEventsStore)

    const myroot = ref(null)

    const timePickerHour = ref(0);
    const timePickerMinute = ref(0);
    
    const forceRerender = () => {
        timePickerHour.value += 1;
        timePickerMinute.value += 1;
    };
    
    const store = useStore();
    const { state } = store;
    
    const valueFromDay = ref(new Date)
    const valueToDay = ref(new Date)
    const valueFromHour =  ref('08')
    const valueFromMinute =  ref('00')
    const valueToHour =  ref('08')
    const valueToMinute =  ref('00')
    const newEventMenueFrom = ref(false)
    const newEventMenueTo = ref(false)
    //const changeTimeZone = ref(false)


    const props = defineProps({ 
                                editMode: Boolean, 
                                fromDay: Date,
                                toDay: Date,
                                fromHour: Number,
                                fromMinute: Number,
                                toHour: Number,
                                toMinute: Number,
                                isFullDay: Boolean
                              });  

    onBeforeMount(() => initialize());

    const initialize = () => { 
        
            valueFromDay.value = props.fromDay
            valueToDay.value = props.toDay
            valueFromHour.value = props.fromHour
            valueFromMinute.value = props.fromMinute
            valueToHour.value = props.toHour
            valueToMinute.value = props.toMinute
            state.dayevent.dateFrom = new Date(props.fromDay)
            state.dayevent.dateTo = new Date(props.toDay)
            state.dayevent.isFullDay = props.isFullDay

        forceRerender()
        
    }

    onMounted(() => {
        if(myroot.value){
            if(state.dayevent.isFullDay == true){
                myroot.value.checked = false; // Reversed due to a false CSS configuration. 
                                              // Needs to be fixed  
            }else{
                myroot.value.checked = true;
            }
        }
        initialize()
    })
    

    const formattedDateFrom = computed({
        get(){
            if(props.editMode == false){
                return format(new Date(`${valueFromDay.value} ${'18:00:00.000'}`), 'EEEE dd. MMMM yyyy', { awareOfUnicodeTokens: true, locale: de}) 
            }else{
                return state.dayevent.dateFrom ? format(state.dayevent.dateFrom, 'EEEE dd. MMMM yyyy', { awareOfUnicodeTokens: true, locale: de}) : 'Datum wählen'
            }
        }
    }) 

    const formattedDateTo = computed({
        get(){
            if(props.editMode == false){
                return format(new Date(`${valueToDay.value} ${'18:00:00.000'}`), 'EEEE dd. MMMM yyyy', { awareOfUnicodeTokens: true, locale: de}) 
            }else{
                return state.dayevent.dateTo ? format(state.dayevent.dateTo, 'EEEE dd. MMMM yyyy', { awareOfUnicodeTokens: true, locale: de}) : 'Datum wählen'
            }
        }
    })



 /*  const fromDateDisp = computed({
        get() {
            return this.fromDateVal;
        },
      })*/

    watch(valueFromHour, (newVal, oldVal) => {
        if(!newVal) {
        valueFromHour.value = '00'
        state.dayevent.valueFromHour= '00'
        return
        } 
        valueFromHour.value = newVal < 0 || newVal > 23     ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
        state.dayevent.valueFromHour = newVal < 0 || newVal > 23     ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
    });


    watch(valueFromMinute, (newVal, oldVal) => {
        if(!newVal) {
        valueFromMinute.value = '00'
        state.dayevent.valueFromMinute= '00'
        return
        } 
        valueFromMinute.value = newVal < 0 || newVal > 59    ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
        state.dayevent.valueFromMinute = newVal < 0 || newVal > 59    ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
    });

    watch(valueToHour, (newVal, oldVal)=> {
        if(!newVal) {
        valueToHour.value = '00'
        state.dayevent.valueToHour = '00'
        return
        } 
        valueToHour.value = newVal < 0 || newVal > 23     ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
        state.dayevent.valueToHour = newVal < 0 || newVal > 23     ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
    });

    watch(valueToMinute, (newVal, oldVal) => {
        if(!newVal) {
        valueToMinute.value = '00'
        state.dayevent.valueToMinute = '00'
        return
        } 
        valueToMinute.value = newVal < 0 || newVal > 59    ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
        state.dayevent.valueToMinute = newVal < 0 || newVal > 59    ? oldVal.toString().padStart(2, '0') : newVal.toString().padStart(2, '0')
    });
  
    function closeNewEventMenueFrom(){
        if(state.dayevent.dateFrom > state.dayevent.dateTo){
            state.dayevent.dateTo = state.dayevent.dateFrom;
        }
        newEventMenueFrom.value = false
    } 

    function closeNewEventMenueTo(){
        newEventMenueTo.value = false
    }

    //function closeChangeTimeZone(){
    //    //changeTimeZone.value = false
   // }

    function check(e) {
      if (e.which < 48 || e.which > 57) e.preventDefault()
    }

    /*function checkboxUpdated(){
        if(state.dayevent.isFullDay == true){
            state.dayevent.isFullDay = false
        }else{
            state.dayevent.isFullDay = true
            state.dayevent.dateTo = state.dayevent.dateFrom;
        }    
    }*/

    function checkBoxClicked(checkBoxName){
      
      var checkBox = document.getElementById(checkBoxName);
      if (checkBox.checked == true){
        state.dayevent.isFullDay = false;
      } else {
        state.dayevent.isFullDay = true;
        state.dayevent.dateTo = state.dayevent.dateFrom;
      }
    }

</script>

<style scoped>

.date-time-flat{
    background-color: #f6f8fc;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
}

.change-class:hover{
    border-radius: 10px;
    background-color: rgb(246, 246, 246);
}

.change-class2:hover{
    border-radius: 2px;
    background-color: rgb(231, 231, 231);
}

.container-class :deep(.v-checkbox .v-selection-control) {
  min-height: unset;
}
.text{
    font-size: x-small;
    font-weight: 400;
    text-align: left;
}

.input{
    color:#0b3558;
    stroke-width: 0px;
    font-size: medium;
    font-weight: 400;
    border-radius: 3px;
    width: 45px;
}

.grid-container {
  display: grid;
  grid-template-columns: 40px 210px 125px 100px;
  padding-left: 28px;
}

.grid-container-tooglecheckbox {
    display: grid;
    grid-template-columns: 55px 260px;
    align-content: center;
    align-items: center;    
}

.div-v-center {
    display: flex;
    height: 100%;
    align-items: center;
}

.grid-item-First{
  background-color: #f6f8fc;
  font-size: 13px;
  text-align: left;
  padding-right: 5px;
  padding-left: 3px;
  color:rgb(67, 67, 67);
  cursor: default;
}
.grid-item-Date {
  background-color: #f6f8fc;
  font-size: 13px;
  text-align: left;
  font-weight: 400; 
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 19px;
  background-color: #0391f5;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

input[type="checkbox"] + label::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  top: 2px;
  right: 2px;
  transition: all 0.2s ease-out;
}

input[type="checkbox"] + label::after {
  content: attr(data-deny);
  color: white;
  position: absolute;
  top: 2px;
  left: 12px;
  font-size: 20px;
  font-weight: 300;
  transition: all 0.2s ease-out;
}

input[type="checkbox"]:checked + label {
  background-color: #BBBBBB;
  
}

input[type="checkbox"]:checked + label::before {
  right: 27px;
}

input[type="checkbox"]:checked + label::after {
  content: attr(data-permit);
  left: 10px;
}



</style>


