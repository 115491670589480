import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { faPerson } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { faPersonWalkingDashedLineArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion} from '@fortawesome/free-regular-svg-icons'
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'
import PrimeVue from 'primevue/config'
import PhosphorIcons from "@phosphor-icons/vue"

import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import i18n from './i18n'

// Vuetify
import '@mdi/font/css/materialdesignicons.css' 
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import colors from 'vuetify/lib/util/colors'

import './assets/styles/styles.css'; // Hier importieren wir die globale CSS-Datei

const pinia = createPinia()


const vuetify = createVuetify({
  ssr: true,
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          background: '#FFFFFF',
          surface: '#FFFFFF',
          primary: colors.red.darken1, 
          'primary-darken-1': '#3700B3',
          secondary: '#03DAC6',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      },
    },
  },
})


library.add(faStar)
library.add(faUserSecret)
library.add(faGear)
library.add(faPerson)
library.add(faUser)
library.add(faPersonWalkingDashedLineArrowRight)
library.add(faCalendarDays)
library.add(faCircleQuestion)
library.add(faCircleXmark)
library.add(faArrowLeft)

 
let app;



store.dispatch("user/onAuthChange", () => {

  
  
  if (!app) {
    app = createApp(App);
    
    app
    
    .use(store)
    .use(pinia)
    .use(router)
    .use(vuetify)
    .use(i18n)
    .use(VCalendar, {})
    .use(PrimeVue)
    .use(PhosphorIcons)
    .use(Toast)

    

    app.component("font-awesome-icon", FontAwesomeIcon);
    app.component('DatePicker', DatePicker);
  
    app.mount('#app')
  }
});