<template>
    <v-dialog
        v-model="this.$store.state.settings.AnnucalSignInPageVisible"
        width="400"
        app
    >

    <!-------------------------------------------------------------------------------------------->
    <!------------------------------------ Annucal VCard ----------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div style="
            margin-right: -32px;
            margin-top: -4px;
        ">
        <AnnucalVCard
            :showCalendarName = true
            :showvCardActions = true
            :VcardWitdh = '480'
            :calendarName="props.Headline"
            @close-menu="closeMenu"
            @savenew="login"
            @saveEdit="logout"
            @register="register"
        >
            <!-------------------------------------------------------------------------------------------->
            <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
            <!-------------------------------------------------------------------------------------------->

            <template v-slot:middle>

                <v-divider style="margin-top:8px"></v-divider>

                <div style="margin-top:-4px">
                    <img 
                        src="@/assets/images/annucal_logo.png" 
                        
                        alt="Annucal" 
                        class="logo"
                        width="120px"
                    >
                </div>

                <!-------------------------------------------------------------------------------------------->
                <!-----------------------------------  Log in ------------------------------------------------>
                <!-------------------------------------------------------------------------------------------->
                <div 
                    v-if="!isAuthenticated && this.$store.state.settings.showForgotPassword == false 
                    && this.$store.state.settings.showRegister==false
                    "
                >
                <!-----------------------------------  Email ------------------------------------------------>
                    <div style="padding-top: 32px">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInEmail" 
                            label = "Email"
                            labelType="email"
                        />
                    </div>

                    <!-----------------------------------  Password ------------------------------------------------>
                    <div style="
                        padding-top: 16px;
                        padding-bottom: 4px;
                    ">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInPassword" 
                            label = "Passwort"
                            labelType="password"
                        />
                    </div>

                   
                    <div style="
                      padding-bottom: 4px;
                      padding-right: 4px;
                      ">
                        <div class="text-grid">
                           <!-------------------------------  No Account / Register ----------------------------------------->
                            <div class="text-grid-left">{{ $t("No account yet") }}?</div>
                            <div class="text-grid-right" @click="openRegister" style=" justify-self: end;"> 
                                {{ $t("Register now") }}
                            </div> 
                            <!-------------------------------  Forgot Password ----------------------------------------->
                            <div class="text-grid-left">{{ $t("Forgot password") }}?</div>
                            <div class="text-grid-right" @click="openForgotPassword"> 
                              {{ $t("Reset now") }}
                            </div>     
                        </div>
                    </div>
                     
                 
                    
                </div>

                <!-------------------------------------------------------------------------------------------->
                <!---------------------------------------- Register ------------------------------------------>
                <!-------------------------------------------------------------------------------------------->
                <div v-if="!isAuthenticated && this.$store.state.settings.showRegister == true">
            
                  <!-----------------------------------  Email ------------------------------------------------>
                  <div style="padding-top: 32px">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInEmail" 
                            label = "Email"
                            labelType="email"
                        />
                    </div>

                    <!-----------------------------------  Password ------------------------------------------------>
                    <div style="
                        padding-top: 16px;
                        padding-bottom: 4px;
                    ">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInPassword" 
                            label = "Passwort"
                            labelType="password"
                        />
                    </div>

                        <!-----------------------------------  Password confirmation ------------------------------------------------>
                        <div style="
                        padding-top: 16px;
                        padding-bottom: 4px;
                    ">
                        <AnnucalTextfieldVue
                            labeltext="textinput"
                            v-model="this.$store.state.settings.logInPasswordConfirmation" 
                            label = "Passwort wiederholen"
                            labelType="password"
                            
                        />
                    </div>

                    <div style="
                      padding-bottom: 4px;
                      padding-right: 4px;
                      ">
                        <div class="text-grid">
                           <!-------------------------------  No Account / Register ----------------------------------------->
                            <div class="text-grid-left">{{ $t("Already registered?") }}</div>
                            <div class="text-grid-right" @click="openLogin" style=" justify-self: end;"> 
                              {{ $t("Log in now") }}
                            </div> 
                           
                        </div>
                    </div>

                </div>
                <!-------------------------------------------------------------------------------------------->
                <!-----------------------------------  Log out ----------------------------------------------->
                <!-------------------------------------------------------------------------------------------->
                <div v-if="isAuthenticated && this.$store.state.settings.showForgotPassword == false" 
                    style="
                        padding-top: 22px;
                        padding-bottom: 20px;
                    "
                >
                <span class="info-text"> {{ $t("logouttext1") }}</span>
                <span class="user-email-text"> {{ user.email }}</span> 

                <div class="info-text"> {{ $t("logouttext2") }}</div> 
                </div>


            </template>

        </AnnucalVCard>
    </div>

    </v-dialog>
</template>
  
<script setup>
import { ref, computed, onMounted, defineProps } from 'vue';
//import calenderCore from '../composition/calenderCore';
//import useAuth from '../composition/useAuth';
import { useStore } from 'vuex';
//import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
//import { useToast } from 'vue-toastification';
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
import router from '@/router'; 

const props = defineProps({
    Headline:{
        type: String,
        required: true
    }
});




function closeMenu(){
    state.settings.AnnucalSignInPageVisible = false
}



//const toast = useToast();
const store = useStore();
const { state } = store;


//const isProcessing = computed(() => state.user.auth.isProcessing);
const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
const user = computed(() => state.user.data);

const form = ref({
  email: '',
  password: '',
  username: '',
  passwordConfirmation: '',
});


//const message = ref(null);
//const error = ref(null);
//const emailSending = ref(false);
//const forgotPasswordemail = ref('');





onMounted(() => initialize());

const initialize = () => {
    store.state.settings.logInEmail=""
    store.state.settings.logInPassword=""
    store.state.settings.logInPasswordConfirmation=""
    store.state.settings.emailErrorMessage = ""
    store.state.settings.savingModus = false
    store.state.settings.AnnucalVcardHeadline = props.Headline
    store.state.settings.logInEmail = ""
    store.state.settings.logInPassword = ""
    
}   

const register = async () => {

  console.log("p1: " + store.state.settings.logInPassword)
  console.log("p2: " + store.state.settings.logInPasswordConfirmation)

  store.state.settings.emailErrorMessage = ""
  store.state.settings.passwordErrorMessage = ""
  form.value.email = store.state.settings.logInEmail
  form.value.password = store.state.settings.logInPassword
  form.value.passwordConfirmation = store.state.settings.logInPasswordConfirmation
  form.value.username="-"

  if (!/.+@.+\..+/.test(store.state.settings.logInEmail)) {
        store.state.settings.emailErrorMessage = "Keine gültige Email-Adresse"
        store.state.settings.savingModus = false
        return
  }
  else if (form.value.password !== form.value.passwordConfirmation) {
    store.state.settings.passwordErrorMessage = 'Die Passwörter stimmen nicht überein'
  } else {
    store.state.settings.emailErrorMessage = ""
      store.state.settings.passwordErrorMessage = ""
    store.state.settings.savingModus = true
      await store.dispatch('user/register', form.value);
      store.state.settings.Authenticated = true;
  }
  if (isAuthenticated.value) {
        router.push({ path: '/' }).then(() => {
        router.go();
        });
    }
};

const login = async () => {

    store.state.settings.emailErrorMessage = ""
    store.state.settings.passwordErrorMessage = ""

    if(store.state.settings.logInEmail == ""){
        store.state.settings.emailErrorMessage = "Email-Adresse erforderlich"
        store.state.settings.savingModus = false
        return
    }
    else if (!/.+@.+\..+/.test(store.state.settings.logInEmail)) {
        store.state.settings.emailErrorMessage = "Keine gültige Email-Adresse"
        store.state.settings.savingModus = false
        return
    }
    else if(store.state.settings.logInPassword == ""){
        store.state.settings.passwordErrorMessage = "Passwort erforderlich"
        store.state.settings.savingModus = false
        return
    }

    store.state.settings.emailErrorMessage = ""
    store.state.settings.savingModus = true

    form.value.email = store.state.settings.logInEmail
    form.value.password = store.state.settings.logInPassword

    await store.dispatch('user/login', form.value);
    if (isAuthenticated.value) {
        router.push({ path: '/' }).then(() => {
        router.go();
        });
    }
    

};

const logout = () => {
 
  store.dispatch('user/logout');
  store.state.settings.introPageToShow = 1;
  store.state.settings.startPageOpen = true;
  router.push({ path: '/' }).then(() => {
    router.go();
  });
};

/*
const resetVariables = () => {
  showForgotPassword.value = false;
};
*/

/*

const openForgotPassword = () => {
  showForgotPassword.value = true;
  showRegister.value = false;
};
*/


const openRegister = () => {
  store.state.settings.logInEmail=""
  store.state.settings.logInPassword=""
  store.state.settings.logInPasswordConfirmation=""
  store.state.settings.AnnucalVcardHeadline = "Registrieren"
  store.state.settings.AnnucalSaveText = "Registrieren"
  store.state.settings.Log_Status = "register"
  store.state.settings.showRegister = true
  store.state.settings.showForgotPassword = false
  
};



const openLogin = () => {
  store.state.settings.logInEmail=""
  store.state.settings.logInPassword=""
  store.state.settings.logInPasswordConfirmation=""
  store.state.settings.AnnucalVcardHeadline = "Anmelden"
  store.state.settings.AnnucalSaveText = "Anmelden"
  store.state.settings.Log_Status = "login"
  store.state.settings.showRegister = false
  store.state.settings.showForgotPassword = false
};

/*
const sendEmail = () => {
  if (!forgotPasswordemail.value) {
    error.value = 'Please type in a valid email address.';
    return;
  }
  error.value = null;
  message.value = null;
  emailSending.value = true;

  sendPasswordResetEmail(getAuth(), forgotPasswordemail.value)
    .then(() => {
      emailSending.value = false;
      toast.success('Eine Email zum zurück setzen Deines Passworts wurde versendet');
    })
    .catch((error) => {
      emailSending.value = false;
      if (error.message.includes('invalid-email')) {
        toast.error('Die Emailadresse scheint nicht korrekt zu sein');
      } else {
        toast.error(error.message);
      }
    });
};

*/



</script>

  
<style scoped>

.text-grid{
    padding-bottom:16px;
    float: right;
    margin-left: auto;
    display: inline-grid;
    font-size: 12px;
    grid-template-columns: 1fr 1fr;
    letter-spacing: -0.5px;
}

.text-grid-left{
    font-weight: 300;
    text-align: right;
    
}

.text-grid-right{
    font-weight: 400;
    box-sizing: border-box;
    overflow: hidden; 
    transition: transform 0.2s ease-in-out; 
    width: 100%; 
    text-align: right; 
    
}

.text-grid-right:hover{
    transform: scale(1.02); 
    color: black;
    font-weight: 500;
    cursor: pointer;
}

.button-text-cancel{
  font-size: 15px;
  font-weight: 400;
  color: #615E59;
  letter-spacing: -1px;
  text-align: right;
  margin-right: 2px;
  padding-left:-44px;
}

.button-text-cancel:hover{
  background-color: #F6F2EF;
}

.close-button {
  font-size: 13px;
  font-weight: 500;
  color: #615E59;
  letter-spacing: -1px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover{
    background-color: #F6F2EF;

}

.link-text{

transition: transform 0.2s ease-in-out;
}

.link-text:hover{

color: #191816;
cursor: pointer;
}
/* Setzt den Overlay-Hintergrund auf ein leichtes Grün */
.v-overlay__scrim {
background-color: #C6C3BB !important; /* Leicht grüner Hintergrund */
}

.info-text{
  font-size: 16px;
  font-weight: 300;
  color: #615E59;
  letter-spacing: -0.8px;
  text-align: left;
  margin-right: 2px;
  user-select: none;
}

.user-email-text{
  font-size: 16px;
  font-weight: 600;
  color: #615E59;
  letter-spacing: -0.8px;
  text-align: left;
  margin-right: 2px;
  user-select: none;
}

</style>
  
