<template>
    <v-slide-x-transition>
<div v-if="this.$store.state.settings.canvasOffScreenVisible">

      <v-card  class="custom-component" >
        <img style="
        float: right;
        padding-top: 15px;
        padding-right: 10px;
        "
         width="55px" :src="require(`@/assets/images/${layerArrowImage}`)"  class="arrow" @click="hideComponent()"  alt="Annucal">
        <p >
       <v-menu v-model="menu"  :location="end" :close-on-content-click="false">
         <template v-slot:activator="{ props }">
           <img  dark v-bind="props" src="@/assets/images/newCalendarButton_big.png" class="buttonbig"  
           alt="new Calendar">
          
         </template>
 
         <div style="
           width: 450px;
           background-color: rgb(253, 253, 253);
         ">
           <CalendarMenu 
             @closeCalendarMenu="closeCalendarMenu"  
             header="Neuen Kalender anlegen"
             prop_mode="new"
             prop_kalendername=""
             prop_kalenderbeschreibung=""
             prop_externalCalendarLink=""
             :prop_radios= true
             prop_calendarColor="#FF9933"
             prop_icon='ph-CalendarCheck'
             prop_calendarId=""
             :prop_creatorUID=this.$store.state.user.userID
             />
         </div>
 
     </v-menu>
   </p>
        <calendar-list />
      </v-card>
</div>
        
    </v-slide-x-transition>
  </template>
  
  <script>
   import CalendarList from "@/components/calendarlist.vue";
   import CalendarMenu from "@/components/CalendarMenu.vue";

  export default {
    data() {
      return {
        isVisible: false,
        layerArrowImage: 'pfeillinks3mouseover.png',
        layerGridWidth: "90px auto",
        isGridExpanded: false,
      };
    },
    components: {
  
     CalendarList,
     CalendarMenu
   
 },
    methods: {
      hideComponent() {
        this.$store.state.settings.canvasOffScreenVisible = false
      },
    },

    changeArrowImageUnselected(){
       if(this.layerArrowImage == 'pfeilrechts3mouseover.png'){
         this.layerArrowImage = 'pfeilrechts3.png'
       }else if(this.layerArrowImage == 'pfeillinks3mouseover.png'){
         this.layerArrowImage = 'pfeillinks3.png'
       }
     },

     changeArrowImageSelected(){
       if(this.layerArrowImage == 'pfeilrechts3.png'){
         this.layerArrowImage = 'pfeilrechts3mouseover.png'
       }else if(this.layerArrowImage == 'pfeillinks3.png'){
         this.layerArrowImage = 'pfeillinks3mouseover.png'
       }
     },

    hideComponent() {
        this.$store.state.settings.canvasOffScreenVisible = false;
    }
  }
  </script>
  
  <style scoped>
  .custom-component {
    position: fixed;
    
    transform-origin: top left;

    
    width: 280px; /* Adjust the width as needed */
    height: 100vh;
  }
  .buttonsmall {
   display: block;
   margin-left: auto;
   margin-right: auto;
   padding-top: 30px;
   width: 90%;
   height: 64px;
   padding-left: 10px;
   padding-right: 10px;
 }

 .buttonbig {
   display: block;
   margin-left: auto;
   margin-right: auto;
   padding-top: 30px;
   width: 88%;
 }

 
  </style>
  