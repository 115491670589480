<template>
    <div class="delete-button" v-if="props.active == true" @click='handleClick'>
        <div class="delete-icon"  >
            <PhTrash />
        </div>
    </div>
    <div class="inactive-button" v-if="props.active == false">
        <div class="icon-trash-inactiv" >
          <PhTrash />
        </div>
    </div>
   
  
</template>

<script setup>
import { defineProps } from 'vue';
import { defineEmits } from 'vue';

const emit = defineEmits(['childClicked']);

const props = defineProps({
    active:{
        type: Boolean,
        required: true
    }
});

const handleClick = () => {
  emit('childClicked');
};



</script>

<style scoped>

.delete-icon {
    color: #e30303d4;
    font-size: 24px;
    font-weight: bold;
    transition: color 0.3s ease;
    padding-top: 5px;
}

.icon-trash-inactiv {
  padding-top: 5px;
  font-size: 24px;
  
  color: rgb(198, 198, 198); /* Icon weiß */
}

.delete-button:hover {
    background-color: #e30303d4;
    cursor: pointer; 
  }
  
.delete-button:hover .delete-icon {
color: white;
}

.delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px; 
    height: 45px; 
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease; 
    margin-left: 13px;
}

.inactive-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px; 
    height: 45px; 
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease; 
    margin-left: 13px;
}

</style>