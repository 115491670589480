<template>

    <!-------------------------------------------------------------------------------------------->
    <!---------------------------------------- Log In  ------------------------------------------->
    <!-------------------------------------------------------------------------------------------->
    <div style="
            margin-right: -32px;
            margin-top: -4px;
        ">
        <AnnucalVCard
            :first_column_width='0'
            :calendarColor = props.calendarColor
            :showCalendarName = true
            :showvCardActions = false
            :VcardWitdh = '190'
            calendarName="Menü"
            @close-menu="closeMenu"
        >
            <!-------------------------------------------------------------------------------------------->
            <!------------------------------  Middle Slot - Annucal VCard -------------------------------->
            <!-------------------------------------------------------------------------------------------->

            <template v-slot:middle>
                <!--------------------------------  Log In --------------------------------------> 
                    <div v-if="isAuthenticated  == false">
                        <AnnucalLink 
                            style="padding-top: 4px"
                            icon="PhUserCircle"
                            linktext="Anmelden" 
                            @click="openLogIn"
                        />
                            
                        <annucal-sign-in
                            :showCalendarName = true
                            @close-menu="closeSignInMenu"
                            :creatorUID=this.$store.state.user.userID
                            prop_mode="login"
                        />
                    </div>   

                <!--------------------------------  Log Out --------------------------------------> 
                <div v-if="isAuthenticated  == true">
                        <AnnucalLink 
                        style="padding-top: 12px"
                        icon="PhSignOut"
                        linktext="Abmelden" 
                        @click="openLogOut"
                    />
                        
                    <annucal-sign-in
                        :showCalendarName = true
                        @close-menu="closeSignInMenu"
                        :creatorUID=this.$store.state.user.userID
                        prop_mode="logout"
                    />
                </div>   
                

                <!-------------------------------  Einstellungen ------------------------------------>
                <div style="padding-top: 4px">
                    <AnnucalLink
                        icon="PhGear"
                        linktext="Einstellungen" 
                    />
                </div>
               
                
            </template>

        </AnnucalVCard>
    </div>

</template>

<script setup>

import { defineProps, defineEmits, ref, computed, onMounted} from 'vue'
import {useStore} from "vuex";
import AnnucalVCard from "./AnnucalVcard.vue"
import AnnucalLink from "./AnnucalLink.vue"
import AnnucalSignIn from "./AnnucalSignIn.vue"

const store = useStore();
const { state } = store;

const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

const props = defineProps({
    creatorUID:{
        type: String,
        required: true
    }
});

onMounted(() => initialize());

const initialize = () => {
    store.state.settings.AnnucalVcardHeadline = "Menü"
}   

function openLogIn(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = "Anmelden"
    state.settings.AnnucalSaveText = "Anmelden"
    state.settings.Log_Status = "login"
    state.settings.showRegister = false
    state.settings.showForgotPassword = false
    closeMenu()
}

function openLogOut(){
    state.settings.AnnucalSignInPageVisible = true
    state.settings.AnnucalVcardHeadline = "Abmelden"
    state.settings.AnnucalSaveText = "Abmelden"
    state.settings.Log_Status = "logout"
    state.settings.showRegister = false
    state.settings.showForgotPassword = false
    closeMenu()
}

const emit = defineEmits(['close-menu'])

function closeMenu(){
    emit('close-menu')
}

function closeSignInMenu(){
    signInmenu.value = false
}


const signInmenu = ref(false);



</script>