<template>

  <div class="main-div">
      <div v-if="showDeleteButton == false">
        <AnnucalVCard 
      
        saveText = "savecalendar"
        :showCalendarName = true
        :showvCardActions = true
        :VcardWitdh = '440'
        :prop_mode="props.prop_mode"
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeMenu"
        style="
            margin-left:12px;
            margin-right:8px;
            margin-top:82px;
        "
     
    >
      <template v-slot:middle>
  
          <div class="first-row"></div>
  
          <div>
      <label class="radio country-name">
        <input
          type="radio"
          name="answer"
          value="Feiertage"
          v-model="selectedSpecialday"
          @change="changeselectedSpecialday('Feiertage')"
        />
        &nbsp; Feiertage
      </label>
    </div>
    <div>
      <label class="radio country-name">
        <input
          type="radio"
          name="answer"
          value="Schulferien"
          v-model="selectedSpecialday"
          @change="changeselectedSpecialday('Schulferien')"
        />
        &nbsp; Schulferien
      </label>
    </div>
  
              <div class="second-row"></div>
  
        <AnnucalTextfieldVue
                          labeltext="Suche ein Land"
                          v-model="selectedCountryName" 
                          label = "Land auswählen"
                          @input="filterCountries"
                          showCounter="false"
                      />
  
  
     
    
      <!-- Liste der gefilterten Länder (ohne Vuetify) -->
      <ul v-if="filteredCountries.length" class="custom-list">
        <li
          v-for="country in filteredCountries.slice(0, 5)"
          :key="country.CountryCode"
          @click="selectCountry(country)"
          class="custom-list-item"
        >
          {{ country.Country }}
        </li>
      </ul>
    
        <!-- Suche nach Unterteilungen, immer aktiv -->
        <div class="third-row"></div>
  
        <AnnucalTextfieldVue
        v-if="selectedCountry"
                          labeltext="Suche eine Unterteilung"
                          v-model="selectedSubdivisionName" 
                          label = "Ort auswählen"
                          @input="filterSubdivisions"
                          showCounter="false"
                      />
  
  
    
       <!-- Liste der gefilterten Unterteilungen (ohne Vuetify) -->
       <ul v-if="filteredSubdivisions.length && selectedCountry" class="custom-list">
        <li
          v-for="subdivision in filteredSubdivisions.slice(0,5)"
          :key="subdivision.SC"
          @click="selectSubdivision(subdivision)"
          class="custom-list-item"
        >
          {{ subdivision.SN }}
        </li>
      </ul>
    
        <!-- Anzeige des ausgewählten Landes und der Unterteilung -->
       <!-- <div v-if="selectedCountry || selectedSubdivision">
          <p>
            Ausgewähltes Land: <strong>{{ selectedCountryName }}</strong>
            <span v-if="selectedSubdivision"> - Unterteilung: {{ selectedSubdivision.SN }} ({{ selectedSubdivision.SC }})</span>
          </p>
        </div>-->
  
      
      </template>
  
    </AnnucalVCard> 
      </div>
      


    <div v-if="showDeleteButton == true">
      <AnnucalVCard 
      showDeleteButton = true
        saveText = "savecalendar"
        :showCalendarName = true
        :showvCardActions = true
        :VcardWitdh = '440'
        :prop_mode="props.prop_mode"
        @unsubscribeMain="unsubscribeMain"
        @deleteElement="deleteElement"
        @saveEdit="saveEdit"
        @savenew="savenew"
        @close-menu="closeMenu"
        style="
            margin-left:12px;
            margin-right:8px;
            margin-top:82px;
        "
     
    >
      <template v-slot:middle>
  
          <div class="first-row"></div>
  
          <div>
      <label class="radio country-name">
        <input
          type="radio"
          name="answer"
          value="Feiertage"
          v-model="selectedSpecialday"
          @change="changeselectedSpecialday('Feiertage')"
        />
        &nbsp; Feiertage
      </label>
    </div>
    <div>
      <label class="radio country-name">
        <input
          type="radio"
          name="answer"
          value="Schulferien"
          v-model="selectedSpecialday"
          @change="changeselectedSpecialday('Schulferien')"
        />
        &nbsp; Schulferien
      </label>
    </div>
  
              <div class="second-row"></div>
  
        <AnnucalTextfieldVue
                          labeltext="Suche ein Land"
                          v-model="selectedCountryName" 
                          label = "Land auswählen"
                          @input="filterCountries"
                          showCounter="false"
                      />
  
  
     
    
      <!-- Liste der gefilterten Länder (ohne Vuetify) -->
      <ul v-if="filteredCountries.length" class="custom-list">
        <li
          v-for="country in filteredCountries.slice(0, 5)"
          :key="country.CountryCode"
          @click="selectCountry(country)"
          class="custom-list-item"
        >
          {{ country.Country }}
        </li>
      </ul>
    
        <!-- Suche nach Unterteilungen, immer aktiv -->
        <div class="third-row"></div>
  
        <AnnucalTextfieldVue
        v-if="selectedCountry"
                          labeltext="Suche eine Unterteilung"
                          v-model="selectedSubdivisionName" 
                          label = "Ort auswählen"
                          @input="filterSubdivisions"
                          showCounter="false"
                      />
  
  
    
       <!-- Liste der gefilterten Unterteilungen (ohne Vuetify) -->
       <ul v-if="filteredSubdivisions.length && selectedCountry" class="custom-list">
        <li
          v-for="subdivision in filteredSubdivisions.slice(0,5)"
          :key="subdivision.SC"
          @click="selectSubdivision(subdivision)"
          class="custom-list-item"
        >
          {{ subdivision.SN }}
        </li>
      </ul>
    
        <!-- Anzeige des ausgewählten Landes und der Unterteilung -->
       <!-- <div v-if="selectedCountry || selectedSubdivision">
          <p>
            Ausgewähltes Land: <strong>{{ selectedCountryName }}</strong>
            <span v-if="selectedSubdivision"> - Unterteilung: {{ selectedSubdivision.SN }} ({{ selectedSubdivision.SC }})</span>
          </p>
        </div>-->
  
      
      </template>
  
    </AnnucalVCard> 
      </div>
    
  
  </div>
    
  </template>
    
  <script setup>
  
    import { ref, defineEmits, onMounted, defineProps, computed } from 'vue';
    import AnnucalVCard from "./AnnucalVcard.vue"
    import AnnucalTextfieldVue from "./AnnucalTextfield.vue"
    import {useStore} from "vuex";
    import calenderCore from "../composition/calenderCore";
    
    
    const calenderCore1 = calenderCore();
    const showDeleteButton = ref(false)
  
    const props = defineProps({
      prop_mode: {
      type: String,
      required: true
    },
    prop_calendarId: String,
    prop_docId: String,
    allowDelete: {
      type: Boolean,
      required: true 
  }
  });
  
    const store = useStore();
  const { state } = store;
  
  const emit = defineEmits(['close-menu'])
  
  const selectedSpecialday = ref('Feiertage'); // Feiertage ist standardmäßig ausgewählt
  
  const changeselectedSpecialday = (day) => {
    console.log(day); // Hier kannst du den Wert weiterverarbeiten
  };
  
  function closeMenu(){
      emit('close-menu')
  }
  
  onMounted(() => initialize());
  
  const initialize = () => {
      store.state.settings.AnnucalVcardHeadline = "Feiertage - Schulferien"
      store.state.settings.AnnucalSaveText = "Speichern"
      calendarId.value= props.prop_calendarId
      docId.value = props.prop_docId
      store.state.settings.savingModus = false
      showDeleteButton.value = props.allowDelete
  } 
  
  const countries = computed({
          get() {
            return state.countries.items;
          },
        })
    
    
    
    const selectedCountry = ref(null);
    const selectedSubdivision = ref(null);
    const selectedCountryName = ref('');
    const selectedSubdivisionName = ref('');
    const filteredCountries = ref([]);
    const filteredSubdivisions = ref([]);
    
    // Methode zur Filterung der Länder
    const filterCountries = () => {
      filteredCountries.value = countries.value.filter(country =>
        country.Country.toLowerCase().includes(selectedCountryName.value.toLowerCase())
      );
      if (!selectedCountryName.value) {
        selectedCountry.value = null;
        selectedSubdivisionName.value = '';
        filteredSubdivisions.value = [];
      }
    };
    
    // Methode zur Filterung der Unterteilungen
    const filterSubdivisions = () => {
      if (selectedCountry.value) {
        filteredSubdivisions.value = selectedCountry.value.Subdivisions.filter(subdivision =>
          subdivision.SN.toLowerCase().includes(selectedSubdivisionName.value.toLowerCase())
        );
      }
    };
    
    // Auswahl eines Landes
    const selectCountry = (country) => {
      selectedCountry.value = country;
      selectedCountryName.value = country.Country;
      filteredCountries.value = [];
      selectedSubdivision.value = null; // Reset der Unterteilungswahl
      selectedSubdivisionName.value = ''; // Reset des Suchfeldes für Unterteilungen
    };
    
    // Auswahl einer Unterteilung
    const selectSubdivision = (subdivision) => {
      selectedSubdivision.value = subdivision;
      selectedSubdivisionName.value = subdivision.SN;
      filteredSubdivisions.value = [];
    };

    const docId = ref('')
    const calendarId = ref('')
    const deleting = ref(false);

    const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

    const events = computed({
        get() {
            return state.events.items;
        },
        })

async function deleteElement(){

  let Calendar = {    
      calendarId: calendarId.value,
      docId: docId.value,
      prop_docId: String,
      creatorUID: store.state.user.userID,
  }
  console.log("test")
  await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
  await store.dispatch("deleteCalendarFromCalendars", Calendar);
  unsubcribeToCalendarAfterDeleting(Calendar) 
  SetNewSelectedCalender()
  emit('close-menu')

}

function SetNewSelectedCalender(){
        for (let i = 0; i < mycalendars.value.length; i++){
            if(mycalendars.value[i].externalCalendarLink == null || mycalendars.value[i].externalCalendarLink == "" || mycalendars.value[i].externalCalendarLink == undefined){
                store.state.settings.currentSelectedCalendar = mycalendars.value[i];
                store.state.settings.currentCalendarId = mycalendars.value[i].calendarId
                store.state.settings.currentCalendarColor = mycalendars.value[i].color 
                store.state.settings.currentCalendarIcon = mycalendars.value[i].icon
            }
        }
  }

async function unsubcribeToCalendarAfterDeleting(result){

deleting.value = true;

for (let index = 0; index < mycalendars.value.length; ++index){
    if(mycalendars.value[index].docId == result.docId){
        mycalendars.value.splice(index, 1);
    }
}

for(let index2 = 0; index2 < events.value.length; ++index2){
    if(events.value[index2].calendarId == result.docId){
        events.value.splice(index2,1);
    }
}


calenderCore1.updateCalender();

deleting.value = false;

}
  

async function savenew(){

  store.state.settings.savingModus = true

  /*
    prop_type="1"
    1 = Internal Kalender
    2 = Holiday, Shool holidays and other international events
    3 = Subscribed ICal from Google, Apple, and so on ...
  */
  
  let newCalendar = {
      color: '#A39F96', //calendarColor.value, 
      //color: '#34ccff', - Farbe für Feiertage
      icon: 'ph-CalendarCheck', //icon.value,
      name: selectedSpecialday.value,
      externalCalendarLink: '',//externalCalendarLink.value,
      description: '',
      visible: true,
      private: true,
      creatorUID: store.state.user.userID,
      creatorName: "Annucal",
      //calendarImage:  calendarImage,
      type: '2',
      specialday:selectedSpecialday.value,
      country: selectedCountryName.value,
      subdivision: selectedSubdivisionName.value,
      events:[]
  }
  
  const newCalendarData = await store.dispatch("createCalendar", newCalendar);
  newCalendar.icon = newCalendarData.imageURL
  newCalendar.calendarId = newCalendarData.calendarId
  newCalendar.docId = newCalendarData.MyCalendarsdocId
  store.state.mycalendars.items.push(newCalendar)
  
  calenderCore1.updateCalender();
  
  emit('close-menu')
  }
  
  
  
  </script>
    
  <style scoped>
  
  .main-div{
      margin-left:37px;
      margin-top: -116px;
  }
  .first-row{
      padding-top:16px;
  }
  
  .second-row{
      padding-top: 30px;
  }
  
  .third-row{
      padding-top: 4px;
  }
  
  .list-text{
    font-size: 14px;
  }
  
  .custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-list-item {
    padding: 0 5px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .custom-list-item:hover {
    background-color: #f0f0f0;
  }
  
  
  </style>
    
  