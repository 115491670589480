export default {
  "January": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero"])};fn.source="Enero";return fn;})(),
  "February": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Febrero"])};fn.source="Febrero";return fn;})(),
  "March": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo"])};fn.source="Marzo";return fn;})(),
  "April": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])};fn.source="Abril";return fn;})(),
  "May": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayo"])};fn.source="Mayo";return fn;})(),
  "June": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junio"])};fn.source="Junio";return fn;})(),
  "July": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julio"])};fn.source="Julio";return fn;})(),
  "August": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])};fn.source="Agosto";return fn;})(),
  "September": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septiembre"])};fn.source="Septiembre";return fn;})(),
  "October": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octubre"])};fn.source="Octubre";return fn;})(),
  "November": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noviembre"])};fn.source="Noviembre";return fn;})(),
  "December": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diciembre"])};fn.source="Diciembre";return fn;})(),
  "Monday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])};fn.source="Lunes";return fn;})(),
  "Tuesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes"])};fn.source="Martes";return fn;})(),
  "Wednesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miércoles"])};fn.source="Miércoles";return fn;})(),
  "Thursday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jueves"])};fn.source="Jueves";return fn;})(),
  "Friday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viernes"])};fn.source="Viernes";return fn;})(),
  "Saturday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])};fn.source="Sábado";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])};fn.source="Domingo";return fn;})(),
  "Public holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacaciones públicas"])};fn.source="Vacaciones públicas";return fn;})(),
  "School holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacaciones escolares"])};fn.source="Vacaciones escolares";return fn;})(),
  "Calendar weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanas calendario"])};fn.source="Semanas calendario";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])};fn.source="Acceso";return fn;})(),
  "No account yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes cuenta aún"])};fn.source="No tienes cuenta aún";return fn;})(),
  "Repeat password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita la contraseña"])};fn.source="Repita la contraseña";return fn;})(),
  "Forgot password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has olvidado tu contraseña"])};fn.source="Has olvidado tu contraseña";return fn;})(),
  "Register now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate ahora"])};fn.source="Regístrate ahora";return fn;})(),
  "Reset now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restablecer ahora"])};fn.source="restablecer ahora";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])};fn.source="Registro";return fn;})(),
  "Already registered?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya registrado?"])};fn.source="¿Ya registrado?";return fn;})(),
  "Log in now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión ahora"])};fn.source="Inicia sesión ahora";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])};fn.source="Cerca";return fn;})()
}