<template>
    <div v-for ="(event) in item.events" :key="event.eventidDB"> 
             
             <div  v-if:="event.calendarId == calendarId" 
               v-bind:style="{ 
                   backgroundColor:  event.eventColor,
                   color: event.eventColor
                 }" >
        
        <div style="width: 25px;">
          <ToolTipFullLineVue v-if="item.showTooltip" 
                        placement="top" 
                        :item=item 
                    />
         </div>
             </div>
       
         </div>
      
   

</template>
  
<script>
  import { useStore } from "vuex";
  import { defineComponent, ref, computed, mergeProps } from 'vue'
  //import Tooltip from './ToolTip.vue';
  import calenderCore from "../composition/calenderCore";
  import { useVuelidate } from '@vuelidate/core'
  import { required,maxLength } from '@vuelidate/validators'
  import { useToast } from "vue-toastification"
  //import AddNewItemBoxVue from "./AddNewItemBox.vue";
  import { useElementSize } from '@vueuse/core'
  import { Timestamp } from "firebase/firestore";
  import ToolTipFullLineVue from "./ToolTipFullLine.vue";

  
   
  
  const toast = useToast();
  const windowHeight = ref(window.innerHeight);
  const newdayHight = ref(windowHeight.value/38);
  
  export default defineComponent({
  
    name: 'Popover',
    props: {
      item:Object,
      calendarId:String
    }, 
  
    components: {
      ToolTipFullLineVue
      //Tooltip,
      //AddNewItemBoxVue,
    },
    data () {
        return {
          newEventMenueNew: false,
          newEventMenueNewRight: false,
          newEventMenueEdit: false,
          Titel: "",
          date: null,
          insertEventisOpen: false,
          deleteEventisOpen: false,
          backColor: "#ffffff",
          eventName: "",
          selectedDate: "",
          name:"",
          backColorTemp: "#ffffff",
          backColorWeekDayTemp: "#ffffff",
          currentEventText: "",
          mouseDownDate: "",
          selectedEvent: "",
          deleteBackgroundColor: "#00ff00",
          selected_dateFormatedJson: "",
          selectedDay: null,
          weekDayColor:"#ff0000",
          v$: useVuelidate(),
          rows:1,
          mouseOverEventId: -1,
          changeEventTimeWithMouse: false,
          newdayHight,
          showTooltip: false
        }
      },
  
      mounted() {      
        window.addEventListener("resize", this.calculateWindowWidth);    
      },   
      
      beforeUnmount() {      
        window.removeEventListener("resize", this.calculateWindowWidth);   
      },
  
      computed:{
  
        currentCalendarColor(){
          return this.$store.state.settings.currentCalendarColor;
        },
        events(){
            return this.$store.state.events.items;
          }
        },
        items(){
              return this.$store.state.calender.items;
        },
        myCalendarItems(){
              return this.$store.state.mycalendars.items;
        },
  
        async created(){
          if(this.$store.state.settings.EventsLoaded == false){
            this.calenderCore1 = calenderCore();
            await this.$store.dispatch("getMyEvents").then (
            setTimeout(() => {  this.calenderCore1.updateCalender(); }, 500)
          )
          this.$store.state.settings.EventsLoaded = true
          }
        },
  
    methods:{
  
      mergeProps,
  
      calculateWindowWidth() {        this.windowWidth = window.innerWidth;      },
  
      returnbgimage(day){
        if(this.$store.state.settings.showKalenderwochen == true){
          return day.kw;
        }
        else{
          return("empty.svg");
        }
      },
  
      closeEventInfoBox(){
        this.newEventMenueNew = false
        for (let i2 = 0; i2 < this.$store.state.calender.items.length; i2++) {
          for (let i3 = 0; i3 < this.$store.state.calender.items[i2].events.length; i3++) {
            this.$store.state.calender.items[i2].events[i3].eventInfoBoxOpen = false
          }
        }
      },
  
      
      closeNewEventMenue(){
        this.newEventMenueNew = false
        this.newEventMenueNewRight = false
        
        for (let x = 0; x < this.$store.state.calender.items.length; x++){
          for (let y = 0; y < this.$store.state.calender.items[x].length; y++){
              for (let i3 = 0; i3 < this.$store.state.calender.items[x][y].events.length; i3++){
              this.$store.state.calender.items[x][y].events[i3].menuopen = false
            }
          }
        }
      },
  
    
  
      clickOnDayNumber(item){
        this.$store.state.settings.eventName = "";
        this.$store.state.settings.eventColor =  '#ebebeb';
        this.$store.state.settings.SlotHeader = "Neues Ereignis";     
        this.$store.state.settings.ShowDeleteButton = false; 
        this.$store.state.settings.SlotMode = "New";
        this.$store.state.settings.currentEventIdDB = "No Event"
        this.insertEventisOpen = true;
        this.selectedDate = item.dateFormated_De;
        this.$store.state.settings.slectedDayId = item.id;
        this.$store.state.settings.currentdatefromJson = item.dateFormated_Json;
        this.selected_dateFormatedJson = item.dateFormated_Json;
        this.selectedDay = item;
        this.$store.state.settings.currentdatefrom = item.dateFormated_Json// this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
        this.$store.state.settings.currentdateto = item.dateFormated_Json//  this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
        this.$store.state.settings.currentdatefromJson =  item.dateFormated_Json// this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
        this.$store.state.settings.currentdatetoJson = item.dateFormated_Json//  this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
        this.$store.state.dayevent.dateFrom = new Date(item.id)
        this.$store.state.dayevent.dateTo = new Date(item.id)
      },
  
  
      click(item){
        if(this.SelectedDateHasEvent(item.id) != false){
          // Edit Event
          this.$store.state.settings.eventName = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).title;
          this.$store.state.settings.eventColor =  item.backColor;
          this.$store.state.settings.SlotHeader = "Ereignis bearbeiten";
          this.$store.state.settings.ShowDeleteButton = true;
          this.$store.state.settings.SlotMode = "Edit";
          this.$store.state.settings.currentEventId = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).id;
          this.$store.state.settings.currentEventIdDB = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).eventidDB
          this.deleteBackgroundColor = item.backColor ;
          this.deleteEventisOpen = true;
          this.selectedEvent = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id));
          this.selectedDay = null;
          this.$store.state.settings.currentdatefrom = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
          this.$store.state.settings.currentdateto = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
          this.$store.state.settings.currentdatefromJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
          this.$store.state.settings.currentdatetoJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
        }
        // Insert Event
        else{
            this.$store.state.settings.eventName = "";
            this.$store.state.settings.eventColor =  '#ebebeb';
            this.$store.state.settings.SlotHeader = "Neues Ereignis";     
            this.$store.state.settings.ShowDeleteButton = false; 
            this.$store.state.settings.SlotMode = "New";
            this.$store.state.settings.currentEventIdDB = "No Event"
            this.insertEventisOpen = true;
            this.selectedDate = item.dateFormated_De;
            this.$store.state.settings.slectedDayId = item.id;
            this.$store.state.settings.currentdatefromJson = item.currentdatefromJson;
            this.selected_dateFormatedJson = item.dateFormated_Json;
            this.selectedDay = item;
        }
      },
  
      doubleclick(item){
      
        if(this.SelectedDateHasEvent(item.id) != false){
          // Edit Event
          this.$store.state.settings.eventName = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).title;
          this.$store.state.settings.eventColor =  item.backColor;
          this.$store.state.settings.SlotHeader = "Ereignis bearbeiten";
          this.$store.state.settings.ShowDeleteButton = true;
          this.$store.state.settings.SlotMode = "Edit";
          this.$store.state.settings.currentEventId = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).id;
          this.$store.state.settings.currentEventIdDB = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).eventidDB
          this.deleteBackgroundColor = item.backColor ;
          this.deleteEventisOpen = true;
          this.selectedEvent = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id));
          this.selectedDay = null;
          this.$store.state.settings.currentdatefrom = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefrom
          this.$store.state.settings.currentdateto = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).dateto
          this.$store.state.settings.currentdatefromJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datefromJson
          this.$store.state.settings.currentdatetoJson = this.GetSelectedEvent(this.SelectedDateHasEvent(item.id)).datetoJson
        }
        // Insert Event
        else{
            this.$store.state.settings.eventName = "";
            this.$store.state.settings.eventColor =  '#ebebeb';
            this.$store.state.settings.SlotHeader = "Neues Ereignis";     
            this.$store.state.settings.ShowDeleteButton = false; 
            this.$store.state.settings.SlotMode = "New";
            this.$store.state.settings.currentEventIdDB = "No Event"
            this.insertEventisOpen = true;
            this.selectedDate = item.dateFormated_De;
            this.$store.state.settings.slectedDayId = item.id;
            this.$store.state.settings.currentdatefromJson = item.currentdatefromJson;
            this.selected_dateFormatedJson = item.dateFormated_Json;
            this.selectedDay = item;
        }
      
    },
  
    mousedown(item, event, id){
      
      if(id == 2){
        this.$store.state.settings.ismousedown = true
      
          if(this.$store.state.user.userID == event.creatorUID){
            this.changeEventTimeWithMouse = true
  
          if(this.TerminHasOnlyOneDay(event)){
            this.$store.state.settings.mouseDownEventStartDate = item.id;
            this.$store.state.settings.mouseDownEventEndDate = item.id
            this.$store.state.settings.selectedEventIDtoChangeDate = event.eventidDB;
            this.$store.state.settings.selectedEventIndexToChange = event.index;
            this.$store.state.settings.FirstDayOfEventSelected  = true;
            this.$store.state.settings.LastDayOfEventSelected = true;
          }
          else if (this.IsFirstDayOfEvent(event, item.id) == true){
            this.$store.state.settings.mouseDownEventStartDate = item.id;
            this.$store.state.settings.selectedEventIDtoChangeDate = event.eventidDB;
            this.$store.state.settings.selectedEventIndexToChange = event.index;
            this.$store.state.settings.FirstDayOfEventSelected = true;
            this.$store.state.settings.LastDayOfEventSelected = false;
            
          }
            else if(this.IsLastDayOfEvent(event, item.id) == true){
              this.$store.state.settings.mouseDownEventEndDate = item.id;
              this.$store.state.settings.selectedEventIDtoChangeDate = event.eventidDB;
              this.$store.state.settings.selectedEventIndexToChange = event.index;
              this.$store.state.settings.FirstDayOfEventSelected = false;
              this.$store.state.settings.LastDayOfEventSelected = true;
            }
          }
      }
    },
  
    mouseup(item){
  
      this.mouseOverEventId = -1
     
  
      this.$store.state.settings.ismousedown = false;
      this.backColor = item.backColor;
      this.$store.state.settings.mouseDownEventStartDate = false;
      this.$store.state.settings.mouseDownEventEndDate = false;  
      this.$store.state.settings.addDayOnEvent = false;    
      this.$store.state.settings.FirstDayOfEventSelected = false;
      this.$store.state.settings.LastDayOfEventSelected = false;
      this.calenderCore1.updateCalender();
  
      if(this.$store.state.settings.eventNeedsSaving != "no"){
        for (let index = 0; index < this.events.length; ++index) {
            if (this.events[index].eventidDB == this.$store.state.settings.eventNeedsSaving)
            {
              if(this.$store.state.settings.Authenticated == true){
                let newEvent = {
                  calendarId: this.events[index].calendarId,
                  eventidDB : this.events[index].eventidDB,
                  color: this.events[index].color, 
                  title: this.events[index].title,
                  datefrom: this.events[index].datefrom,
                  dateto: this.events[index].dateto,
                  datefromJson: this.events[index].datefromJson ,
                  datetoJson: this.events[index].datetoJson,   
                  fromHour: this.events[index].fromHour,
                  fromMinute: this.events[index].fromMinute,
                  toHour: this.events[index].toHour,
                  toMinute: this.events[index].toMinute,
                  isFullDay: this.events[index].isFullDay,
                  additionalInfos: this.events[index].additionalInfos,
                  editedOn: Timestamp.now(),
                  rRules: this.events[index].rRules
              } 
              this.$store.dispatch("updateStoredEvent", newEvent); 
              }  
            }
        }
  
        this.$store.state.settings.eventNeedsSaving = "no"
      }
    },
  
    mouseenter(item, id){
  
      if(id == 2){
          this.mouseOverEventId = item.eventId
          //this.$store.state.settings.selectedCursor = "grab"
      }
     
      if(this.$store.state.settings.ismousedown == true){
          
            if(this.$store.state.settings.LastDayOfEventSelected == true){
              this.addDayToEventBottom(item);
            }
            else if(this.$store.state.settings.FirstDayOfEventSelected == true){
              this.addDayToEventTop(item);
            }
            
      }
     
      if(this.$store.state.settings.ismousedown == false){
          if(this.$store.state.settings.selectedCursor != item.cursor){
            this.$store.state.settings.selectedCursor = item.cursor;
          }
      } 
    
      //Highlight day under MouseMove
      this.backColorTemp = item.backColor;
      this.backColorWeekDayTemp = item.backColorWeekDay;
      item.backColorWeekDay = '#f5fc81';
   
    },
  
    mouseleave(item){ 
      
      //item.backColor = this.backColorTemp;
      
      item.backColorWeekDay = this.backColorWeekDayTemp;
      
      /*if(this.$store.state.settings.ismousedown == true){
       
       if(this.$store.state.settings.FixedWithMousUp == false){
       // console.log("jetzt updaten")
        this.calenderCore1.updateCalender();
        this.$store.state.settings.FixedWithMousUp = true
       }
        
      }*/
       
    },
  
    mousemove(item)
    {
      
      if(this.$store.state.settings.ismousedown == true)
      {
        if(item.backColor == this.backColor)
        {
          item.backColor = item.backColorWeekDay;
        }
  
        if(this.$store.state.settings.ctrlPressed == true){
          this.calenderCore1.updateCalender();
        }
      }
    },
  
    addDayToEventBottom(item){ 
      if(this.$store.state.settings.ismousedown == true && this.$store.state.settings.ctrlPressed == false)
      {
        this.$store.state.settings.selectedCursor = "ns-resize";
        //Add day to event
        if(this.$store.state.settings.mouseDownEventEndDate != false){    
          
          var dt1 = new Date(this.$store.state.settings.mouseDownEventEndDate);
          var dt2 = new Date(item.id);
  
          if(dt2 >= dt1){ 
          //if(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24)) > -1){
            //for (let index = 0; index < this.events.length; ++index) {
              //if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
              {
                  //if(this.IsLastEventDateWithinNextEvent(this.events[index].id,item)== false){
                      this.events[this.$store.state.settings.selectedEventIndexToChange].dateto = item.id;
                      this.events[this.$store.state.settings.selectedEventIndexToChange].datetoJson =  item.dateFormated_Json ,
                      this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                      this.$store.state.settings.addDayOnEvent = false;
                      this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                //} 
              //} 
            }
          }
          //Remove day from event
          if(dt1 > dt2){ 
          //else if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) > 0){
            //for (let index = 0; index < this.events.length; ++index) {
              //if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
              //{
                var dt_from = new Date (this.events[this.$store.state.settings.selectedEventIndexToChange].datefrom);
                var dt_to = new Date(item.id);
                
                if(Math.floor((Date.UTC(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate()) - Date.UTC(dt_to.getFullYear(), dt_to.getMonth(), dt_to.getDate()) ) /(1000 * 60 * 60 * 24)) < 1){
                    this.events[this.$store.state.settings.selectedEventIndexToChange].dateto = item.id;
                    this.events[this.$store.state.settings.selectedEventIndexToChange].datetoJson =  item.dateFormated_Json;
                    this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                    this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                } 
              //}
            //}
          }
        } 
      }
  
    },
  
    addDayToEventTop(item){
      if(this.$store.state.settings.ismousedown == true && this.$store.state.settings.ctrlPressed == false)
      {
        //Add day to event
        this.$store.state.settings.selectedCursor = "ns-resize";
        if(this.$store.state.settings.mouseDownEventStartDate != false){    
            var dt1 = new Date(this.$store.state.settings.mouseDownEventStartDate);
            var dt2 = new Date(item.id);
  
            if(dt1 >= dt2){
            //if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) >   -1){
              //for (let index = 0; index < this.events.length; ++index) {
              //  if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
               // {
                  
                   // if(this.IsFirstEventDateWithinPreviousEvent(this.events[index].id,item)== false){
                        this.events[this.$store.state.settings.selectedEventIndexToChange].datefrom = item.id;
                        this.events[this.$store.state.settings.selectedEventIndexToChange].datefromJson = item.dateFormated_Json;
                        this.$store.state.settings.mouseDownEventStartDate = item.id;
                        this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                        this.$store.state.settings.addDayOnEvent = false;
                        this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                    //}
                //}
             // }
            } 
            //Remove day from event
            else if(dt2 > dt1){
            //else if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) < 0){
              //for (let index = 0; index < this.events.length; ++index) {
              //  if (this.events[index].eventidDB == this.$store.state.settings.selectedEventIDtoChangeDate)
              //  {
                  var dt_from = new Date (item.id);
                  var dt_to = new Date(this.events[this.$store.state.settings.selectedEventIndexToChange].dateto);
                   
                  if(dt_from <= dt_to){
                  //if(Math.floor((Date.UTC(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate()) - Date.UTC(dt_to.getFullYear(), dt_to.getMonth(), dt_to.getDate()) ) /(1000 * 60 * 60 * 24)) < 1){
                      this.events[this.$store.state.settings.selectedEventIndexToChange].datefrom = item.id;
                      this.events[this.$store.state.settings.selectedEventIndexToChange].datefromJson = item.dateFormated_Json;
                      this.$store.state.settings.mouseDownEventStartDate = item.id;
                      this.calenderCore1.updatedateEvent(this.events[this.$store.state.settings.selectedEventIndexToChange], item.id);
                      this.$store.state.settings.eventNeedsSaving = this.events[this.$store.state.settings.selectedEventIndexToChange].eventidDB
                  } 
                //}
              //}
            }
          } 
      }
  
    },
  
    ChangeEventNameAndColor(Eventcolor, EventName){
      for (let index = 0; index < this.events.length; ++index) {
          if (this.events[index].id == this.$store.state.settings.currentEventId)
          {
            this.events[index].title = EventName
            this.events[index].color = Eventcolor
            this.events[index].datefrom = this.$store.state.settings.currentdatefrom
            this.events[index].dateto = this.$store.state.settings.currentdateto
            this.events[index].datefromJson = this.$store.state.settings.currentdatefromJson
            this.events[index].datetoJson = this.$store.state.settings.currentdatetoJson
          }
      } 
    },
  
    async saveChanges(Eventcolor, EventName, e){
  
      this.eventName = EventName
      var isValid = await this.v$.$validate();
  
      //if(this.$store.state.settings.eventColor == "#ebebeb"){
      //  isValid = false;
      //  toast.error("Bitte eine Farbe auswählen")
     // }
  
      if (isValid){
          let tempdocid = Math.floor(Math.random() * 100000000)
          if(this.$store.state.settings.SlotMode == "Edit"){
              this.ChangeEventNameAndColor(Eventcolor, EventName);
  
              if(this.$store.state.settings.Authenticated == true){
                let newEvent = {
                  userid: this.$store.state.user.userID,
                  eventidDB : this.$store.state.settings.currentEventIdDB,
                  color: this.$store.state.settings.currentCalendarColor, 
                  title: EventName,
                  datefrom: this.$store.state.settings.currentdatefrom,
                  dateto: this.$store.state.settings.currentdateto,
                  datefromJson: this.$store.state.settings.currentdatefromJson ,
                  datetoJson: this.$store.state.settings.currentdatetoJson    
                }
                this.$store.dispatch("updateEvent", newEvent);
              }
          }
          else{
            if(this.$store.state.settings.Authenticated == true){
              if(this.$store.state.settings.currentEventIdDB == "No Event"){
                let newEvent = {
                  calendarId: this.$store.state.settings.currentCalendarId,
                  color: this.$store.state.settings.currentCalendarColor, 
                  title: EventName,
                  datefrom: this.$store.state.settings.slectedDayId,
                  dateto: this.$store.state.settings.slectedDayId,
                  datefromJson: this.selected_dateFormatedJson ,
                  datetoJson: this.selected_dateFormatedJson    
                }
                const docid =  await this.$store.dispatch("createEvent", newEvent);
                tempdocid = docid.id
              }
            }
            this.events.push({
              calendarId: this.$store.state.settings.currentCalendarId,
              eventidDB : tempdocid,
              color: this.$store.state.settings.currentCalendarColor, 
              title: EventName,
              datefrom: this.$store.state.settings.slectedDayId,
              dateto: this.$store.state.settings.slectedDayId,
              datefromJson: this.selected_dateFormatedJson,
              datetoJson: this.selected_dateFormatedJson  
            })
          }
          if(this.selectedDay != null){
              this.selectedDay.cursor = "s-resize";
          } 
          this.calenderCore1.updateCalender();
          this.handleHide(e);
        }
        else{
          if(this.v$.eventName.required.$invalid){
            toast.error("Bitte einen Titel für das Ereignis eintragen")
          }
          else if(this.v$.eventName.maxLength.$invalid){
            toast.error("Der Eventname darf aus max. 25 Zeichen bestehen")
          }
        }
    },
  
    deleteEvent(){
      for (let index = 0; index < this.events.length; ++index){
        if (this.events[index].id == this.$store.state.settings.currentEventId){
            this.events.splice(index, 1); 
            this.calenderCore1.updateCalender();
            if(this.$store.state.settings.Authenticated == true){
              let eventToDelete = {
                userid: this.$store.state.user.userID,
                eventidDB : this.$store.state.settings.currentEventIdDB, 
              }
              this.$store.dispatch("deleteEvent", eventToDelete);
            }
        }
      }
      this.calenderCore1.updateCalender();
      this.handleHide();
    },
  
      updateEvents()
      {
          for (let index = 0; index < this.items.length; ++index){
            this.updateSingleDay(this.items[index]);
          }
      },
  
      updateSingleDay(day){
        var dayHasEvent = false;
        for (let index = 0; index < this.events.length; ++index) {
          if((day.id >= this.events[index].datefrom && day.id <= this.events[index].dateto))
          {
            day.backColor = this.events[index].color;
            day.eventText = this.events[index].title;
            dayHasEvent = true;
          }
        }
        if (dayHasEvent == false){
            day.backColor = day.backColorWeekDay;
            day.eventText = "";
            if(day.weekday == "So" || day.weekday == "Sa"){
                  day.backColor = this.$store.state.settings.BackColorWeekend;
                  day.dayHasEvent = true;
              }
              else{
                  day.backColor = this.$store.state.settings.BackColorNormalDay;              
              }
        }
      },
  
      GetSelectedEvent(eventID){
        for (let index = 0; index < this.events.length; ++index) {
            if (this.events[index].id == eventID)
            {
                return this.events[index];   
            }
        } 
      },
  
  
      SelectedDateHasEvent(selectedDate){
        for (let index = 0; index < this.events.length; ++index) {
          if (selectedDate >= this.events[index].datefrom && selectedDate <= this.events[index].dateto)
          {
            return this.events[index].id;
          }
        }
        return false;
      },
  
      TerminHasOnlyOneDay(event){
        
        var dt1 = new Date(event.datefrom);
        var dt2 = new Date(event.dateto);
  
        if(Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24)) == 0){
          return true;
        }
        else{
          return false;
        }
       
      },
  
      IsFirstDayOfEvent(event, selectedDate){
      
        if(event.datefrom == selectedDate){
              return true;
            }else{
              return false;
            }
        
      },
  
      IsLastDayOfEvent(event, selectedDate){
  
        if(event.dateto == selectedDate){
              return true;
            }else{
              return false;
            }
        
      },
  
      DayDifference(day1, day2){
        var dt1 = new Date(day1);
        var dt2 = new Date(day2);
        return Math.floor((Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) - Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) ) /(1000 * 60 * 60 * 24));
      },
  
      //IsLastEventDateWithinNextEvent(eventID, currentItem){
  
        //return false; // We have now more events on one day
        
        // Find selected event
        /*for (let index1 = 0; index1 < this.events.length; ++index1) {
            if (this.events[index1].id == eventID)
            {
              // Get StartDate and EndDate from selected event
              var dt0 = new Date(this.events[index1].datefrom);
              var dt1 = new Date(currentItem.id);
  
              // Run again through all events
              for (let index = 0; index < this.events.length; ++index){
  
                  // only process the other events and not the selected one
                  if(eventID != this.events[index].id){
  
                      // get DateFrom for the current Event
                      var dt2 = new Date(this.events[index].datefrom);
  
                      // Is DateFrom of the selected event before the DateFrom of the current event
                      
                      if(this.DayDifference(dt0, dt2) < 0){
                          if(this.DayDifference(dt1, dt2) > -1){
                              return true;
                          }
                      }
                  }
              }
              return false;
          }
        }*/
      //},
      
      IsFirstEventDateWithinPreviousEvent(eventID,currentItem){
        
        // Find selected event
        for (let index1 = 0; index1 < this.events.length; ++index1) {
            if (this.events[index1].id == eventID)
            {
              // Get StartDate and EndDate from selected event
              var dt0 = new Date(currentItem.id);
              var dt1 = new Date(this.events[index1].dateto);
  
              // Run again through all events
              for (let index = 0; index < this.events.length; ++index){
  
                  // only process the other events and not the selected one
                  if(eventID != this.events[index].id){
  
                      // get DateFrom for the current Event
                      var dt2 = new Date(this.events[index].dateto);
  
                      // Is DateFrom of the selected event before the DateFrom of the current event
                      if(this.DayDifference(dt0, dt2) < 1){
                          if(this.DayDifference(dt1, dt2) > -1){
                              return true;
                          }
                      }
                  }
              }
              return false;
          }
        }
      },
  
      closePopup(){
        this.toggle.value = false;
      },
  
      changeEventColor(selectedColor){
        this.$store.state.settings.eventColor = selectedColor;
      }
    },
  
    validations () {
      return {
        eventName: { 
          required,
          maxLength: maxLength(25), 
        }
      }
    },
  
    setup() {
  
      
      const windowHeight = ref(window.innerHeight)
      const toggle = ref(false)
      const store = useStore();
      const { state } = store;
      const calenderCore1 = calenderCore();      
      const isAuthenticated = computed(() => store.getters["user/isAuthenticated"]);
      const el = ref(null)
      const { width, height } = useElementSize(el)
      
      const handleWeekDayClick = () => {
      }
  
      const dayHight = ref(windowHeight.value/35)
  
  
      
    
  
   
  
      //const dayHight = computed({
      //  get() {
      //    return windowHeight.value/35
      //  },
      //})
  
  
      const startDrag = (e, currentEvent) =>{
         
        //console.log("TESt: " + divs[i])//.style.backgroundColor = "red"
        
        //followerDiv.value["Q0mLUrp8na9KoNDYvoBr"].style.color = "red"
  
        //refs.Q0mLUrp8na9KoNDYvoBr.style.backgroundColor = "red"
  
        state.settings.currentDraggingEventIndex = currentEvent.index
        
        //document.querySelector("Q0mLUrp8na9KoNDYvoBr").opacity = '0.4';
        state.settings.isdragging = true;
        state.settings.selectedCursor = "alias";
        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.effectAllowed = 'move';
        //e.dataTransfer.setData('itemID', item.id);
        e.dataTransfer.effectAllowed = true;
        
        
        //var img = document.createElement("img");
        //img.src = "http://kryogenix.org/images/hackergotchi-simpler.png";
        //event.dataTransfer.setDragImage(img, 0, 0);
        
      }
  
     const getMonthName = (month) =>{
      
      switch(month) {
      case "01":
        return "Januar"
      case "02":
        return "February"
      case "03":
        return "March"
      case "04":
        return "April"
      case "05":
        return "May"
      case "06":
        return "June"
      case "07":
        return "July"
      case "08":
        return "August"
      case "09":
        return "Sepetember"
      case 10:
        return "October"
      case 11:
        return "November"
      case 12:
        return "December"
     
  }
     }
  
  const onDrop = (event, item, events) => {
    
      state.settings.ctrlPressed = false;
      state.settings.ismousedown = false;
      state.settings.selectedCursor = "default";
      
      let date_1 = new Date(events[state.settings.currentDraggingEventIndex].dateto);
      let date_2 = new Date(events[state.settings.currentDraggingEventIndex].datefrom);
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24) );
      const newStartDate = new Date(item.id);
      const newEndDate = new Date(item.id);
      newEndDate.setDate(newEndDate.getDate() + TotalDays)
      //let correctEndDate = new Date(item.id)
  
      let newYearFrom = newStartDate.getYear() + 1900
      let newMonthFrom = newStartDate.getMonth() + 1
      let newDayFrom = newStartDate.getDate()
  
      let newYearTo = newEndDate.getYear() + 1900
      let newMonthTo = newEndDate.getMonth() + 1
      let newDayTo = newEndDate.getDate()
  
      if(newMonthFrom < 10) newMonthFrom = "0"+newMonthFrom;
      if(newDayFrom < 10) newDayFrom = "0"+newDayFrom;
      if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
      if(newDayTo < 10) newDayTo = "0"+newDayTo;
    
      //let insert = true;
  
      /*for (var d = newStartDate; d <= newEndDate; d.setDate(d.getDate() + 1)) {
        if(insert == false){
          break;
        }
        else{
          newYearTo = d.getYear() + 1900
          newMonthTo = d.getMonth() + 1
          newDayTo = d.getDate()
          if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
          if(newDayTo < 10) newDayTo = "0"+newDayTo;
  
          for (let index3 = 0; index3 < events.length; ++index3){
            if(insert == true){
              if(events[state.settings.currentDraggingEventIndex].id != events[index3].id){
                if(((newYearTo + "-" + newMonthTo + "-" + newDayTo) == events[index3].datefrom)){
                insert = false;
                d.setDate(d.getDate() - 1)
                correctEndDate = d
              }
              }
              else{
                correctEndDate = d
              }
            }
          }
        }
      }*/
      
      //correctEndDate.setDate(correctEndDate.getDate() - 1)
  
      //newYearTo = correctEndDate.getYear() + 1900
      //newMonthTo = correctEndDate.getMonth() + 1
      //newDayTo = correctEndDate.getDate()
  
      //if(newMonthTo < 10) newMonthTo = "0"+newMonthTo;
      //if(newDayTo < 10) newDayTo = "0"+newDayTo;
  
  
      events[state.settings.currentDraggingEventIndex].datefrom =  newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
      events[state.settings.currentDraggingEventIndex].dateto = newYearTo + "-" + newMonthTo + "-" + newDayTo,
      events[state.settings.currentDraggingEventIndex].datefromJson = getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
      events[state.settings.currentDraggingEventIndex].datetoJson = getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC"      
    
  
      //------------------- SAVE EVENT CHANGES IN FIRESTORE DATABASE START -----------------------------------------
  
      if(isAuthenticated.value == true){
        let newEvent = {
          calendarId: events[state.settings.currentDraggingEventIndex].calendarId,
          userid: state.user.userID,
          eventidDB : events[state.settings.currentDraggingEventIndex].eventidDB,
          color: events[state.settings.currentDraggingEventIndex].color, 
          title: events[state.settings.currentDraggingEventIndex].title,
          datefrom: newYearFrom + "-" + newMonthFrom + "-" + newDayFrom,
          dateto: newYearTo + "-" + newMonthTo + "-" + newDayTo,
          datefromJson: getMonthName(newMonthFrom) + " " + newDayFrom + ", " + newYearFrom + " 12:00:00 UTC" ,
          datetoJson: getMonthName(newMonthTo) + " " + newDayTo + ", " + newYearTo + " 12:00:00 UTC",
          fromHour: events[state.settings.currentDraggingEventIndex].fromHour,
          fromMinute: events[state.settings.currentDraggingEventIndex].fromMinute,
          toHour: events[state.settings.currentDraggingEventIndex].toHour,
          toMinute: events[state.settings.currentDraggingEventIndex].toMinute,
          isFullDay: events[state.settings.currentDraggingEventIndex].isFullDay,
          additionalInfos: events[state.settings.currentDraggingEventIndex].additionalInfos,
          rRules: events[state.settings.currentDraggingEventIndex].rRules      
        }
        store.dispatch("updateStoredEvent", newEvent);
        
      }
   
      //------------------- SAVE EVENT CHANGES IN FIRESTORE DATABASE END -----------------------------------------
    
      calenderCore1.updateCalender();
  
    }
      
      return {
        toggle,
        startDrag,
        onDrop,
        calenderCore1,
        handleWeekDayClick,
        el,
        width,
        height,
        windowHeight,
        dayHight
      }
    } 
  })
  </script>
  
  <style scoped>
  
  :global(.custom-tooltip) {
    font-size: 1.025rem !important;
    opacity: 1 !important;
    background-color: rgba(63, 63, 63,0) !important;
    padding: 0.06rem 0.05rem 0.07rem 0.06rem!important;
  }
  
  .grid-container2 {
    display: grid;
    user-select: none;
    margin-left: 0px;
    text-align: left;
    height: 100%;
  }
  .grid-item2 {
    
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    
  }
  .day-item {
    border-radius: 0px;
    
    border: 0px solid rgba(210, 202, 202, 0.802);
    background-color: rgba(255, 255, 255, 0.758);
    padding-top: 5px;
    color:#0b3558;
    font-size: medium;
    font-weight:300;
    text-align: center;
    user-select: none;
  }
  
  .buttonbottom_close{
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
  }
  
  .buttonbottom_save{
      margin-right: 0px;
      margin-bottom: 0px;
  }
  
  .go-right{
    justify-content: flex-end;
    background-color: white;
  }
  .square {
    height: 15px;
    width: 5px;
    background-color: #0b3558;
  }
  
  .day-grid-container-main{
    grid-auto-columns: 1fr;
    display: grid;
    user-select: none;
    padding-right: 2px;
  }
  
  .day-grid-container{
    grid-auto-columns: 1fr;
    display: grid;
    user-select: none;
    padding-right: 2px;
  }
  
  .toolip-grid-container{
    grid-template-columns: 40px auto auto;
    display: grid;
    padding-left: 15px;
    padding-right: 15px;
    user-select: none;
    border-radius: 25px;
  }
  
  .event-grid-container{
    background-color: #ffffff;
    grid-template-columns: 15px 15px;
    display: grid;
    user-select: none;
  }
  
  .TextContainer{
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
    vertical-align: middle;
    height: 100%;
  }
  
  .SetEllipsis{
    display: table-cell;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .SetNoEllipsis{
    white-space: nowrap;
    overflow: hidden;
    margin-left: 4px;
  }
  
  .red-underline{
      text-decoration: underline rgb(184, 1, 1);
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
  }
  
  .center-vertical{
   vertical-align: center;
  }
  
  .grid-container {
      display: grid;
      grid-template-columns: 30px 250px;
  } 
  
  .center {
    vertical-align: middle;
  }
  
  .item-small-top {
    display: table-cell;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
      user-select: none;
      align-items:center;
      grid-column: 1 / span 1;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 3px;
      font-size:x-small;
      font-weight: 500;
      height:100%;
      width: 100%;
      border-top-width: 1px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
      border-style: solid;
      border-top-color: rgb(204, 204, 204);
      border-radius: 2px;
      
  }
  
  .item-small-bottom {
      user-select: none;
      display: table-cell;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
      align-items:center;
      grid-column: 1 / span 1;
      padding-left: 0px;
      padding-right: 0px;
      font-size:x-small;
      font-weight: 500;
      height:100%;
      width: 100%;
      border-top-width: 1px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 1px;
      border-style: solid;
      border-top-color: rgb(204, 204, 204);
      border-bottom-color: rgb(204, 204, 204);
      border-radius: 2px;
  }
  
  .item-large-top {
    
    align-items:center;
    justify-content:left;
    float: left;
    width: 100%;
    height: 50%;
    margin-bottom: -50px;
    background-repeat: no-repeat  ;
    background-size: 25%;
    background-position: center right ;
    background-origin: content-box;
    vertical-align: middle;
    text-align: left;
    border-radius: 2px;
    grid-column: 2 / span 2;       
    font-size: 11px;
    color:#ffffff;
    text-align: center;
    font-weight: 600;
    height:100%;
    width:100%;
  
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-style: solid;
    border-top-color: rgb(197, 194, 194);
    
  }
  
  .item-large-bottom {
    
    align-items:center;
    justify-content:left;
    float: left;
    width: 100%;
    height: 50%;
    margin-bottom: -50px;
    background-repeat: no-repeat  ;
    background-size: 25%;
    background-position: center right ;
    background-origin: content-box;
    padding-right: 0px;
    vertical-align: middle;
    text-align: left;
    padding-left: 1px;
    border-radius: 2px;
    grid-column: 2 / span 2;       
    font-size: 11px;
    color:#ffffff;
    text-align: center;
    font-weight: 600;
    height:100%;
    width:95%;
    right:2%;
    left:2%;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    border-top-color: rgb(197, 194, 194);
    border-bottom-color: rgb(197, 194, 194);
    
  }
  
  .weekday-number{
      font-weight: 800;
      font-size: 9px;
      vertical-align: middle;
      padding-left: 1px;
      padding-right: 1px;
  } 
  
  .weekday-name{
      font-weight: 300;
      font-size: 9px;
  }
  
  .popoverPanel {
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(66, 66, 66, 0.5);
    font-size: .875rem;
    display: none;
  }
  
  .popoverPanel[data-show] {
    display: block;
  }
  
  .item.full-width {
      grid-column: span 2;
    }
  
  </style>
  
  
  