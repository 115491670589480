<!--//https://primevue.org/-->

<template>


    <v-card 
        style="
        padding-top: 10px;" 
        rounded="lg"
        variant="flat"
        color="#ffffff"    

        
    >
    
    <v-card-item color="green">
        
        <div style="
        padding-left: 15px;
        padding-right: 15px;
        ">
    
        <!------------------------------- CALENDERIMAGE / CALENDARNAME START ------------------------------------------>
        <div >
    
            <!--<div style="padding-right: 55px;">
                <imageUpload @changeCalendarImage="changeCalendarImage"/>
            </div>-->
       
            <div 
                style="
                padding-left: 0px;
                padding-top: 2px;
                "
            >
                <IconPicker 
                    @changeIcon="changeIcon" 
                    :key="iconPickerKey" 
                    :icon="icon" 
                    :color="calendarColor"
                    :edit="editable"
                />
          
               
            </div>
            
            
           <div style="padding-top: 20px;">
            <div>Kalendername</div>
            <v-text-field v-if="editable==true"
                bg-color="#F6F2EF"
                density="compact"
                v-model=kalendername
                clearable
                variant="flat"
                                     
            /> 
            <div 
                v-if="mode=='edit' &&  editable==false"
                class="vertical-center"
                v-bind:style="{ color: calendarColor }"
                style="font-size: large;
                font-weight: 300;
                "
                                        
            >{{ kalendername }}</div> 
           </div>
            
        </div>
          
          <!------------------------------- CALENDERIMAGE / CALENDARNAME END ------------------------------------------>
    
    
          <!------------------------------- KALENDER BESCHREIBUNG START ---------------------------------->
         
          <v-textarea  v-if="editable==true"
                style="
                margin-left: 5px;
                margin-top: 30px;"
                density="compact"
                height="37px"
                v-model=kalenderbeschreibung
                clearable
                :label="$t('description')"
                variant="outlined"
                color="#6397ff"
                bg-color="white"
            > 
          </v-textarea>


          <div v-if="mode=='edit' &&  editable==false"
            style="
                padding-left: 10px;
                padding-bottom: 25px;
                font-size: medium;
                font-weight: 300;
            "
                
                                        
            >{{ kalenderbeschreibung }}</div> 
    
          <!------------------------------- KALENDER BESCHREIBUNG END ---------------------------------->
   
          <div v-if="editable==true"
            style="
            padding-top: 15px;
            padding-left: 5px;">      
            <myColorPicker  v-if="calendarColor != null"
                @changeCalendarColor="changeCalendarColor"
                :prop_calendarColor=calendarColor
            />
          </div>

          <!-------------------------------- IMPORT EXTERNAL CALENDAR START --------------------------------> 
          <div style="
            display: grid;
            grid-template-columns: 95% 5%;
            align-items: center;
          ">
            <v-text-field v-if="editable==true"
                bg-color="white"
                density="compact"
                v-model=externalCalendarLink
                clearable
                :label="$t('subscribetoexternalcalendar')"
                variant="outlined"
                color="#6397ff"                          
            /> 
            <div style="
            padding-left: 10px;
            padding-bottom: 20px;
            " v-if="editable==true">
                <v-dialog
      v-model="dialog"
      width="800"
    >
      <template v-slot:activator="{ props }">
        <v-icon v-bind="props" icon="mdi-help-circle"></v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('howtosubscribe1')}}</span>
        </v-card-title>
        <v-card-text>
            {{ $t('howtosubscribe2')}}<br>
            {{ $t('howtosubscribe3')}}
            
            <br><br>
            {{ $t('howtosubscribe4')}}
            <br>
            {{ $t('howtosubscribe5')}}
            <br>


</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialog = false"
          >
          {{ $t('close')}}
          </v-btn>
     
        </v-card-actions>
      </v-card>
    </v-dialog>
            </div>


            

            
          </div>
        
          <!-------------------------------- IMPORT EXTERNAL CALENDAR END --------------------------------> 

            
          <!-------------------------------------- RADIO BUTTONS START ------------------------------------->    
    <!--
          <div v-if="editable==true"
            style="
            align-content: end;
            padding-left: 0px;">
                <v-radio-group v-model="radios">
                    <tr>
                        <td><v-radio style="
                              color:rgb(34, 34, 34);
                             font-size:larger;
                             font-weight: 400;"
                            v-bind:value=true>
                            <template v-slot:label>
                            <div style="
                                font-size:medium;
                                font-weight: 400;">{{ $t('private')}}</div>
                            </template></v-radio></td>
                        <td> <v-radio 
                        style="
                        color: rgb(34, 34, 34);
                        font-size:medium;
                        font-weight: 400;"
                        v-bind:value=false>
                        <template v-slot:label>
                            <div style="
                             font-size:medium;
                             font-weight: 400;">{{ $t('public')}}</div>
                        </template>
                    </v-radio></td>
                    </tr>
                </v-radio-group>
            </div>
        -->
    
    <!-------------------------------------- RADIO BUTTONS END ------------------------------------->  
    
           
        </div>
    </v-card-item>
    
        <v-card-actions 
        style="
        padding-bottom: 15px;
        padding-right: 12px;
        ">
            <v-spacer></v-spacer>
            

            <div v-if="mode=='edit' && editable==true"
                style="
              position:fixed;
                left:15px;
                bottom:20px;
                "
            >
              <v-btn @click="deleteCalendar()">       
                <v-icon 
                    color= "#525252"
                    icon="mdi-delete"
                    size="35px"
                />
                </v-btn>
            </div>
          
    
            <v-btn color="#656565"  class="text-none" density="default"
             style="margin-right: 5px; font-size: 14px; font-weight: 500;" 
            variant="text"  @click="closeCalendarMenu()">  
            {{ $t('cancel')}}
            </v-btn> 
    

            <v-btn density="default" color="#316bff" class="text-none"  :loading="loading"    v-if="mode=='edit' && editable==false"  style="margin-right: 5px; font-size: 14px; font-weight: 500;" 
            variant="flat"  @click="unsubscribeMain()" > 
            {{ $t('unsubscribe')}}
            </v-btn> 
    
            <v-btn density="default" color="#316bff" class="text-none"  v-if="mode=='edit' &&  editable==true"  variant="flat" @click="saveEdit()" style="font-size: 14px; font-weight: 500;">
                {{ $t('save')}}
            </v-btn>  

            <v-btn density="default"  color="#316bff" class="text-none"    v-if="mode=='new'"  variant="flat" @click="saveNew()" style="font-size: 14px; font-weight: 500;">
                {{ $t('save')}}
            </v-btn>  
              
            
            </v-card-actions>
     
    </v-card>
    
    
        
    </template>
    
    <script setup>
    import myColorPicker from './calendarColorPicker.vue';
    import { ref, defineEmits, defineProps, onMounted, computed } from 'vue'
    import {db} from "../db";
    //*****import imageUpload from './imageUpload.vue';
    import IconPicker from './IconPicker.vue';
    import {useStore} from "vuex";
    import calenderCore from "../composition/calenderCore";
    import { doc, updateDoc, deleteDoc } from "firebase/firestore";
    import { useToast } from "vue-toastification"
    

    const toast = useToast(); 

    const dialog = ref(false)

    const iconPickerKey = ref(0);

    const forceRerender = () => {
        iconPickerKey.value += 1;
    };
    
    const props = defineProps({   
                    prop_mode: String,
                    header: String,
                    prop_kalendername: String,
                    prop_kalenderbeschreibung: String,
                    prop_radios: Boolean,
                    prop_calendarColor: String,
                    prop_icon: String,
                    prop_calendarId: String,
                    prop_docId: String,
                    prop_creatorUID:String,
                    prop_externalCalendarLink:String
                }); 
    
    
    const emits = defineEmits(['closeCalendarMenu'])

    //const availableCalendarItems= computed({
    //    get() {
    //     return state.availablecalendars.items;
    //    },
    //  })

 

    const mycalendars = computed({
        get() {
          return state.mycalendars.items;
        },
      })

    const events = computed({
        get() {
            return state.events.items;
        },
        })
    
    const store = useStore();
    
const { state } = store;
const calenderCore1 = calenderCore();

    const menueHeader = ref('')
    const mode = ref('')
    const editable = ref(false);
    const deleting = ref(false);
    const loading = ref(false);
    const docId = ref('')
    const calendarId = ref('')
    const kalendername = ref('')
    const externalCalendarLink = ref('')
    const creatorUID = ref('')
    const kalenderbeschreibung = ref('')
    const radios= ref(true)
    const calendarColor = ref(null)
    //const calendarImagePath = ref('https://firebasestorage.googleapis.com/v0/b/annucaldev.appspot.com/o/default%2Fstandard_calendar_icon.png?alt=media&token=c55dce53-7204-4b48-b171-44fb0d687a9d')
    var calendarImage = null
    const icon = ref('')

    const initialize = () => {
        kalendername.value = props.prop_kalendername
        kalenderbeschreibung.value = props.prop_kalenderbeschreibung
        radios.value = props.prop_radios
        calendarColor.value = props.prop_calendarColor
        calendarId.value= props.prop_calendarId
        docId.value = props.prop_docId
        creatorUID.value = props.prop_creatorUID
        icon.value = props.prop_icon
        mode.value = props. prop_mode
        menueHeader.value = props.header
        if (props.prop_externalCalendarLink == undefined){
            externalCalendarLink.value = ''
        }else{
            externalCalendarLink.value = props.prop_externalCalendarLink
        }
        

        if(store.state.user.userID == creatorUID.value ){
            editable.value = true
            forceRerender()
        }
        else{
            menueHeader.value = 'Kalender deabonnieren?'
            editable.value = false
            forceRerender()
        }

    }   

    onMounted(() => initialize());

    
    function  changeCalendarColor(newColor){
        calendarColor.value = newColor
        forceRerender()
    }

    function changeIcon(newIcon){
        icon.value = newIcon
    }
    
    //*****function changeCalendarImage(newImage){
    //*****    calendarImagePath.value = newImage.name
    //*****     calendarImage = newImage
    //***** }
    
    function closeCalendarMenu(){
        emits('closeCalendarMenu')
    }
    
    async function saveNew(){

        if(kalendername.value == ""){
            toast.error("Bitte einen Namen für den Kalender eintragen")
            return
        }

        let newCalendar = {
            color: calendarColor.value, 
            //color: '#34ccff', - Farbe für Feiertage
            icon: icon.value,
            name: kalendername.value,
            externalCalendarLink: externalCalendarLink.value,
            description: kalenderbeschreibung.value,
            visible: true,
            private: radios.value,
            creatorUID: store.state.user.userID,
            creatorName: "Annucal",
            calendarImage:  calendarImage,
            events:[]
        }
    
        const newCalendarData = await store.dispatch("createCalendar", newCalendar);
        newCalendar.icon = newCalendarData.imageURL
        newCalendar.calendarId = newCalendarData.calendarId
        newCalendar.docId = newCalendarData.MyCalendarsdocId
        store.state.mycalendars.items.push(newCalendar)
        
   

        emits('closeCalendarMenu')
    }

    async function saveEdit(){

          let newCalendar = {
            color: calendarColor.value, 
            icon: icon.value,
            name: kalendername.value,
            description: kalenderbeschreibung.value,
            private: radios.value,
            docId: docId.value,
            userId: store.state.user.userID,
            calendarId: calendarId.value,
            externalCalendarLink: externalCalendarLink.value
        }
    
        await store.dispatch("updateCalendar", newCalendar);
        

        //--------------- CHANGE LOCAL MYCALENDERS START ---------------------------
        
        for (let i = 0; i < mycalendars.value.length; i++) {
          if(mycalendars.value[i].calendarId == calendarId.value){
            mycalendars.value[i].color = calendarColor.value
            mycalendars.value[i].icon = icon.value
            mycalendars.value[i].name = kalendername.value
            mycalendars.value[i].description = kalenderbeschreibung.value
            mycalendars.value[i].private = radios.value
            mycalendars.value[i].externalCalendarLink = externalCalendarLink.value
          }
        }

        //--------------- CHANGE LOCAL MYCALENDERS END ---------------------------

        //--------------- CHANGE LOCAL EVENTS START ------------------------------


        for (let i = 0; i < events.value.length; i++) {
          if(events.value[i].calendarId == calendarId.value){
            events.value[i].color = calendarColor.value
            events.value[i].icon = icon.value
            events.value[i].name = kalendername.value
            events.value[i].description = kalenderbeschreibung.value
            events.value[i].private = radios.value
            events.value[i].externalCalendarLink = externalCalendarLink.value
            await updateDoc(doc(db, "calendars", events.value[i].calendarId, "events", events.value[i].eventidDB), newCalendar);
          }
        }

        calenderCore1.updateCalender();

        //--------------- CHANGE LOCAL EVENTS END ------------------------------

    

        
        emits('closeCalendarMenu')
    }

    async function deleteCalendar(){

        let Calendar = {
            calendarId: calendarId.value,
            docId: docId.value,
            creatorUID: store.state.user.userID,
        }
        await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
        await store.dispatch("deleteCalendarFromCalendars", Calendar);
        unsubcribeToCalendarAfterDeleting(Calendar) 
        SetNewSelectedCalender()
        emits('closeCalendarMenu')

    }

    function SetNewSelectedCalender(){
        for (let i = 0; i < mycalendars.value.length; i++){
            if(mycalendars.value[i].externalCalendarLink == null || mycalendars.value[i].externalCalendarLink == "" || mycalendars.value[i].externalCalendarLink == undefined){
                store.state.settings.currentSelectedCalendar = mycalendars.value[i];
                store.state.settings.currentCalendarId = mycalendars.value[i].calendarId
                store.state.settings.currentCalendarColor = mycalendars.value[i].color 
                store.state.settings.currentCalendarIcon = mycalendars.value[i].icon
            }
        }
    }

    async function unsubscribeMain(){

        let Calendar = {
            calendarId: calendarId.value,
            docId: docId.value,
            creatorUID: store.state.user.userID,
        }
        await store.dispatch("deleteCalendarFromMyCalendars", Calendar);
        unsubcribeToCalendar(Calendar)
        emits('closeCalendarMenu')
    }

    const availableCalendarItems= computed({
        get() {
         return state.availablecalendars.items;
        },
      })

    function removeEvents(calendarId){
        for(let index2 = 0; index2 < events.value.length; ++index2){
            if(events.value[index2].calendarId == calendarId){
            events.value.splice(index2,1);
            }
        }
    }

    async function unsubcribeToCalendar(result){

        loading.value = true;

        for (let index = 0; index < mycalendars.value.length; ++index){
            if(mycalendars.value[index].calendarId == result.calendarId){
            await deleteDoc(doc(db, "users", state.user.userID, "myCalendars", mycalendars.value[index].docId));
            mycalendars.value.splice(index, 1);
            }
        }

        for(let index2 = 0; index2 < events.value.length; ++index2){
            removeEvents(result.calendarId)
        }

        // Refresh Events Indexlist
        for (let index = 0; index < events.value.length; index++){
                events.value[index].index = index;
        }

        for (let index3 = 0; index3 < availableCalendarItems.value.length; ++index3){
             if(availableCalendarItems.value[index3].calendarId == result.calendarId){
                 availableCalendarItems.value[index3].subscribed = false;
             }
         }

        calenderCore1.updateCalender();
        
        result.subscribed = false;

        loading.value = false;

    }

    async function unsubcribeToCalendarAfterDeleting(result){

            deleting.value = true;

            for (let index = 0; index < mycalendars.value.length; ++index){
                if(mycalendars.value[index].docId == result.docId){
                    mycalendars.value.splice(index, 1);
                }
            }

            for(let index2 = 0; index2 < events.value.length; ++index2){
                if(events.value[index2].calendarId == result.docId){
                    events.value.splice(index2,1);
                }
            }

            
            calenderCore1.updateCalender();

            deleting.value = false;

        }

   
    
    </script>
    
    <style scoped>

.button-grid{
    display: grid;
  padding-left: 0px;
  grid-template-columns: 248px auto ;
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
       .grid-container {
        
        height: 70px;
        display: grid;
        grid-template-columns: 70px auto;
        gap: 2;
        align-items: center;
      } 
    
      .center{
       vertical-align: middle;
      }
      .image{
        padding-top:30px;
        transition: transform .2s; /* Animation */
      }
    
      .image:hover{
        transform: scale(1.2);
        cursor: pointer;
    }
    
    </style>
    