var dayevent = []

export default {

    state(){
        return{
            valueFromHour : '08',
            valueFromMinute :  '00',
            valueToHour :  '08',
            valueToMinute :  '00',
            newEventMenueFrom : false,
            newEventMenueTo : false,
            dateFrom : new Date(),
            dateTo : new Date(),
            isFullDay : false,
            rRules : 'ONCE',
            removedDays : []
        }
    },
    actions:{
      getMydaEvent({commit}){
        commit("setdayEvent",dayevent);   
    }
  },

  mutations:{
    
    setdayEvent(state, dayevent){
          state.items = dayevent;
      }
  }
}

