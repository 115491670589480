//import feiertageFromFile from "../../assets/FeiertageDeutschland2024.json"
//import feiertageFromFile from "../../assets/FeiertageÖsterreich2024.json"
//import feiertageFromFile from "../../assets/FeiertageSchweiz2024.json"

//import feiertageFromFile from "../../assets/SchulferienDeutschland2024.json"
import feiertageFromFile from "../../assets/SchulferienÖsterreich2024.json"

export default {

    state(){
        return{
            items: feiertageFromFile
        }
    },
}



      