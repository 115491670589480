
<template> 

  <div :class="this.$store.state.settings.currentTheme + '-theme'">
    
    <!------------------------------------------------------------------------------------------->
    <!--------------------------------------- < 800 PX ------------------------------------------>
    <!------------------------------------------------------------------------------------------->
    <div v-if="this.$store.state.settings.screenWidth < 800">
        <v-container>
           <v-row>
             <v-col>
               <v-btn 
               variant="flat"
               color="blue"
               icon="mdi-calendar"
                fab small    class="error"
               @click="toggleComponent"></v-btn>
             </v-col>
           </v-row>
           <div>
             <custom-component />
           </div>
         </v-container>
        
         <exchange-navbar
          :title="brandName"
          :items="menuItems"
        />
      
         <router-view />
   
    </div>
  
    <!------------------------------------------------------------------------------------------->
    <!--------------------------------------- > 800 PX ------------------------------------------>
    <!------------------------------------------------------------------------------------------->
    
    <div  v-else class="calendar-grid"  :style="{'grid-template-columns':layerGridWidth}">
      <div class="layer-container">
        <div style="
          margin-top: 64px;
          padding-left: 12px;
          padding-right: 12px;
        ">
          <calendar-side-control-vue
          />
    </div>
        
  
      </div>

      <!------------------------------------------------------------------------------------------->
      <!------------------------------------------ SIGN IN ---------------------------------------->
      <!------------------------------------------------------------------------------------------->
      <annucal-sign-in-vue/>

      <!------------------------------------------------------------------------------------------->
      <!------------------------------------- NAVBAR & PAGECONTENT--------------------------------->
      <!------------------------------------------------------------------------------------------->
      <div class="outer-grid-column">
        <exchange-navbar
          :title="brandName"
          :items="menuItems"
        />
        <div >
          <router-view />
        </div>
        
      </div>
  
  
    </div>
  </div>
  </template>
    
    <script>
    import ExchangeNavbar from "./components/ExchangeNavbar.vue";
    //import CalendarList from "./components/calendarlist.vue";
    import CalendarSideControlVue from "./components/CalendarSideControl.vue"
    import useAuth from './composition/useAuth';
    //import CalendarMenu from "./components/CalendarMenu.vue";
    import CustomComponent from "./components/CustomComponent.vue";
    import AnnucalSignInVue from './components/AnnucalSignIn.vue';
  
    //import HamburgerMenue from "./components/HamburgerMenue.vue";
    
    
    export default {
      name: 'App',
      components: {
        ExchangeNavbar,
        //CalendarList,
        CalendarSideControlVue,
       // CalendarMenu,
        //HamburgerMenue,
       CustomComponent,
       AnnucalSignInVue
   
    },
      setup() {
        return useAuth();
      },
    
      data() {
        return {
          brandName: "Annucal - Plan ahead",
          menuItems: [
          ],
          radios: "private",
          calendarColor: "#eeeeee",
          calendarImage: "https://firebasestorage.googleapis.com/v0/b/annucaldev.appspot.com/o/default%2Fannucallogo02.png?alt=media&token=d0438b30-8572-4f71-aebe-b9af83f8033c",
          layerGridWidth: "52px auto",
          layerArrowImage: 'pfeilrechts3.png',
          buttonImage: 'newCalendarButton.png',
          isGridExpanded: false,
          checkbox1: true,
            checkbox2: false,
            dialogm1: '',
            dialog: false,
            menu: false,
            form: {
            kalendername: "",
            password: "",
            description: "",
       
          },
    
           
        }
      },
      watch: {
        isAuthenticated(isAuth) {
          if (isAuth) { this.$router.push("/calender"); }
        }
      },
    
      mounted() {
        this.updateScreenWidth();
        this.onScreenResize();
      },
    
      methods: {
   
       toggleComponent() {
         //this.$refs.customComponent.isVisible = !this.$refs.customComponent.isVisible;
         this.$store.state.settings.canvasOffScreenVisible = !this.$store.state.settings.canvasOffScreenVisible;
         
       },
   
       toggleHamburgerMenu() {
         //this.$refs.customComponent.isVisible = !this.$refs.customComponent.isVisible;
         this.$store.state.settings.hamburgerMenueVisible = !this.$store.state.settings.hamburgerMenueVisible;
         
       },
   
    
        onScreenResize() {
          window.addEventListener("resize", () => {
            this.updateScreenWidth();
          });
        },
        updateScreenWidth() {
          this.$store.state.settings.screenWidth = window.innerWidth;
        },
    
        async  save(){
            let newCalendar = {
                    color: this.calendarColor, 
                    icon: this.calendarImage,
                    name: this.form.kalendername,
                    description: this.form.description,
                    visible: true,
                    private: this.radios,
                    creatorUID: this.$store.state.user.userID,
                    creatorName: "asdf"
                  }
                  await this.$store.dispatch("createCalendar", newCalendar);
                  this.$store.state.mycalendars.items.push(newCalendar)
                  this.menu = false
        },
    
        closeCalendarMenu(){
          this.menu = false
        },
    
         changeEventColor(selectedColor){
          this.calendarColor = selectedColor;
        },
    
        changeArrowImageSelected(){
          if(this.layerArrowImage == 'pfeilrechts3.png'){
            this.layerArrowImage = 'pfeilrechts3mouseover.png'
          }else if(this.layerArrowImage == 'pfeillinks3.png'){
            this.layerArrowImage = 'pfeillinks3mouseover.png'
          }
        },
    
         addNewCalendar(){
          this.$store.state.settings.NewCalendarPageOpen = true
        },
    
        changeArrowImageUnselected(){
          if(this.layerArrowImage == 'pfeilrechts3mouseover.png'){
            this.layerArrowImage = 'pfeilrechts3.png'
          }else if(this.layerArrowImage == 'pfeillinks3mouseover.png'){
            this.layerArrowImage = 'pfeillinks3.png'
          }
        },
    
        changeGridSize() {
          if(this.layerGridWidth == "280px auto"){
            this.layerGridWidth = "90px auto";
            this.layerArrowImage = 'pfeilrechts3.png';
            this.isGridExpanded = false;
          }
          else{
            this.layerGridWidth = "280px auto";
            this.layerArrowImage = 'pfeillinks3.png';
            this.isGridExpanded = true;
          }
          
              }
            },
    }
    </script>
    
    <style lang="scss">
      @import 'assets/styles/variables.scss';
      @import '~bulma/bulma.sass';
      @import 'assets/styles/main.scss';
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
    
      $navbar-item-hover-color: red;
    
    </style>
    
    <style scoped>
    
    .grid-container {
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto auto;
        
    } 
    
    .grid-container2{
        padding-left: 10px;
        display: grid;
        grid-template-columns: auto auto ;
        
    } 
    
    .color-selector2{
      width:35px;
      height:35px;
      border-radius: 25px;
      margin-top:10px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 0px;
    }
     
     .calendar-grid{
      overflow: hidden; /* Verhindert das Scrollen des äußeren Containers */
        display: grid;
        z-index: -1;
        background-color: rgb(255, 255, 255);
      }
      .layer-container{
        background-color: var(--calendar-background-color); 
        z-index: 2;
      }
      .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 15px;
      width: 60px;
    }
    .logoimage {
      display: block;
      margin-left: 6px;
      margin-right: auto;
      padding-top: 34px;
      width: 120px;
    }
    
    .buttonsmall {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 30px;
      width: 90%;
      height: 64px;
      padding-left: 10px;
      padding-right: 10px;
    }
    
    .buttonbig {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 30px;
      width: 95%;
    }
    .arrow {
      display: block;
      margin-right: -15px;
      padding-top: 20px;
      width: 35px;
      float: right;
      z-index: 200;
    }
  
    .outer-grid-container {
    display: grid;
    grid-template-columns: auto 1fr; /* Zwei Spalten, wobei die erste automatische Breite hat */
    gap: 10px; /* Abstand zwischen den Spalten */
  }
  
  .outer-grid-column {
    overflow-x:hidden; /* Verhindert das Scrollen der ersten Spalte */
  }
  
  .inner-grid-container {
    overflow-x: auto; /* Horizontales Scrollen aktivieren */
  
  }
  
  .grid-item {
    background-color: lightgray;
    border: 1px solid gray;
    padding: 20px;
    text-align: center;
  }
  
  .grid-column-1 {
    /* Stil für die Inhalte der ersten Spalte */
    padding: 20px;
    border: 1px solid gray;
    background-color: lightblue;
  }
    
    </style>
  


   
  
  
  
  
  
  
  
  






