export default {
  "January": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennaio"])};fn.source="Gennaio";return fn;})(),
  "February": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fevereiro"])};fn.source="Fevereiro";return fn;})(),
  "March": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo"])};fn.source="Marzo";return fn;})(),
  "April": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprile"])};fn.source="Aprile";return fn;})(),
  "May": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggio"])};fn.source="Maggio";return fn;})(),
  "June": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giugno"])};fn.source="Giugno";return fn;})(),
  "July": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luglio"])};fn.source="Luglio";return fn;})(),
  "August": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])};fn.source="Agosto";return fn;})(),
  "September": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settembre"])};fn.source="Settembre";return fn;})(),
  "October": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottobre"])};fn.source="Ottobre";return fn;})(),
  "November": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])};fn.source="Novembre";return fn;})(),
  "December": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicembre"])};fn.source="Dicembre";return fn;})(),
  "Monday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunedi"])};fn.source="Lunedi";return fn;})(),
  "Tuesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martedì"])};fn.source="Martedì";return fn;})(),
  "Wednesday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercoledì"])};fn.source="Mercoledì";return fn;})(),
  "Thursday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giovedì"])};fn.source="Giovedì";return fn;})(),
  "Friday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venerdì"])};fn.source="Venerdì";return fn;})(),
  "Saturday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabato"])};fn.source="Sabato";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenica"])};fn.source="Domenica";return fn;})(),
  "Public holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste pubbliche"])};fn.source="Feste pubbliche";return fn;})(),
  "School holidays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacanze scolastiche"])};fn.source="Vacanze scolastiche";return fn;})(),
  "Calendar weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimane del calendario"])};fn.source="Settimane del calendario";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])};fn.source="Login";return fn;})(),
  "No account yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun account ancora"])};fn.source="Nessun account ancora";return fn;})(),
  "Repeat password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la password"])};fn.source="Ripeti la password";return fn;})(),
  "Forgot password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha dimenticato la password"])};fn.source="Ha dimenticato la password";return fn;})(),
  "Register now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti ora"])};fn.source="Iscriviti ora";return fn;})(),
  "Reset now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina ora"])};fn.source="Ripristina ora";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])};fn.source="Registrati";return fn;})(),
  "Already registered?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Già registrato?"])};fn.source="Già registrato?";return fn;})(),
  "Log in now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi ora"])};fn.source="Accedi ora";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudere"])};fn.source="Chiudere";return fn;})()
}